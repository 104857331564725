<template>
  <div>
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="pa-0 ma-0" cols="12" sm="3">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 to-upper"
            :label="$t('vehicles.search')"
            autofocus
            @input="debounceInput"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          fixed-header
          :headers="headers"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :height="'calc(-200px + 100vh)'"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          :items="vehicles"
          :key="forceReloadComponentKey"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td @click="editVehicle(item)">
                {{ item.id }}
              </td>
              <td @click="editVehicle(item)">
                <template v-if="item.is_out_of_circulation">
                  <v-tooltip top z-index="9999">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" v-bind="attrs" v-on="on">
                        mdi-car-arrow-right
                      </v-icon>
                    </template>
                    {{ $t("vehicle_is_out_of_circulation") }}
                  </v-tooltip>
                </template>
              </td>
              <td @click="editVehicle(item)">
                {{ item.registration_number }}
              </td>
              <td @click="editVehicle(item)">
                <span v-if="item.client">
                  {{ item.client.name }}
                  {{
                    item.client.first_name ? item.client.first_name : ""
                  }}</span
                >
              </td>
              <td @click="editVehicle(item)">
                <span v-if="item.brand"> {{ item.brand.name }} </span>
              </td>
              <td @click="editVehicle(item)">
                {{ item.model }}
              </td>
              <td @click="editVehicle(item)">
                {{ item.vin }}
              </td>
              <td @click="editVehicle(item)">
                <v-icon v-if="item.is_leasing" class="mr-1" color="success">
                  mdi-check
                </v-icon>
              </td>
              <td @click="editVehicle(item)">
                <span v-if="item.driver">
                  <span v-if="item.driver">
                    {{ item.driver.name }}
                    {{
                      item.driver.first_name ? item.driver.first_name : ""
                    }}</span
                  >
                </span>
              </td>
              <td @click="editVehicle(item)">
                <!-- {{ getLocalisationFromDepot(item) }} -->
                <v-icon
                  v-if="getLocalisationFromDepot(item).length > 0"
                  color="warning"
                  >mdi-check-circle</v-icon
                >
              </td>
              <td @click="editVehicle(item)">
                <v-icon
                  color="warning"
                  v-if="item.note"
                  @click="onNoteClick(item)"
                >
                  mdi-information
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </div>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "VehiclesListing",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      vehicles: [],
      alerts: [],
      search: "",
      isLoading: true,
      totalItems: 0,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      options: {},
      sortBy: "registration_number",
      sortDesc: false,
      sortOrd: "ASC",
      sort: {},
      showAlert: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      isDebounceCancelled: false,
    };
  },
  mounted() {
    this.$store.commit("setFormMode", VIEW_MODE);
    (this.header = { Authorization: "Bearer " + this.token }), this.fetchData();
    this.autocompleteValueLocal = this.autocompleteValue();
    this.headers.push({});
    this.headers.splice(-1);
  },
  methods: {
    debounceInput: _.debounce(function(e) {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.fetchData();
    }, 500),
    getLocalisationFromDepot(item) {
      let localisation = "";
      let activeDepot = 0;
      if (item.depot) {
        item.depot.forEach((element) => {
          if (element.status_id == 10171001 && element.deleted_at == null) {
            // depot stored
            activeDepot++;
            localisation += element.localisation
              ? " " + element.localisation + ","
              : "";
          }
        });
        if (activeDepot > 0 && !localisation.trim()) {
          return this.$t("yes");
        } else if (activeDepot > 0 && localisation.trim()) {
          return localisation.slice(0, -1);
        } else {
          return "";
        }
      }
    },
    fetchData(currentPage = null) {
      if (!currentPage) {
        this.options.page = 1;
        this.resetDataTable(currentPage);
      }

      this.isLoading = true;
      const payload = {
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortOrd,
        search: this.search,
      };
      axios({
        method: "get",
        url: API_BASE_URL + "/client_vehicles",
        headers: this.header,
        params: payload,
      })
        .then((data) => {
          console.log("client", data.data);
          this.vehicles = data.data.data;
          this.vehicles.forEach((vehicle) => {
            vehicle.is_leasing = vehicle.is_leasing == 1 ? true : false;
          });
          this.totalItems = data.data.total;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          this.$noty.success(
            this.$t("vehicle.something_went_wrong_while_fetching_data"),
            { timeout: 5000, layout: "bottomLeft" }
          );
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      console.log(itemsPerPage);
      itemsPerPage == -1
        ? (this.itemsPerPage = this.totalItems)
        : (this.itemsPerPage = itemsPerPage);
      this.fetchData();
    },
    editVehicle(item) {
      this.$store.commit("seteditId", item.id);
      this.$router.push("/vehicles/edit").catch(() => {});
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      console.log("data => ", sortDesc);
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$t("vehicles.id"), value: "id", sortable: true },
        { text: "", value: "id", sortable: false, width: "10" },
        {
          text: this.$t("vehicles.registration_number"),
          value: "registration_number",
          sortable: true,
        },
        {
          text: this.$t("vehicles.client_name"),
          value: "client_name",
          sortable: false,
        },
        {
          text: this.$t("vehicles.brand_name"),
          value: "brand_name",
          sortable: false,
        },
        { text: this.$t("vehicles.model"), value: "model", sortable: true },
        { text: this.$t("vehicles.vin"), value: "vin", sortable: true },
        {
          text: this.$t("vehicles.is_leasing"),
          value: "is_leasing",
          sortable: true,
        },
        {
          text: this.$t("vehicles.driver_name"),
          value: "driver_name",
          sortable: false,
        },
        { text: this.$t("vehicles.hotel"), sortable: false },
        { text: this.$t("vehicles.note"), value: "note", sortable: true },
      ];
    },
  },
  watch: {
    sortOrd: {
      handler: function() {
        this.fetchData();
      },
    },
    sortBy: {
      handler: function() {
        this.fetchData();
      },
    },
  },
};
</script>
<style scoped></style>
