<template>
    <v-card flat>
        <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
        <confirmation-model
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <v-form
            ref="form"
            class="main_form"
            id="client-form"
            @submit.prevent="submit"
        >
            <v-btn type="submit" class="d-none" id="submit-client"></v-btn>
            <input type="hidden" name="id" v-model="form.id" />
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="1">
                        <v-select
                            :autocomplete="autocompleteValueLocal"
                            v-model="form.entityType"
                            :items="entityTypeSelectItems"
                            class="to-upper"
                            :label="$t('clients.field.entity_type')"
                            item-text="text"
                            item-value="id"
                            return-object
                            :disabled="
                                this.operation === 'edit' &&
                                    form.defaultEntityTypeOfClient &&
                                    form.defaultEntityTypeOfClient.id == 1
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" :sm="isCompanyEntitySelected ? 10 : 11">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            v-model="form.name"
                            class="to-upper"
                            name="name"
                            v-on:keydown="
                                handleInput($event, 'justalphanumeric', 50)
                            "
                            :error-messages="
                                $store.state.validation &&
                                !$v.form.name.required
                                    ? [
                                          this.$t(
                                              'clients.text_messages.name_required'
                                          ),
                                      ]
                                    : []
                            "
                            :label="
                                this.isCompanyEntitySelected
                                    ? $t('clients.field.company_name')
                                    : $t('clients.field.full_name')
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1" v-if="isCompanyEntitySelected">
                        <v-select
                            :autocomplete="autocompleteValueLocal"
                            v-model="form.denomination"
                            :items="denominations"
                            class="to-upper"
                            item-value="id"
                            item-text="text"
                            :label="$t('clients.field.denomination')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-select
                            :autocomplete="autocompleteValueLocal"
                            v-model="form.familyClient"
                            :items="familyClients"
                            item-value="id"
                            item-text="name"
                            class="to-upper"
                            :label="$t('clients.field.family_client')"
                            menu-props="auto"
                            :error-messages="
                                $store.state.validation &&
                                !$v.form.familyClient.required
                                    ? [
                                          this.$t(
                                              'clients.text_messages.family_client_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-select
                            :autocomplete="autocompleteValueLocal"
                            v-model="form.status"
                            :items="statuses"
                            class="to-upper"
                            item-value="id"
                            item-text="key"
                            :label="$t('clients.field.status')"
                            :error-messages="
                                $store.state.validation &&
                                !$v.form.status.required
                                    ? [
                                          this.$t(
                                              'clients.text_messages.status_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-select
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-model="form.currency"
                            :items="currencies"
                            item-value="id"
                            item-text="text"
                            :label="$t('clients.field.currency')"
                            :error-messages="
                                $store.state.validation &&
                                !$v.form.currency.required
                                    ? [
                                          this.$t(
                                              'clients.text_messages.currency_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-select
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-model="form.language"
                            :items="languages"
                            item-value="id"
                            item-text="text"
                            :label="$t('clients.field.language')"
                            :error-messages="
                                $store.state.validation &&
                                !$v.form.language.required
                                    ? [
                                          this.$t(
                                              'clients.text_messages.language_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            v-model="form.email"
                            class="to-upper"
                            v-on:keydown="handleInput($event, 'email', 60)"
                            :error-messages="
                                $store.state.validation && !$v.form.email.email
                                    ? [
                                          this.$t(
                                              'clients.text_messages.invalid_email'
                                          ),
                                      ]
                                    : []
                            "
                            :label="$t('clients.field.email')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-model="form.phone"
                            v-on:keydown="
                                handleInput($event, 'phonenumber', 45)
                            "
                            :label="$t('clients.field.phone')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-model="form.gsm"
                            v-on:keydown="
                                handleInput($event, 'phonenumber', 45)
                            "
                            :label="$t('clients.field.gsm')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <!-- <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-on:keydown="
                                handleInput($event, 'phonenumber', 45)
                            "
                            v-model="form.fax"
                            :label="$t('clients.field.fax')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field> -->
                        <vue-tel-input-vuetify :disabledFetchingCountry="true" :preferredCountries="preferredCountry" :filterCountries="onlyCountries"  validCharactersOnly :label="$t('clients.field.fax')" :inputOptions="{ showDialCode: true, tabindex: 0 }" :mode="'international'" v-model="form.fax"></vue-tel-input-vuetify>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="3" v-if="isCompanyEntitySelected">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-model="form.registration_code"
                            v-on:keydown="
                                handleInput($event, 'justalphanumeric', 45)
                            "
                            :label="$t('clients.field.registration_number')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="isCompanyEntitySelected">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-model="form.tva"
                            v-on:keydown="
                                handleInput($event, 'justalphanumeric', 45)
                            "
                            :label="$t('clients.field.tva')"
                            :error-messages="
                                $store.state.validation && !$v.form.tva.required
                                    ? [
                                          this.$t(
                                              'clients.text_messages.tva_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" :sm="isCompanyEntitySelected ? 3 : 6">
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper"
                            v-model="form.web"
                            v-on:keydown="
                                handleInput($event, 'alphanumeric', 45)
                            "
                            :label="$t('clients.field.web_site')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" :sm="isCompanyEntitySelected ? 3 : 6">
                        <v-textarea
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper text-capitalize"
                            name="Note"
                            v-on:keydown="
                                handleInput($event, 'alphanumeric', 500)
                            "
                            :label="$t('clients.field.note')"
                            :auto-grow="true"
                            rows="1"
                            v-model="form.note"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row
                    class="group_label"
                    v-if="
                        this.operation != 'edit' &&
                            (checkTag('client_address') ||
                                checkTag('client_billing_address'))
                    "
                >
                    <v-col class="pb-2">
                        <span style="color: #2196F3"> Address </span>
                    </v-col>
                </v-row>

                <v-row
                    v-if="
                        this.operation != 'edit' &&
                            (checkTag('client_address') ||
                                checkTag('client_billing_address'))
                    "
                >
                    <v-col class="pt-0 pb-0" cols="4">
                        <v-combobox
                            class="to-upper"
                            :autocomplete="autocompleteValueLocal"
                            @input.native="fetchAddressSuggListDebounced()"
                            v-model="address"
                            :items="addresses"
                            item-value="id"
                            item-text="street"
                            :label="$t('billings.address')"
                            :search-input.sync="search_address"
                            :loading="isLoadingSearchAddress"
                            v-on:keydown.enter.prevent=""
                            @change="$emit('update:comp_address', address)"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-combobox>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <v-text-field
                            class="to-upper"
                            :autocomplete="autocompleteValueLocal"
                            v-model="zipcode"
                            :label="$t('billings.zip_code')"
                            v-on:keydown.enter.prevent=""
                            v-on:keydown="
                                handleInput($event, 'alphanumeric', 10)
                            "
                            :error-messages="
                                $store.state.validation &&
                                address &&
                                !$v.zipcode.required
                                    ? [
                                          this.$t(
                                              'billings.text_messages.zipcode_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <v-combobox
                            class="to-upper"
                            :autocomplete="autocompleteValueLocal"
                            @input.native="fetchCitySuggListDebounced()"
                            v-model="city"
                            :items="cities"
                            item-value="id"
                            item-text="name"
                            :label="$t('billings.city')"
                            :search-input.sync="search_city"
                            :loading="isLoadingSearchCity"
                            v-on:keydown.enter.prevent=""
                            v-on:keydown="handleInput($event, 'alpha', 50)"
                            :error-messages="
                                $store.state.validation &&
                                address &&
                                !$v.city.required
                                    ? [
                                          this.$t(
                                              'billings.text_messages.city_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-combobox>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <v-combobox
                            class="to-upper"
                            :autocomplete="autocompleteValueLocal"
                            @input.native="fetchCountySuggListDebounced()"
                            v-model="county"
                            :items="counties"
                            item-value="county"
                            item-text="county"
                            :label="$t('billings.county')"
                            :search-input.sync="search_county"
                            :loading="isLoadingSearchCounty"
                            v-on:keydown="handleInput($event, 'alpha', 50)"
                            v-on:keydown.enter.prevent=""
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-combobox>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <v-autocomplete
                            class="to-upper"
                            :autocomplete="autocompleteValueLocal"
                            flat
                            :items="countries"
                            v-model="country"
                            :label="$t('billings.country')"
                            item-value="id"
                            item-text="key"
                            v-on:keydown.enter.prevent=""
                            :loading="isLoadingSearchCountry"
                            :error-messages="
                                $store.state.validation &&
                                address &&
                                !$v.country.required
                                    ? [
                                          this.$t(
                                              'billings.text_messages.country_required'
                                          ),
                                      ]
                                    : []
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row
                    class="group_label"
                    v-if="
                        this.operation != 'edit' &&
                            (checkTag('vehicles') || checkTag('vehicles'))
                    "
                >
                    <v-col class="pb-2">
                        <span style="color: #2196F3"> Vehicle </span>
                    </v-col>
                </v-row>

                <v-row
                    class="pa-3 pt-6"
                    v-if="
                        this.operation != 'edit' &&
                            (checkTag('vehicles') || checkTag('vehicles'))
                    "
                >
                    <create-edit-vehicle
                        v-if="checkTag('vehicles')"
                        ref="vehicleComp"
                        :comp_vehicle_type.sync="parent_vehicle_type"
                        :comp_registration_no.sync="parent_registration_no"
                        :comp_brand.sync="parent_brand"
                        :comp_model.sync="parent_model"
                        :comp_km.sync="parent_km"
                        :comp_reg_date.sync="parent_reg_date"
                        :comp_creditor.sync="parent_creditor"
                        :comp_creditors.sync="creditors"
                        :comp_vin.sync="parent_vin"
                        :comp_is_leasing.sync="parent_is_leasing"
                    />
                </v-row>
            </v-container>
        </v-form>
        <client-tabs v-if="client.id" :client="client"></client-tabs>
        <v-btn @click="onSubmit()" class="d-none" id="submit-client"></v-btn>
        <v-btn @click="onDelete()" class="d-none" id="delete-client"></v-btn>
    </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL, VIEW_MODE } from "@/config";
import { DETAILS_MODE } from "@/config";
import { ADD_MODE } from "@/config";
import Tabs from "@/components/Client/Tabs";
import ConfirmationModel from "@/components/ConfirmationModel";
import { validationMixin } from "vuelidate";
import CreateEditVehicle from "@/components/Vehicles/CreateEditVehicle";

import {
    required,
    email,
    requiredIf,
    phoneNum,
    numeric,
    minLength,
    maxLength,
    alpha,
    minValue,
    maxValue,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    name: "ClientEdit",
    validations: {
        form: {
            name: {
                required: requiredIf(function() {
                    return !this.form.name.length;
                }),
            },
            email: {
                email: (val) => email((val ?? "").toLowerCase()),
            },
            tva: {
                required: requiredIf(function() {
                    return this.isCompanyEntitySelected;
                }),
            },
            status: {
                required,
            },
            currency: {
                required,
            },
            language: {
                required,
            },
            familyClient: {
                required,
            },
        },
        zipcode: {
            required: requiredIf(function(model) {
                return this.address;
            }),
        },
        city: {
            required: requiredIf(function(model) {
                return this.address;
            }),
        },
        country: {
            required: requiredIf(function(model) {
                return this.address;
            }),
        },
    },
    components: {
        "client-tabs": Tabs,
        "confirmation-model": ConfirmationModel,
        "create-edit-vehicle": CreateEditVehicle,
    },
    props: {
        iCompany: Boolean,
    },
    data() {
        const defaultForm = Object.freeze({
            defaultEntityTypeOfClient: null, // this will be used for edit client
            entityType: { id: 1 },
            id: "",
            company: {},
            language: localStorage.getItem("languageId") || "en",
            name: "",
            phone: "",
            gsm: "",
            fax: "",
            email: "",
            web: "",
            currency: localStorage.getItem("currencyId") || "USD",
            representative_company_id: "",
            user: "",
            note: "",
            status: 10041001,
            familyClient: "",
            created_at: "",
            updated_at: "",
            denomination: 10061001,
            registration_code: "",
            tva: "",
        });

        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            client: {},
            DeleteConfirm: false,
            form: Object.assign({}, defaultForm),
            denominations: [],
            statuses: [],
            currencies: [],
            languages: [],
            operation: "edit",
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            autocompleteValueLocal: "off",
            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            isLoadingSearchAddress: false,
            isLoadingSearchCity: false,
            isLoadingSearchCounty: false,
            isLoadingSearchCountry: false,
            zipcode: null,
            addresses: [],
            address: null,
            search_address: null,
            cities: [],
            city: null,
            search_city: null,
            counties: [],
            county: null,
            search_county: null,
            countries: [],
            country: null,
            search_country: null,

            // Vehicle Component Sync Values
            parent_vehicle_type: null,
            parent_registration_no: null,
            parent_brand: null,
            parent_model: null,
            parent_km: null,
            parent_reg_date: null,
            parent_creditor: null,
            creditors: [],
            parent_vin: null,
            parent_is_leasing: false,
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        (this.header = { Authorization: "Bearer " + this.token }),
            this.setCurrentFormOperation();
        this.getCompanyDenominations();
        this.getStatuses();
        this.getCurrencies();
        this.getLanguages();
        this.getCountries();
        this.getCreditors();
        if (this.operation === "edit") {
            this.$store.commit("setFormMode", DETAILS_MODE);
            this.fetchData();
        } else {
            this.setDefaultFamilyClient();
            this.$store.commit("setFormMode", ADD_MODE);
        }
    },
    methods: {
        getCreditors() {
            axios
                .get(API_BASE_URL + "/creditors", { headers: this.header })
                .then(({ data }) => {
                    this.creditors = data.data.map((element) => {
                        if (element.creditor_company) {
                            element.creditor_company["is_leaser"] =
                                element.is_leaser;
                            return element.creditor_company;
                        }
                    });
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                });
        },
        makeNewClientAddress() {
            return {
                zipcode: this.zipcode ?? null,
                city: this.city
                    ? typeof this.city == "object"
                        ? parseInt(this.city.id)
                        : this.city
                    : null,
                county: this.county
                    ? typeof this.county == "object"
                        ? parseInt(this.county.id)
                        : this.county
                    : null,
                country: this.country
                    ? typeof this.country == "object"
                        ? this.country.id
                        : this.country
                    : null,
                address: this.address
                    ? typeof this.address == "object"
                        ? parseInt(this.address.id)
                        : this.address
                    : null,
            };
        },
        makeNewVehicleData() {
            return {
                vehicle_id:
                    this.parent_registration_no &&
                    typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.id
                        : null,
                vehicle_type_id:
                    this.parent_vehicle_type &&
                    typeof this.parent_vehicle_type == "object"
                        ? this.parent_vehicle_type.id
                        : null,
                registration_no:
                    this.parent_registration_no &&
                    typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.registration_number
                        : this.parent_registration_no,
                brand: this.parent_brand
                    ? typeof this.parent_brand == "number"
                        ? this.parent_brand
                        : typeof this.parent_brand == "object"
                        ? this.parent_brand.id
                        : null
                    : null,
                model: this.parent_model ?? null,
                km: this.parent_km ?? null,
                reg_date: this.parent_reg_date ?? null,
                vin: this.parent_vin ?? null,
                is_leasing: this.parent_is_leasing ?? null,
                creditor_id: this.parent_creditor
                    ? typeof this.parent_creditor == "object"
                        ? this.parent_creditor.id
                        : this.parent_creditor
                    : null,
            };
        },
        getCountries() {
            axios
                .get(API_BASE_URL + "/countries", { headers: this.header })
                .then(({ data }) => {
                    this.countries = data.data;
                    this.shipping_countries = data.data;
                    this.countries.forEach((element) => {
                        if (element.is_default == 1) {
                            this.defaultCountry = element;
                        }
                    });
                    this.country = this.defaultCountry.id;
                    this.shipping_country = this.defaultCountry.id;
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                });
        },
        fetchCountySuggListDebounced() {
            if (!this.search_county || typeof this.search_county == "object") {
                return;
            }

            this.isLoadingSearchCounty = true;
            axios
                .get(
                    API_BASE_URL +
                        "/counties?" +
                        (this.search_county
                            ? "&search=" + encodeURIComponent(this.search_county)
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.counties = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchCounty = false;
                })
                .finally(() => (this.isLoadingSearchCounty = false));
        },
        fetchAddressSuggListDebounced() {
            if (
                !this.search_address ||
                typeof this.search_address == "object"
            ) {
                return;
            }
            this.isLoadingSearchAddress = true;
            axios
                .get(
                    API_BASE_URL +
                        "/addresses?" +
                        (this.search_address
                            ? "&search=" + encodeURIComponent(this.search_address)
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.addresses = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchAddress = false;
                })
                .finally(() => (this.isLoadingSearchAddress = false));
        },
        fetchCitySuggListDebounced() {
            if (!this.search_city || typeof this.search_city == "object") {
                return;
            }

            this.isLoadingSearchCity = true;
            axios
                .get(
                    API_BASE_URL +
                        "/cities?" +
                        (this.search_city ? "&search=" + encodeURIComponent(this.search_city) : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.cities = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchCity = false;
                })
                .finally(() => (this.isLoadingSearchCity = false));
        },
        fetchCountrySuggListDebounced() {
            if (
                !this.search_country ||
                typeof this.search_country == "object"
            ) {
                return;
            }
            axios
                .get(
                    API_BASE_URL +
                        "/countries?" +
                        (this.search_country
                            ? "&search=" + encodeURIComponent(this.search_country)
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.countries = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchCountry = false;
                })
                .finally(() => (this.isLoadingSearchCountry = false));
        },
        setDefaultFamilyClient() {
            this.familyClients.forEach((element) => {
                if (element.is_default == 1) {
                    this.form.familyClient = element;
                }
            });
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        deletePopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "clients.delete_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("clients.cancel");
            this.confirmationDialogTrueText = this.$t("clients.delete");
            this.confirmationDialogOperation = "delete";
            this.confirmationDialog = true;
        },
        onDelete() {
            this.deletePopUp();
        },
        fetchData() {
            axios
                .get(API_BASE_URL + "/clients/" + this.$store.state.editId, {
                    headers: this.header,
                })
                .then(({ data }) => {
                    this.client = data.data;
                    this.form.id = data.data.id;
                    this.form.name = data.data.name;
                    this.form.familyClient = data.data.family_client_id;
                    this.form.status = data.data.status.id;
                    this.form.currency = data.data.currency.id;
                    this.form.language = data.data.language.id;
                    this.form.note = data.data.note;
                    this.form.company = data.data.company;

                    const isCompany = !!this.form.company;
                    if (isCompany) {
                        // if is a company then take the details from company
                        this.form.entityType = { id: 1 };
                        this.form.defaultEntityTypeOfClient = { id: 1 };
                        this.form.denomination =
                            data.data.company.denomination_id;
                        this.form.email = data.data.company.email;
                        this.form.phone = data.data.company.phone;
                        this.form.gsm = data.data.company.gsm;
                        this.form.fax = data.data.company.fax;
                        this.form.web = data.data.company.web;
                        this.form.registration_code =
                            data.data.company.registration_code;
                        this.form.tva = data.data.company.tva;
                    } else {
                        this.form.entityType = { id: 0 };
                        this.form.defaultEntityTypeOfClient = { id: 0 };
                        this.form.email = data.data.email;
                        this.form.phone = data.data.phone;
                        this.form.gsm = data.data.gsm;
                        this.form.fax = data.data.fax;
                        this.form.web = data.data.web;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        makeSubmitData() {
            return {
                name: this.form.name,
                family_client_id:
                    typeof this.form.familyClient == "object"
                        ? this.form.familyClient.id
                        : this.form.familyClient,
                denomination_id:
                    this.form.entityType.id > 0 ? this.form.denomination : 0,
                representative_company_id: this.form.company
                    ? this.form.company.id
                    : null,
                status_id: this.form.status,
                currency_id: this.form.currency,
                email: this.form.email,
                phone: this.form.phone,
                gsm: this.form.gsm,
                fax: this.form.fax,
                web: this.form.web,
                registration_code: this.form.registration_code,
                tva: this.form.tva,
                language_id: this.form.language,
                note: this.form.note,
                address: this.makeNewClientAddress(),
                vehicle: this.makeNewVehicleData(),
            };
        },
        submit() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.$v.$invalid) {
                this.$noty.warning(this.$t("please_fill_form_correctly"), {
                    timeout: 5000,
                    layout: "bottomLeft",
                });
                return false;
            } else {
                console.log(this.makeSubmitData());
                if (this.operation === "add") {
                    this.addClient(this.makeSubmitData());
                } else {
                    this.updateClient(this.makeSubmitData());
                }
            }
        },
        getCompanyDenominations() {
            
            axios
                .get(API_BASE_URL + "/codes?class_key=company_denomination", {
                    headers: this.header,
                })
                .then(({ data }) => {
                    this.denominations = this.translateKeys(
                        data.data,
                        "key",
                        "company_denomination"
                    );
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    
                });
        },
        getStatuses() {
            
            axios
                .get(API_BASE_URL + "/codes?class_key=client_statuses", {
                    headers: this.header,
                })
                .then(({ data }) => {
                    this.statuses = data.data.map((item) => {
                        item.key = this.$t("clients." + item.key);
                        return item;
                    });
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    
                });
        },
        getCurrencies() {
            
            axios
                .get(API_BASE_URL + "/currencies", { headers: this.header })
                .then(({ data }) => {
                    this.currencies = this.translateKeys(
                        data.data,
                        "key",
                        "currencies"
                    );
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    
                });
        },
        getLanguages() {
            
            axios
                .get(API_BASE_URL + "/languages", { headers: this.header })
                .then(({ data }) => {
                    this.languages = this.translateKeys(
                        data.data,
                        "key",
                        "languages"
                    );
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    
                });
        },
        addClient(data) {
            
            axios
                .post(API_BASE_URL + "/clients", data, { headers: this.header })
                .then((response) => {
                    this.$noty.success(
                        this.$t(
                            "clients.text_messages.client_added_successfully"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.$router.push("/clients").catch(() => {});
                })
                .catch((error) => {
                    this.$noty.error(
                        this.$t(
                            "clients.text_messages." +
                                error.response.data.errors[0]
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                })
                .finally(() => {
                    
                });
        },
        updateClient(data) {
            axios
                .put(API_BASE_URL + "/clients/" + this.form.id, data, {
                    headers: this.header,
                })
                .then((response) => {
                    this.$noty.success(
                        this.$t(
                            "clients.text_messages.client_updated_successfully"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.$router.push("/clients").catch(() => {});
                })
                .catch((error) => {
                    this.$noty.error(
                        this.$t(
                            "clients.text_messages." +
                                error.response.data.errors[0]
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                })
                .finally(() => {
                    
                });
        },
        deleteClient() {
            
            axios
                .delete(API_BASE_URL + "/clients/" + this.$store.state.editId, {
                    headers: this.header,
                })
                .then((data) => {
                    this.$noty.success(
                        this.$t(
                            "clients.text_messages.client_deleted_successfully"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.$router.push("/clients").catch(() => {});
                })
                .catch((error) => {
                    this.$noty.error(
                        this.$t(
                            "clients.text_messages." +
                                error.response.data.errors[0]
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                })
                .finally(() => {
                    
                });
        },
        setCurrentFormOperation() {
            this.operation =
                this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
        },
    },
    computed: {
        isCompanyEntitySelected() {
            return this.form.entityType.id === 1;
        },
    },
    watch: {
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                if (
                    this.confirmationDialogOperation == "delete" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.deleteClient();
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },

        search_county(val) {
            if (!val || this.county == val) {
                return;
            }

            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchCountySuggListDebounced();
            }, 500);
        },
        search_city(val) {
            if (!val || this.city == val) {
                return;
            }

            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchCitySuggListDebounced();
            }, 500);
        },
        search_country(val) {
            if (!val || this.country == val) {
                return;
            }
            this.isLoadingSearchCountry = true;
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchCountrySuggListDebounced();
            }, 500);
        },
        search_address(val) {
            if (!val || this.address == val) {
                return;
            }

            this.addresses = [];
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchAddressSuggListDebounced();
            }, 500);
        },
    },
};
</script>

<style scoped>
.v-data-table__wrapper {
    height: calc(-700px + 100vh) !important;
}
</style>
