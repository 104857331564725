import {mapActions, mapState} from "vuex";
import moment from "moment";
import {
    API_BASE_URL,
    COLOR_BEST_SUPPLIER_PRICE,
    COLOR_LONG_SUPPLIER_DELIVERY_DATE,
    COLOR_SHORT_SUPPLIER_DELIVERY_DATE
} from "@/config";
import axios from "@/plugins/axios";
import { reject } from "lodash";

export default {
    data() {
        return {
            timerId: null,
            defaultMuToSelect: {"id":10311000,"class_id":1031,"key":"piece","value":"pc","order":1},
            multiselectsValues: [], 
            multiselects: [],
            notificationOption: {
                timeout: 5000,
                layout: "bottomLeft",
            },
            isVerifiedTVA: null,
            isVerifiedTVALoading: false,
            calendarViewTypes:[],
            classCodes: {},
            expeditionStatuses: [],
            expeditionExchangeContainersTypes:[],
            expeditionEquipmentTypes: [],
            expeditionLoadSecuringTypes: [],
            expeditionCertificatesTypes: [],
            merchandiseTypes: [],
            memberTypes: [],
            company_bank_attachments: [],
            defaultPdfEmailConfig: {
                'billing': {
                    'players': ['client', 'driver'],
                    'documents': ['estimation', 'purchase_order', 'bill', 'credit_note', 'depot_report', 'worksheet','work_report', 'expedition_report']
                },
                'expedition': {
                    'players': ['expeditor', 'transporter'],
                    'documents': ['expedition_report']
                }
            },
            years: [],
            dynamicModules: {}
        };
    },
    created() {
        if (this?.page == undefined) {
            this.page = 1;
        }
        this.computeYears();
    },
    computed: {
        ...mapState(["forceReloadComponentKey"]),
        showGridTotal(){
            // return false;
            return this.checkUserTag("show_grid_total");
        },
        userRole(){
            let userData = JSON.parse(localStorage.getItem('data'));
            return userData?.userDetails?.role;
        },
        isAdmin(){
            let userRole = JSON.parse(localStorage.getItem("data")).userDetails.role;

            if(userRole.name === 'admin'){
                return true;
            }
            return false;
        },
        currentUser(){
            let userData = JSON.parse(localStorage.getItem('data'));
            return userData?.userDetails;
        },
        userCountry(){
            return localStorage.getItem('userCountry');
        },
        userCompany(){
            let userData = JSON.parse(localStorage.getItem('data'));
            return userData?.userDetails?.company;
        },
        requestInProgress() {
            return this.$store.state.progressBarLoading;
        },
        defaultCurrencyId(){
            return localStorage.getItem('currencyId') || 'EUR';
        },
        attachmentTypes() {
            return  [
                {
                    id: 1,
                    key: 'cmr_lv',
                    icon: 'mdi-file-document-outline',
                    text: this.$t('attachment.types.cmr_lv')
                },
                {
                    id: 11,
                    key: 'cmr_bl',
                    icon: 'mdi-file-document-outline',
                    text: this.$t('attachment.types.cmr_bl')
                },
                {
                    id: 2,
                    key: 'delivery_note',
                    icon: 'mdi-package-variant-closed',
                    text: this.$t('attachment.types.delivery_note')
                },
                {
                    id: 3,
                    key: 'order_of_transport',
                    icon: 'mdi-text-box-outline',
                    text: this.$t('attachment.types.order_of_transport')
                },
                {
                    id: 4,
                    key: 'weighing_slip',
                    icon: 'mdi-weight',
                    text: this.$t('attachment.types.weighing_slip')
                },
                {
                    id: 5,
                    key: 'bill',
                    icon: 'mdi-file-document',
                    text: this.$t('attachment.types.bill')
                },
                {
                    id: 6,
                    key: 'washing_voucher',
                    icon: 'mdi-washing-machine',
                    text: this.$t('attachment.types.washing_voucher')
                },
                {
                    id: 7,
                    key: 'photo_of_merchandise',
                    icon: 'mdi-image-multiple',
                    text: this.$t('attachment.types.photo_of_merchandise')
                },
                {
                    id: 8,
                    key: 'waste_tracking_slip',
                    icon: 'mdi-tire',
                    text: this.$t('attachment.types.waste_tracking_slip')
                },
                {
                    id: 9,
                    key: 'media_exchange_voucher',
                    icon: 'mdi-play-network-outline',
                    text: this.$t('attachment.types.media_exchange_voucher')
                },
                {
                    id: 10,
                    key: 'other',
                    icon: 'mdi-text-box-multiple-outline',
                    text: this.$t('attachment.types.other')
                },
                {
                    id: 12,
                    key: 'expedition_report',
                    icon: 'mdi-text-box-multiple-outline',
                    text: this.$t('attachment.types.expedition_report')
                },
                {
                    id: 13,
                    key: 'client_bill',
                    icon: 'mdi-text-box-multiple-outline',
                    text: this.$t('attachment.types.client_bill')
                },
            ]
        },
        functionsToExecuteAgain(){
            console.log("functions to execute again", this.$store.state.functionsToExecuteAgain);
            return this.$store.state.functionsToExecuteAgain;
        },
        isLoggedInCompanyPeppolSupported(){
            let value = localStorage.getItem('is_peppol') ?? 0;
            return parseInt(value) == 1 ? true : false; 
        },
        userCurrencySymbol() {
            return localStorage.getItem('currencySymbol');
        },
        mapApiKey() {
            return this.$store.state.mapApiKey;
        },
        currencies() {
            return this.$store.state.currencies;
        },
        bankAccounts() {
            return this.$store.state.bankAccounts;
        },
        defaultBankAccount() {
            return this.$store.state.defaultBankAccount;
        },
        userLanguageId() {
            return this.$store.state.languageId;
        },
        cachedAdressTypes() {
            return this.$store.state.cachedAdressTypes;
        },
        taxationReasons(){
            return this.$store.state.taxationReasons;
        },
        cachedData() {
            return this.$store.state.cachedData;
        },
        orderReminderTypes() {
            return this.$store.state.orderReminderTypes;
        },
        familyArticles() {
            return this.$store.state.familyArticles;
        },
        countries() {
            return this.$store.state.countries;
        },
        approvals() {
            return this.$store.state.approvals;
        },
        projectStatuses() {
            return this.$store.state.projectStatuses;
        },
        tyreTypes() {
            return this.$store.state.tyreTypes;
        },
        wheelTypes() {
            return this.$store.state.wheelTypes;
        },
        wheelMaterials() {
            return this.$store.state.wheelMaterials;
        },
        tyreSeasons() {
            return this.$store.state.tyreSeasons;
        },
        statuses() {
            return this.$store.state.statuses;
        },
        familyClients() {
            return this.$store.state.familyClients;
        },
        billTypes() {
            return this.$store.state.billTypes;
        },
        expensesTypes() {
            return this.$store.state.expensesTypes;
        },
        resourceTypes() {
            return this.$store.state.resourceTypes;
        },
        allCodes() {
            let codes = [];
            if (this.tyreTypes) {
                codes = codes.concat(
                    this.translateKeys(this.tyreTypes, "key", "types")
                );
            }
            if (this.tyreSeasons) {
                codes = codes.concat(
                    this.translateKeys(this.tyreSeasons, "key", "seasons")
                );
            }
            if (this.wheelTypes) {
                codes = codes.concat(
                    this.translateKeys(this.wheelTypes, "key", "types")
                );
            }
            return codes;
        },
        onlyCountries() {
            let countries = [];
            this.countries.forEach(element => {
                // Pushing to countries
                // console.log("countries searching", element);
                countries.push(element.key);
            });
            return countries;
        },
        preferredCountry() {
            return [localStorage.getItem('userCountry'), 'LU', 'BE', 'FR', 'DE', 'PT'];
        }
    },
    methods: {
        clickOutside(){
            document.getElementById('inspire').click();
        },
        checkIfComponentExists(moduleName) {
            let data = JSON.parse(localStorage.getItem("data"));
            let companyIdentifier = data.userDetails.company.identifier;
            let modulePath = `@/components/${companyIdentifier}/${moduleName}.vue`;
        
            // Using Webpack's require.context to check for component existence
            const context = require.context('@/components', true, /\.vue$/);
        
            // Construct the full path relative to the context's base path
            const fullPath = `./${companyIdentifier}/${moduleName}.vue`;
        
            if (context.keys().includes(fullPath)) {
                console.log(`Module ${moduleName} exists at ${modulePath}`);
                return true;
            } else {
                console.error(`Module ${moduleName} not found at ${modulePath}`);
                return false;
            }
        },  
        // Load Module/Component
        async loadModuleOrComponent(moduleName){
            try {
                let isLoadedModuleOrComponent = await this.checkIfComponentExists(moduleName);
                if(isLoadedModuleOrComponent){
                    this.dynamicModules[moduleName] = isLoadedModuleOrComponent;
                    this.$store.commit("setDynamicLoadedComponents",  this.dynamicModules);
                    console.log("loading module", isLoadedModuleOrComponent);
                    return true;
                }
                return false;
            } catch (error) {
                console.error(`Failed to load module ${moduleName}:`, error);
                return false;
            }
        },
        // Check if Component is Loaded
        checkIfComponentLoaded(moduleName){
            let allLoadedComponents = Object.keys(this.$store.state.dynamicLoadedComponents);
            return allLoadedComponents.includes(moduleName);
        },
        moveLastItemOnePositionBack(array) {
            // If array has less than 2 items, no need to move anything
            if (array.length < 2) {
                // console.warn("Array has less than 2 items. No item to move.");
                return array;
            }
        
            // Remove the last item from the array
            const lastItem = array.pop();
            
            // Insert the last item one position back
            array.splice(array.length - 1, 0, lastItem);
            return array;
        },
        computeYears() {
            for (let i = new Date().getFullYear(); i > 2018; i--) {
                this.years.push({ year: i });
            }
        },
        areObjectsEqual(obj1, obj2, keysToSkip = []) {
            // Check if both objects are valid
            if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
                return false;
            }

            // Get the keys of both objects
            const obj1Keys = Object.keys(obj1 ?? {}).filter(key => !keysToSkip.includes(key));
            const obj2Keys = Object.keys(obj2 ?? {}).filter(key => !keysToSkip.includes(key));

            // Check if the number of keys is the same
            if (obj1Keys.length !== obj2Keys.length) {
                console.log('typeof Different number of keys:', obj1Keys, obj2Keys);
                return false;
            }

            // Check if all keys and values are the same
            for (let key of obj1Keys) {
                // Check if the key exists in both objects
                if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
                    console.log('typeof Key not found in obj2:', key);
                    return false;
                }

                // If both values are objects, recursively compare them
                if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                    if (!this.areObjectsEqual(obj1[key], obj2[key], keysToSkip)) {
                        console.log('typeof Objects not equal at key:', key);
                        return false;
                    }
                } else {
                    // Check if the values are NaN or null
                    if ((Number.isNaN(obj1[key]) && Number.isNaN(obj2[key])) || (obj1[key] === null && obj2[key] === null)) {
                        continue;
                    }

                    // Check if the values are the same
                    if (obj1[key] !== obj2[key]) {
                        console.log('typeof Values not equal at key:', key, obj1[key], obj2[key]);
                        return false;
                    }
                }
            }

            // If all checks passed, the objects are equal
            return true;
        },
        getMemberTypeById(id){
            return new Promise((resolve) => {
                let memberType = null;
                console.log("val_type_id_member_member_type_id", this.memberTypes, id, this.$store.state.classCodes['member_types']);
                (this.$store.state.classCodes['member_types']?? []).forEach((element) => {
                    if(element.id == id){
                        console.log("found member type", id, element);
                        memberType = element;
                    }
                })
                console.log("selected member type", memberType);
                resolve(memberType);
            })
            
        },
        selectEntityType(val){
            let entityType = this.entityTypes[0];
            console.log("entity_types", val, this.entityTypes);
            this.entityTypes.forEach((element) => {
                if(element.value.toLowerCase() == val.toLowerCase()){
                    console.log("entity_type", val, element);
                    entityType = element;
                }
            })

            return entityType;
        },
        showFile(file){
            let url = file.signed_url;
            let fileName = file.title
            this.setPreviewData(url,fileName, true, file.mime_type);
            console.log("item selected clicked",file);
        },
        setMultiLevelFallback(findCompanyLocale){
            let data = JSON.parse(localStorage.getItem("data"));
            let companyIdentifier = data.userDetails.company.identifier;
            console.log("**************load local messages messages from created*********", findCompanyLocale, companyIdentifier);
            if(findCompanyLocale.includes(companyIdentifier)){
                findCompanyLocale = findCompanyLocale.replaceAll(companyIdentifier, "");
            }
            if(findCompanyLocale != this.$i18n.fallbackLocale){
                this.$i18n.fallbackLocale = [findCompanyLocale, this.$i18n.fallbackLocale];
            }
        },
        async copyText(mytext) {
            try {
                await navigator.clipboard.writeText(mytext);
                this.$noty.success(this.$t('copied'), { timeout: 500, layout: "bottomLeft" });
            } catch($e) {
                this.$noty.error(this.$t('cannot_copied'), { timeout: 500, layout: "bottomLeft" });
            }
        },
        findTaxationReasonByCode(code_id){
            let isTaxationAllowed = true;
            this.taxationReasons.forEach((element) => {
                if(element.id == code_id){
                    console.log("is_taxation_allowed", parseInt(element.value), !parseInt(element.value));
                    isTaxationAllowed = !! parseInt(element.value);
                }
            })
            return isTaxationAllowed;
        },
        getAlphabets(str) {
            return (str ?? "").replace(/[^A-Za-z]+/g, '');
        },
        checkReferenceInExpeditions(reference, expedition_id, checkIn = 'expedition_details'){
            console.log("checking_reference", reference, expedition_id);
            return new Promise((resolve, reject) => {
                if(reference){
                    axios
                    .get(API_BASE_URL + "/expeditions/check/reference?reference=" + reference + 
                        (expedition_id ? "&except_expedition_id=" + expedition_id : "") + 
                        (checkIn == 'expedition_details' ? "&check_inside_details=" + true : "")
                    , { headers: this.header })
                    .then(({ data }) => {
                        if(data.data){
                            this.$noty.error(this.$t('expeditions.text_messages.reference_already_exists'), { timeout: 5000, layout: "bottomLeft" });
                        }
                        resolve(data.data);
                    })
                    .catch((error) => {
                        reject();
                    })
                    .finally(() => {
                        reject();
                    });
                }
                else{
                    resolve();
                }
            })
        },
        findFileIconWithDescription(item){
            let iconFound = null;
            if(item.description){
                this.attachmentTypes.forEach((attachmentType) => {
                    if(attachmentType.key.toUpperCase() == item.description.toUpperCase()){
                        iconFound = attachmentType.icon;
                    }
                })
            }
            if(iconFound){
                return iconFound;
            }
            return "mdi-file-document-outline";
        },
        setExpeditionRowColorClass(itemStatus){
            let className = '';
            if(itemStatus && typeof itemStatus == 'object'){
                if(itemStatus.key == 'new'){
                    className = 'white'
                }
                if(itemStatus.key == 'in_process'){
                    className = 'orangecolor'
                }
                if(itemStatus.key == 'finished'){
                    className = 'greencolor'
                }
                if(itemStatus.key == 'billed'){
                    className = 'bluecolor'
                }
                if(itemStatus.key == 'dispute'){
                    className = 'redcolor'
                }
                if(itemStatus.key == 'canceled'){
                    className = 'greycolor'
                }
            }
            
            return className;
        },
        setSupplierRowColorClass(supplier, optional = ''){
            console.log('supplier key --- ', supplier, optional);
            let className = 'greencolor';
            if(supplier && typeof supplier == 'object'){
                if(supplier.is_active == 1){
                    className = 'greencolor'
                }
                if(supplier.is_active == 0){
                    className = 'orangecolor'
                }
            }
            else{
                className = 'orangecolor'
            }
            
            return className;
        },
        computeAddressLabel(address){
            let label = "";

            if (this.isset(address.building_id)) {
                label += address.building_id;
            }

            if (this.isset(address.street)) {
                if (label != "") {
                    label += ", ";
                }
                label += address.street;
            }

            if (this.isset(address.zip)) {
                if (label != "") {
                    label += ", ";
                }
                label += address.zip;
            }

            if (this.isset(address.county)) {
                if (label != "") {
                    label += ", ";
                }
                label += address.county;
            }
        
            if (this.isset(address.state)) {
                if (label != "") {
                    label += ", ";
                }
                label += address.state;
            }
        
            if (this.isset(address.city)) {
                if (label != "") {
                    label += ", ";
                }
                if (typeof address.city === "object") {
                    label += address.city?.name || "";
                } else {
                    label += address.city;
                }
            }

            if (this.isset(address.city)) {
                if (label != "") {
                    label += ", ";
                }
                if (typeof address.city === "object") {
                    label += address.city?.country_id || "";
                } else {
                    label += address.city;
                }
            }

            return label.toUpperCase();
        },
        computeExpeditionListingAddressLabel(address){
            let label = "";

            // Country
            if (this.isset(address.city)) {
                if (label != "") {
                    label += ", ";
                }
                if (typeof address.city === "object") {
                    label += address.city?.country_id || "";
                } else {
                    label += address.city;
                }
            }

            if (this.isset(address.zip)) {
                if (label != "") {
                    label += ", ";
                }
                label += address.zip;
            }

            if (this.isset(address.city)) {
                if (label != "") {
                    label += ", ";
                }
                if (typeof address.city === "object") {
                    label += address.city?.name || "";
                } else {
                    label += address.city;
                }
            }


            return label.toUpperCase();
        },
        checkIfCodeExists(classKey){
            let classCodes = this.$store.state.classCodes;
            return classKey in classCodes;
        },
        // If Class Key is Number then we have to fetch by class id and if it is string then we have to fetch by class key
        getCodes(classKey, translationKey = null) {
            return new Promise((resolve) => {
                if(! this.checkIfCodeExists(classKey)){
                    let url = typeof classKey == 'number' ? '/codes?class_id=' : '/codes?class_key=';
                    axios.get(API_BASE_URL + url + classKey, {
                        headers: { Authorization: "Bearer " + this.$store.state.AccessToken },
                    })
                    .then(({data}) => {
                        let translatedData = data.data;
                        if(translationKey && Array.isArray(translatedData)){
                            translatedData = this.translateKeys(data.data, "key", translationKey);
                        }
                        else if(translationKey === null && Array.isArray(translatedData)){
                            translatedData = this.translateKeys(data.data, classKey, "key", classKey);
                        }
                        this.$store.commit("setClassCodes", { key: classKey, values: translatedData});
                        resolve(this.$store.state.classCodes[classKey]);
                    }).catch(() => {
                        reject([]);
                    });
                }
                else{
                    resolve(this.$store.state.classCodes[classKey]);
                }
            })
        },
        isset(payload) {
            if(Array.isArray(payload)){
                return payload.length > 0;
            }

            return payload !== undefined && payload !== null && payload !== "" && payload !== false;
        },
        async getCalendarViewTypes(){
            return await this.getCodes('calendar_view_types', false)
            .then((data) => {
                this.calendarViewTypes = data;
            })
        },
        resetVariables(){
            this.isVerifiedTVA = null;
            this.isVerifiedTVALoading = false;
        },
        verifyTVA(tva){
            return new Promise((resolve, reject) => {
                if(tva){
                    this.isVerifiedTVALoading = true;
                    axios
                    .get(API_BASE_URL + "/clients/checkvat/" + tva, { headers: this.header })
                    .then(({ data }) => {
                        this.isVerifiedTVA = data.data.isValid;
                        console.log("is valid", data.data.isValid);
                        if(data.data.isValid == false){
                            this.$noty.error(tva + " " + this.$t('invalid_tva'), { timeout: 5000, layout: "bottomLeft" });
                        }
                        resolve(data.data);
                    })
                    .catch((error) => {
                        console.log("an error occured " + error);
                        this.isVerifiedTVA = false;
                        this.$noty.error(this.$t('invalid_tva'), { timeout: 5000, layout: "bottomLeft" });
                        reject();
                    })
                    .finally(() => {
                        this.isVerifiedTVALoading = false;
                        reject();
                    });
                    console.log("is verified", this.isVerifiedTVA);
                }
                else{
                    this.resetVariables()
                    resolve();
                }
            })
        },
        ...mapActions(["forceReloadComponent"]),
        setFunctionToExecuteGlobally(val, isToRemove = false){
            let allFunctionToExecute = this.functionsToExecuteAgain ?? [];
            if(isToRemove){
                let index = allFunctionToExecute.indexOf(val);
                if (index > -1) { // only splice array when item is found
                    allFunctionToExecute.splice(index, 1); 
                }
            }
            else{
                allFunctionToExecute.push(val)
            }
            this.$store.commit("setFunctionsToExecuteAgain", allFunctionToExecute)
        },
        setPreviewData(previewUrl, previewFileName, previewIsAuthDependent, mimeType = null, showFullScreen = true, openInNewWindow = false){
            this.$store.commit("setPreviewUrl", previewUrl);
            this.$store.commit("setPreviewFileName", previewFileName);  
            this.$store.commit("setPreviewIsAuthDependent", previewIsAuthDependent);
            this.$store.commit("setPreviewMimeType", mimeType);
            this.$store.commit("setIsShowFullScreen", showFullScreen ?? true);
            this.$store.commit("setOpenInNewWindow", openInNewWindow ?? false);

        },
        translateKey(key) {
            let translation = this.$t(key);

            if (translation !== key) {
                return translation;
            }

            // remove _ from key and make it sentence case
            return key
                .split('.')
                .pop()
                .replace(/_/g, ' ')
                .toString()
                .toUpperCase()
        },
        resetDataTable(page = null) {
            if (!page) {
                this.forceReloadComponent();
                this.page = 1;
                if (this?.options) {
                    this.options.page = 1;
                }
            }
        },
        computeSlotBackgroundColor(item, slot) {
            let subSlots = this.subSlots;

            if (!(subSlots.length > 0)) {
                return "white";
            }

            // if item field price is empty return white
            if (item[`${slot.field_price}`] === null) {
                return "white";
            }

            let fieldPrices = subSlots.map((subSlot) => {
                return {
                    field: subSlot.value,
                    price: item[`${subSlot.field_price}`],
                };
            });

            // remove null values
            let filteredPrices = fieldPrices.filter((fieldPrice) => {
                return fieldPrice.price !== null;
            });

            // sort by price from lowest to highest
            let sortedPrices = filteredPrices.sort((a, b) => {
                return a.price - b.price;
            });

            // get the lowest price
            let lowestPrice = sortedPrices[0];

            let isLowestPrice = false;

            // if field of lowest price is equal to slot value return light green
            if (lowestPrice && lowestPrice.field === slot.value) {
                isLowestPrice = true;
            }

            // get delivery date from Item
            let deliveryDate = item[`${slot.field_delivery_date}`];

            // if delivery date is null return limegreen
            if (deliveryDate === null && !isLowestPrice) {
                return COLOR_SHORT_SUPPLIER_DELIVERY_DATE;
            }

            // format delivery date with moment JS
            let formattedDeliveryDate = moment(deliveryDate);

            let now = moment();

            let difference = formattedDeliveryDate.diff(now, "days");

            // if deliveryDate is greater Than 4 Days return Light Salmon
            if (difference > 4) {
                return COLOR_LONG_SUPPLIER_DELIVERY_DATE;
            }

            return isLowestPrice ? COLOR_BEST_SUPPLIER_PRICE : COLOR_SHORT_SUPPLIER_DELIVERY_DATE;
        },
        processSlots(timeSlots){
            let workpointslots = [];
            let arrayposition = -1;
            let lasttimeslot = null;

            timeSlots.forEach(element => {
                // as timeslots is already sorted so on change of work point id will jump to next array positions
                if(!lasttimeslot || lasttimeslot.work_point_id != element.work_point_id ){
                    arrayposition++;
                    workpointslots.push({
                        workpointid: element.work_point_id,
                        starttimeslots: [],
                        endtimeslots: []
                    });

                }
                // push to the array and then sort the time array in ascending order, so that it will help in finding out min and max time slot
                workpointslots[arrayposition].starttimeslots.push(element.start_time);
                workpointslots[arrayposition].starttimeslots.sort(function (a, b) {
                    return new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b);
                });
                workpointslots[arrayposition].endtimeslots.push(element.end_time);
                workpointslots[arrayposition].endtimeslots.sort(function (a, b) {
                    return new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b);
                });
                lasttimeslot = element;
            });

            let mintime = '23:59';
            let maxtime = '00:00';

            workpointslots.forEach(element => {
                if(element.starttimeslots.length > 0 && new Date('1970/01/01 ' + element.starttimeslots[0]) < new Date('1970/01/01 ' + mintime)){
                    mintime = element.starttimeslots[0];
                }
                if(element.endtimeslots.length > 0 && new Date('1970/01/01 ' + element.endtimeslots[element.endtimeslots.length - 1]) > new Date('1970/01/01 ' + maxtime)){
                    maxtime = element.endtimeslots[element.endtimeslots.length - 1];
                }
            });


            return {
                minTime: mintime,
                maxTime: maxtime,
                workPointSlots: workpointslots
            };
        },
        formatDateWithLocale(dateString, dateAlone = false, format = null) {
            let finalFormat = "L LT";
            if(dateAlone){
                finalFormat = "L";
            }
            if(format){
                finalFormat = format;
            }
          return moment(dateString).locale(this.$i18n.locale).format(dateAlone ? "L" : "L LT");
        },
        formatTimeWithLocale(timeString, format = 'H:i:s') {
            if(!this.isset(timeString) || timeString === '00:00:00') return null;
            return moment(timeString, [format]).locale(this.$store.state.languageId).format('LT')
        },

        translationHelper(key){
            let translation = this.$t(key);

            if (translation !== key) {
                return translation;
            }

            // remove _ from key and make it sentence case
            return key
                .split('.')
                .pop()
                .replace(/_/g, ' ')
                .toString()
                .toUpperCase()
        },
        getCompanyPropertyValue(propertyKey){
            try {
                let companyProperties = JSON.parse(
                    localStorage.getItem("company_properties")
                );

                let property = companyProperties.find(
                    (item) => item.company_setting_key === propertyKey
                );

                if(!property){
                    return null;
                }

                return property.value;

            }catch(e) {
                return null;
            }
        },
        clone(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        getElementById(id) {
            return document.getElementById(id);
        },
    },
};
