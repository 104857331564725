<template>
  <v-card flat>
    <confirmation-model
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-form
      ref="form"
      class="main_form"
      id="supplier-form"
      @submit.prevent="onSubmit()"
    >
      <v-btn type="submit" class="d-none" id="supplier-client"></v-btn>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="name"
              name="name"
              class="to-upper"
              v-on:keydown="handleInput($event, 'alphanumeric', 50)"
              :label="$t('supplier.name')"
              required
              :error-messages="
                $store.state.validation && !$v.name.required
                  ? [this.$t('supplier.name_required')]
                  : []
              "
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="contact"
              class="to-upper"
              v-on:keydown="handleInput($event, 'alphanumeric', 50)"
              :label="$t('supplier.contact')"
              required
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="identifier"
              class="to-upper"
              v-on:keydown="handleInput($event, 'alphanumeric', 50)"
              :label="$t('supplier.identifier')"
              required
              :error-messages="$store.state.validation ?  returnErrorMessage(
                [!$v.identifier.required,this.$t('supplier.identifier_required')],
                [!$v.identifier.maxLength,this.$t('supplier.max_3_character_allowed')]
              ) : []"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              :autocomplete="autocompleteValueLocal"
              v-model="parent_supplier"
              :items="suppliers"
              item-text="name"
              item-value="id"
              class="to-upper to-upper"
              :label="$t('supplier.parent_supplier_id')"
              v-on:keydown="handleInput($event, 'justalphanumeric', 25)"
              v-on:keydown.enter.prevent=""
              hide-details="auto"
              :outlined="is_boxes"
              :dense="is_boxes"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :autocomplete="autocompleteValueLocal"
              v-model="supplierStatus"
              :items="supplierStatuses"
              item-text="text"
              item-value="id"
              class="to-upper to-upper"
              :label="$t('supplier.status')"
              v-on:keydown="handleInput($event, 'justalphanumeric', 25)"
              :error-messages="
                $store.state.validation && !$v.supplierStatus.required
                  ? [this.$t('supplier.supplier_status_required')]
                  : []
              "
              v-on:keydown.enter.prevent=""
              hide-details="auto"
              :outlined="is_boxes"
              :dense="is_boxes"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :autocomplete="autocompleteValueLocal"
              v-model="supplierType"
              :items="supplierTypes"
              item-text="text"
              item-value="id"
              class="to-upper to-upper"
              :label="$t('supplier.type')"
              v-on:keydown="handleInput($event, 'justalphanumeric', 25)"
              :error-messages="
                $store.state.validation && !$v.supplierType.required
                  ? [this.$t('supplier.supplier_type_required')]
                  : []
              "
              v-on:keydown.enter.prevent=""
              hide-details="auto"
              :outlined="is_boxes"
              :dense="is_boxes"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-select
              v-model="familyArticle"
              :items="familyArticles"
              item-value="id"
              item-text="name"
              :label="$t('supplier.family_article')"
              class="mr-0"
              v-on:keydown="handleInput($event, 'justalphanumeric', 25)"
              :error-messages="
                $store.state.validation && !$v.familyArticle.required
                  ? [this.$t('supplier.family_article_required')]
                  : []
              "
              return-object
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn @click="onSubmit()" class="d-none" id="submit-supplier"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-supplier"></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL, VIEW_MODE } from "@/config";
import { DETAILS_MODE, JUST_CANCEL_MODE, ADD_MODE } from "@/config";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  requiredIf,
  phoneNum,
  numeric,
  minLength,
  maxLength,
  alpha,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  mixins: [validationMixin],
  components: {
    "confirmation-model": ConfirmationModel,
  },
  validations: {
    name: {
      required: required,
    },
    identifier: {
      required: required,
      maxLength: maxLength(3)
    },
    supplierType: {
      required: required,
    },
    supplierStatus: {
      required: required,
    },
    familyArticle: {
      required: required
    }
  },
  name: "Supplier",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      DeleteConfirm: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      suppliers: [],
      supplier: {},
      supplierType: null,
      supplierTypes: [],
      contact: null,
      supplierStatus: null,
      supplierStatuses: [],
      familyArticle: null,
      parent_supplier: null,
      autocompleteValueLocal: "off",
      identifier: null,
      is_default: 0,
      name: "",
      parent: null,
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
    };
  },
  mounted() {
    this.$store.commit("setValidation", false);
    this.autocompleteValueLocal = this.autocompleteValue();
    this.header = { Authorization: "Bearer " + this.token };

    this.getSuppliers().then(() => {
      this.$store.commit("setValidation", false);
      if (this.$store.state.editId) {
        this.$store.commit("setFormMode", DETAILS_MODE);
        this.fetchData();
      } else {
        this.$store.commit("setFormMode", ADD_MODE);
      }
    });
    this.getStatuses();
    this.getTypes();
  },
  methods: {
    getFamilyArticles() {
        axios.get(API_BASE_URL + '/family_articles?items_per_page=-1&sortby=order,name', {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
        .then(({data}) => {
          this.familyArticles = data.data;
        })
        .catch(function (error) {
            console.log('an error occured ' + error);
        });
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deleteItem() {
      
      axios({
        method: "delete",
        url: API_BASE_URL + "/suppliers/" + this.$store.state.editId,
        headers: this.header,
      })
        .then(() => {
          this.$router.push("/suppliers").catch(() => {});
        })
        .catch((error) => {
          //
        })
        .finally(() => {
          
        });
    },
    onDelete() {
      console.log("delete clicked");
      this.deletePopUp();
    },
    fetchData() {
      
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/suppliers/" + this.$store.state.editId, {
            headers: this.header,
          })
          .then(({ data }) => {
            this.supplier = data.data;
            this.name = this.supplier.name;
            this.contact = this.supplier.contact;
            this.identifier = this.supplier.identifier;
            this.parent_supplier = this.supplier.parent_supplier;
            this.supplierStatus = this.supplier.status;
            this.supplierType = this.supplier.type;
            this.familyArticle = this.supplier.companySupplier.family_article;
            if (this.supplier.id == 0) {
              this.$store.commit("setFormMode", JUST_CANCEL_MODE);
            } else {
              this.$store.commit("setFormMode", DETAILS_MODE);
            }
            resolve();
          })
          .catch((err) => {
            reject();
          })
          .finally(() => {
            
          });
      });
    },
    onSubmit() {
      this.$store.commit("setValidation", true);
      if (!this.$v.$invalid) {
        if (this.$store.state.editId) {
          this.updateSupplier(this.makeData());
        } else {
          this.addSupplier(this.makeData());
        }
      } else {
        this.$noty.warning(this.$t("please_fill_form_correctly"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      }
    },
    makeData() {
      return {
        name: this.name,
        contact: this.contact,
        identifier: this.identifier,
        parent_supplier_id: this.parent_supplier
          ? this.parent_supplier.id
          : null,
        status_id: this.supplierStatus ? this.supplierStatus.id : null,
        type_id: this.supplierType ? this.supplierType.id : null,
        family_article_id: this.familyArticle ? this.familyArticle.id : null,
      };
    },
    addSupplier(data) {
      
      axios
        .post(API_BASE_URL + "/suppliers", data, { headers: this.header })
        .then((response) => {
          this.$router.push("/suppliers").catch(() => {});
        })
        .catch(function (error) {
          this.$noty.error(
            this.$t("supplier.text_messages.something_went_wrong"),
            { timeout: 5000, layout: "bottomLeft" }
          );
        })
        .finally(() => {
          
        });
    },
    async getStatuses() {
      await this.getCodes('suppliers_statuses', 'suppliers_statuses')
                .then((data) => {
                  this.supplierStatuses = data;
                });
    },
    async getTypes() {
      await this.getCodes('supplier_types', 'supplier_types')
                .then((data) => {
                  this.supplierTypes = data;
                });
    },
    getSuppliers() {
      return new Promise((resolve, reject) => {
        
        axios
          .get(API_BASE_URL + "/suppliers", { headers: this.header })
          .then((data) => {
            this.suppliers = data.data.data;
            resolve();
          })
          .catch((err) => {
            reject();
          })
          .finally(() => {
            
          });
      });
    },
    updateSupplier(data) {
      
      axios
        .put(API_BASE_URL + "/suppliers/" + this.supplier.id, data, {
          headers: this.header,
        })
        .then((response) => {
          this.$router.push("/suppliers").catch(() => {});
        })
        .catch(function (error) {
          console.log("an error occured " + error);
          this.$noty.error(
            this.$t("supplier.text_messages.something_went_wrong"),
            { timeout: 5000, layout: "bottomLeft" }
          );
        })
        .finally(() => {
          
        });
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "supplier.DeleteConfirmation"
      );
      this.confirmationDialogFalseText = this.$t("supplier.Cancel");
      this.confirmationDialogTrueText = this.$t("supplier.Delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
  },
  computed: {},
  watch: {
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.deleteItem();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
};
</script>

<style scoped>
</style>
