<template>
  <v-card>
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab key="label_reserves">
        {{ $t("stocks.label_reserve") }}
      </v-tab>
      <v-tab key="supplier">
        {{ $t("scheduler.supplier") }} {{ $t("vehicles.vehicle_tab.orders") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="'reserves'">
        <v-card flat>
          <v-data-table
            :headers="reservedStocksHeaders"
            :items="reservedStockTableItems"
            :hide-default-footer="true"
            class="elevation-1"
            :items-per-page="reservedStockTableItems.length"
          >
            <template v-slot:item.document_number="{ item }">
              <span
                @click="navigateToOrdersPage(item.order_id)"
                :style="{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }"
              >
                {{ item.identifier }}
              </span>
            </template>

            <template v-slot:item.appointment_date="{ item }">
              <span
                v-if="item.appointment_date"
                @click="navigateToAppointmentPage(item.appointment_id)"
                :style="{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }"
              >
                {{ item.appointment_date }}
              </span>

            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item :key="'supplier'">
        <v-card flat>
          <v-data-table
            :headers="reservedSupplierStockHeaders"
            :items="reservedSupplierStockTableItems"
            :hide-default-footer="true"
            class="elevation-1 mt-5"
            :items-per-page="reservedSupplierStockTableItems.length"
            :sortable="false"

          >
            <template v-slot:item.supplier="{ item }">
              <span>
                {{ item.supplier.supplier_identifier }}
              </span>
            </template>
            <template v-slot:item.document_number="{ item }">
              <span
                @click="navigateToOrdersPage(item.order_id)"
                :style="{
                  color: 'blue',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }"
              >
                {{ item.identifier }}
              </span>
            </template>
            <template v-slot:item.short_name="{ item }">
                <template v-if="item.short_name">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >{{ item.short_name }}</span>
                    </template>
                    <span>{{ item.orderer_name }}</span>
                  </v-tooltip>
                </template>
            </template>

            <template v-slot:item.appointment_date="{ item }">
              <span
                v-if="item.appointment_date"
                @click="navigateToAppointmentPage(item.appointment_id)"
                :style="{
                  color: 'blue',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }"
              >
                {{ item.appointment_date }}
              </span>

            </template>
            <template v-slot:item.supplier_status="{ item }">
                <v-autocomplete
                    v-if="item && item.supplier && item.supplier.supplier_order_id"
                    v-model="item.supplier.status_id"
                    :items="reception_order_statuses"
                    :label="$t('receptions.status')"
                    item-value="id"
                    item-text="text"
                    class="mr-2"
                    return-object
                    @change="updateReceptionStatus(item)"
                ></v-autocomplete>

            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import axios from "@/plugins/axios";
import { mapState } from "vuex";
import { API_BASE_URL } from "@/config";
import moment from "moment";

export default {
  name: "ReservedStocksTable",
  props: {
    articleId: {
      type: Number,
      required: true,
    },
    familyArticleId: {
      type: Number,
      required: true,
    },
      activeTab:{
          default: 0
      }
  },

  data() {
    return {
      reception_order_statuses: [],
      reception_status: null,
      reservedStocks: [],
      supplierReservedStocks: [],
      headers: {},
      familyArticle: null,
      reservedStocksHeaders: [
        {
          text: this.$t("scheduler.client_name"),
          value: "full_name",
            align: "lefr"
        },
        {
          text: this.$t("billings.quantity"),
          value: "quantity",
            align: "center"
        },
        {
          text: this.$t("scheduler.order_no"),
          value: "document_number",
            align: "center"
        },
        {
          text: this.$t("scheduler.appointment_date"),
          value: "appointment_date",
            align: "center"
        },
      ],
      reservedSupplierStockHeaders: [
        {
          text: this.$t("scheduler.supplier"),
          value: "supplier",
          width: "50px",
            align: "center",
          sortable: false,
        },{
          text: this.$t("receptions.orderer"),
          value: "short_name",
          width: "50px",
            align: "center",
          sortable: false,
        },
        {
          text: this.$t("scheduler.client_name"),
          value: "full_name",
          width: "200px",
          sortable: false,
            align: "left"
        },
        {
          text: this.$t("billings.quantity"),
          value: "quantity",
          // width: "10px",
          sortable: false,
            align: "center"
        },
        {
          text: this.$t("scheduler.confirmed_quantity"),
          value: "confirmed_quantity",
          // width: "10px",
          sortable: false,
            align: "center"
        },
          {
              text: this.$t("scheduler.acquisition_price"),
              value: "acquisition_price",
              // width: "10px",
              sortable: false,
              align: "left"
          },
        {
          text: this.$t("scheduler.order_no"),
          value: "document_number",
          // width: "10px",
          sortable: false,
            align: "center"
        },
        {
          text: this.$t("scheduler.appointment_date"),
          value: "appointment_date",
          sortable: false,
            align: "center"
        },
        {
          text: this.$t("scheduler.ordered_on"),
          value: "ordered_on",
          sortable: false,
            align: "center"
        },
        {
          text: this.$t("scheduler.expected_delivery"),
          value: "delivery_date",
          sortable: false,
            align: "center"
        },
        {
          text: this.$t("receptions.status"),
          value: "supplier_status",
          sortable: false,
            align: "center"
        },
      ],
      tab: null,
    };
  },
  async created() {
      this.tab = this.activeTab;

    this.headers = {
      headers: {
        Authorization: "Bearer " + this.AccessToken,
      },
    };
    this.getSupplierOrderStatuses();
    await this.getReservedStocks();
    await this.getSupplierReservedStocks();
    await this.getFamilyArticle();
  },
  computed: {
    ...mapState(["AccessToken"]),
    decimalPrecision() {
      return this.familyArticle ? this.familyArticle.quantity_decimals : 0;
    },
    reservedStockTableItems() {
      return this.reservedStocks.map((item) => {
        return {
          full_name:
            (item.name ? item.name : "") +
            " " +
            (item.first_name ? item.first_name : ""),
          quantity: this.fixDecimal(item.quantity, this.decimalPrecision),
          document_number: item.document_number,
          identifier: item.identifier,
          appointment_date: item.start_time
            ? moment(item.start_time).format("DD/MM/YYYY HH:mm")
            : null,
          order_id: item.order_id,
          appointment_id: item.appointment_id,
        };
      }).sort((a, b) => {
              return b.order_id - a.order_id;
          });

      // sort data by order_id desc
    },
    reservedSupplierStockTableItems() {
      return this.supplierReservedStocks.map((item) => {
        return {
          supplier: item,
          full_name: item.document_number
            ? (item.name ? item.name : "") +
              " " +
              (item.first_name ? item.first_name : "")
            : this.$t("receptions.internal_stock"),
          short_name: item.orderer_user_id ? item.short_name : '',
          name: item.orderer_user_id ? item.name : '',
          quantity: this.fixDecimal(
            item.ordered_quantity,
            this.decimalPrecision
          ),
          confirmed_quantity: this.fixDecimal(
            item.confirmed_quantity,
            this.decimalPrecision
          ),
          document_number: item.document_number,
          identifier: item.identifier,
          acquisition_price: this.fixDecimal(
            item.acquisition_price,
            2
          ),
          appointment_date: item.start_time
            ? moment(item.start_time).format("DD/MM/YYYY HH:mm")
            : null,
          ordered_on: item.created_at
            ? moment(item.created_at).format("DD/MM/YYYY HH:mm")
            : "",
          delivery_date: item.delivery_date
            ? moment(item.delivery_date).format("DD/MM/YYYY")
            : "",
          order_id: item.order_id,
          appointment_id: item.appointment_id,
        };
      }).sort((a, b) => {
              return b.order_id - a.order_id;
          });
    },
  },
  methods: {
    updateReceptionStatus(item){
        if(item && item.supplier && item.supplier.supplier_order_id){
            let data = {
                is_partial_update: 1,
                status_id: item.supplier.status_id ? (typeof item.supplier.status_id == 'object' ? item.supplier.status_id.id : item.supplier.status_id) : null
            }
            return new Promise((resolve) => {
                axios.put(API_BASE_URL + '/suppliers/order/' + item.supplier.supplier_order_id, data, {headers: this.header})
                .then(data => {
                    this.$noty.success(this.$t('receptions.text_messages.reception_updated_successfully'), { timeout: 5000, layout: 'bottomLeft'});
                })
                .finally(() => {
                    resolve();
                });
            });
        }
    },
    getSupplierOrderStatuses() {
        return new Promise((resolve, reject) => {
            this.getCodes(
                "supplier_order_statuses",
                "receptions.statuses"
            ).then((data) => {
                this.reception_order_statuses = data;
                resolve();
            });
        });
    },
    async getReservedStocks() {
      await axios
        .get(
          API_BASE_URL + "/orders/stock_reserve/" + this.articleId,
          this.headers
        )
        .then((response) => {
          this.reservedStocks = response.data.data;
          console.log(this.reservedStocks);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getSupplierReservedStocks() {
      await axios
        .get(
          API_BASE_URL +
            "/supplier_order/articles?article_id=" +
            this.articleId,
          this.headers
        )
        .then((response) => {
          this.supplierReservedStocks = response.data.data;
          console.log("supplier Stock Reserve", this.supplierReservedStocks);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getFamilyArticle() {
      await axios
        .get(
          API_BASE_URL + "/family_articles/" + this.familyArticleId,
          this.headers
        )
        .then((response) => {
          this.familyArticle = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async navigateToOrdersPage(orderId) {
      this.$store.commit("seteditId", orderId);
      this.$router.push("/billing/edit");
    },
    async navigateToAppointmentPage(appointmentId) {
      this.$store.commit("setAppointmentRedirectData", {
        id: appointmentId,
      });
      this.$router.push("/scheduler/edit");
    },
  },
};
</script>
