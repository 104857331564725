<template>
  <v-card class="elevation-0">
    <v-tabs
        v-model="tab"
        height="50px"
        background-color="primary"
        dark
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#client-addresses">
        <v-icon class="mr-1">mdi-map-search-outline</v-icon>
        {{$t('company_settings.tabs.addresses')}}
      </v-tab>

      <v-tab href="#client-bills">
        <v-icon class="mr-1">mdi-currency-eur</v-icon>
        {{$t('company_settings.tabs.working_points')}}
      </v-tab>

      <v-tab href="#client-banks">
        <v-icon class="mr-1">mdi-bank</v-icon>
        {{$t('company_settings.tabs.bank_accounts')}}

      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="'client-addresses'" :value="'client-addresses'">
          <addresses-tab 
            :prop_address.sync="tab_billing_address"
            :prop_shipping_address.sync="tab_shipping_address"
          ></addresses-tab>
      </v-tab-item>

      <v-tab-item :key="'client-bills'" :value="'client-bills'">
          <working-points 
            :prop_workpointdata.sync="tab_working_point"
            class="pa-4"
          ></working-points>
      </v-tab-item>

      <v-tab-item key="client-banks" value="client-banks">
          <CompanyBanks :company-id="3" />
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import AddressesTab from '@/components/CompanySettings/AddressesTab';
import WorkingPoints from '@/components/CompanySettings/WorkingPoints';
import CompanyBanks from "@/components/CompanySettings/CompanyBanks.vue";

export default {
  name:       "Tabs",
  components: {
    CompanyBanks,
    'addresses-tab': AddressesTab,
    'working-points':     WorkingPoints
  },
  props:{
    prop_address: [Object],
    prop_shipping_address: [Object],
    prop_workpointdata: [Array]
  },
  data() {
    return {
      tab_billing_address: null,
      tab_shipping_address: null,
      tab_working_point: [],
      tab:  null,
      text: "",
    }
  },
  watch: {
    tab_billing_address(val){
      this.$emit("update:prop_address", this.tab_billing_address);
      // this.$emit("update:prop_shipping_address", this.tab_shipping_address);
    },
    tab_shipping_address(val){
      // this.$emit("update:prop_address", this.tab_billing_address);
      this.$emit("update:prop_shipping_address", this.tab_shipping_address);
    },
    tab_working_point(val){
      console.log("tab updating point changed and now trying to update prop_workpointdata", val);
      this.prop_workpointdata = val;
      this.$emit("update:prop_workpointdata", val);
    },
    prop_workpointdata(val){
      console.log("working point", val);
      this.tab_working_point = val;
    },
    prop_address(val){
      console.log("tab address", val);
      this.tab_billing_address = val;
    },
    prop_shipping_address(val){
      console.log("tab shipping address", val);
      this.tab_shipping_address = val;
    }
  }
}
</script>

<style scoped>

</style>
