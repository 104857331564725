<template>
  <v-card flat>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-form
      ref="form"
      class="main_form"
      id="family-client-form"
      @submit.prevent="submit"
    >
      <v-btn type="submit" class="d-none" id="submit-family-client"></v-btn>
      <input type="hidden" name="id" v-model="form.id" />
      <v-container fluid>
        <v-row>
          <v-col col="1" sm="1">
            <v-checkbox 
              :autocomplete="autocompleteValueLocal"
              :label="$t('family_client.is_default')"
              v-model="form.is_default"
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="form.name"
              name="name"
              class="to-upper"
              :label="$t('family_client.name')"
              :error-messages="$store.state.validation && !$v.form.name.required ? [this.$t('family_client.name_required')] : [] "
              required
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
                :autocomplete="autocompleteValueLocal"
                flat
                :items="roles"
                v-model="form.restricted_to_role_id"
                :label="$t('family_client.restricted_role_id')"
                class="mr-2 to-upper"
                item-value="id"
                item-text="description"
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
                hide-no-data
            ></v-autocomplete>
          </v-col>
        </v-row>
        
        
      </v-container>
    </v-form>

    <v-btn @click="onSubmit()" class="d-none" id="submit-family-client"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-family-client"></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL, VIEW_MODE } from "@/config";
// import { EDIT_MODE } from "@/config";
import { DETAILS_MODE } from "@/config";
import { ADD_MODE } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha, minValue, maxValue } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  name: "FamilyClient",
  components:{
    "confirmation-model":	ConfirmationModel,
  },
  validations: {
    form:{
      name:{
        required: required
      }
    },
  },
  data () {
    const defaultForm = Object.freeze({
        id: '',
        name: '',
        is_default: false,
        restricted_to_role_id: null
    });
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      form: Object.assign({}, defaultForm),
      operation: 'edit',
      token: this.$store.state.AccessToken,
      header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
      autocompleteValueLocal : 'off',
      confirmationDialog: false,
      confirmationDialogConfirmationText:null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      roles: [],
    }
  },
  mounted () {
    this.autocompleteValueLocal = this.autocompleteValue();
    this.setCurrentFormOperation();
    this.header = { Authorization: "Bearer " + this.token };
    this.getRoles();
    
    if (this.operation === 'edit') {
      this.$store.commit('setFormMode', DETAILS_MODE);
      this.fetchData();
    }
    else {
      this.$store.commit('setFormMode', ADD_MODE);
    }
  },
  methods: {
    getRoles() {
      return new Promise((resolve, reject) => {
        
        return axios.get(API_BASE_URL + '/roles', {headers: this.header})
            .finally(() => {
              
            })
            .then((response) => {
              this.roles = Object.values(response.data.data)
              resolve(this.roles);
            }).catch((error) => {
              reject(error)
            })
      })
    },
    deleteItem(){
      
      axios({
        method: 'delete',
        url: API_BASE_URL + '/family_clients/' + this.$store.state.editId,
        headers: this.header,
      }).then(resDeleteFamilyClient => {
        this.$noty.success( this.$t('family_client.deleted_successfully') , { timeout: 5000, layout: 'bottomLeft'});
        this.$router.push('/family-clients').catch(() => {});
      }).catch((error) => {
        
      })
      .finally(() => {
        
      })
    },
    onDelete () {
      this.deletePopUp()
    },
    fetchData () {
      axios.get(API_BASE_URL + '/family_clients/' + this.$store.state.editId, {headers: this.header})
        .then(({ data }) => {
          this.form.id = data.data.id;
          this.form.name = data.data.name;
          this.form.is_default = data.data.is_default;
          this.form.restricted_to_role_id = data.data.restricted_to_role;
        })
        .catch((err) => {
          console.log(err)
        });
    },
    submit () {
      this.$store.commit("setValidation",true); 
      if (!this.$v.$invalid ) {
        if (this.operation === 'add') {
          this.addFamilyClient(this.makeData());
        } else {
          this.updateFamilyClient(this.makeData());
        }
      }
      else{
        this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft'})
      }
    },
    makeData(){
      return {
          name: this.form.name,
          restricted_to_role_id: this.form.restricted_to_role_id ? (typeof this.form.restricted_to_role_id == 'object' ? this.form.restricted_to_role_id.id : this.form.restricted_to_role_id) : null,
          is_default: this.form.is_default,
        };
    },
    addFamilyClient (data) {
      
      axios.post(API_BASE_URL + '/family_clients', data, {headers: this.header})
        .then(response => {
          if (response.status === 201) {
            this.$router.push("/family-clients").catch(() => {});
            this.$noty.success( this.$t('family_client.family_client_added_successfully') , { timeout: 5000, layout: 'bottomLeft'});
          }
          else {
            alert('ERROR on saving. Please try again later');
          }
        })
        .catch(function (error) {
          console.log('an error occured ' + error);
        })
        .finally(() => {
          
        });
    },
    updateFamilyClient (data) {
      axios.put(API_BASE_URL + '/family_clients/' + this.form.id, data, {headers: this.header})
        .then(response => {
          if (response.status === 201) {
            this.$noty.success( this.$t('family_client.family_client_updated_successfully') , { timeout: 5000, layout: 'bottomLeft'});
            this.$router.push("/family-clients").catch(() => {});
          }
          else {
            alert('ERROR on saving. Please try again later');
          }
        })
        .catch(function (error) {
          console.log('an error occured ' + error);
        });
    },
    setCurrentFormOperation () {
      this.operation = (this.$route.path.indexOf('/add') > 0) ? 'add' : 'edit';
    },
    confirmationButtonClicked(buttonClicked){
        this.confirmationDialogButtonClickedIs = buttonClicked;
        this.confirmationDialog=false;
    },
    deletePopUp(){
        this.confirmationDialogConfirmationText = this.$t("family_client.delete_confirmation");
        this.confirmationDialogFalseText = this.$t("family_client.cancel");
        this.confirmationDialogTrueText = this.$t("family_client.delete");
        this.confirmationDialogOperation = 'delete';
        this.confirmationDialog = true;
    },
  },
  watch:{
    confirmationDialogButtonClickedIs(newVal){
        if(newVal == true &&  this.confirmationDialogOperation != null){
            if(this.confirmationDialogOperation == 'delete' && this.confirmationDialogButtonClickedIs == true){
                this.deleteItem();
            }
            this.confirmationDialogButtonClickedIs = null;
        }
    }
  },
  computed: {
    
  }
}
</script>

<style scoped>
</style>
