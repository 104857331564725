<template>
  <div>

  </div>
</template>
<script>
import axios from "axios";
import {API_BASE_URL} from "@/config";

export default {
  name: 'PopupNotificationHandler',
  data() {
    return {}
  },
  mounted() {
    this.getNewNotifications();
    setInterval(() => {
      this.getNewNotifications();
    }, (60000 * 3));
  },
  methods: {
    getNewNotifications() {
      if (!this.isset(this.$store.state.AccessToken)) return null
      axios.get(API_BASE_URL + '/message_notification_updates', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.AccessToken
        }

      }).then(({data}) => {
        let messages = data;
        if (messages.length > 0) {
          messages.forEach((message) => {
            alert(message.body);
            // this.$noty.warning(message.body, { timeout: this.computerMessageTimeoutByLength(message.body), layout: 'bottomLeft'})
          });
        }
      })
    },
    computerMessageTimeoutByLength(message) {
      let timeout = 5000;
      if (message.length > 100) {
        timeout = 10000;
      }
      return timeout;
    }
  }
}
</script>