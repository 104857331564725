<template>
  <div class="root-element">
    <v-data-table
        :headers="headers"
        :items="triggerInstances"
        :items-per-page="10"
        class="elevation-1"
        @click:row="handleRowClick"
    >
      <template v-slot:item.serial="{ item, index }">
        {{ index + 1 }}
      </template>

      <template v-slot:item.trigger="{ item }">
        {{ translateKey("triggers_section.names." + item.trigger.name) }}
      </template>

      <template v-slot:item.nonSystemConditions="{ item }">
        <v-chip
            v-for="(condition, index) in item.nonSystemConditions"
            :key="index"
            color="primary"
            dark
            class="ma-1"
        >
          <span>{{ translateKey('triggers_section.params.' + index) }}: {{ condition }}</span>
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="red" @click="deleteInstancePopup(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="(status) => status ? deleteTriggerInstance() : ''"
    ></confirmation-model>
  </div>
</template>
<script>
import AddNewTriggerModal from "@/views/Triggers/TriggersForm.vue";
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import moment from "moment";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  name: "TriggersListing",
  components: {ConfirmationModel},
  props: {
    parentModel:{
      type: Object,
      default: null
    },
    parentModelPrimaryKey: {
      type: String,
      default: null
    },
    parentModelForeignKey: {
      type: String,
      default: ""
    },
  },
  computed:{
    modelKey(){
      if(!this.parentModel && !this.parentModelPrimaryKey) {
        return null;
      }
      return this.parentModel[this.parentModelPrimaryKey];
    }
  },
  data() {
    return {
      triggers: [],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "serial"
        },
        {text: this.translateKey('triggers_section.trigger'), value: "trigger", align: "start"},
        {text: this.translateKey('triggers_section.conditions'), value: "nonSystemConditions", align: "start"},
        {text: this.translateKey('triggers_section.creation_time'), value: "created_at", align: "start"},
        {text: this.translateKey('triggers_section.execution_time'), value: "executed_at", align: "start"},
        {text: this.translateKey('triggers_section.execution_status'), value: "is_successfully_executed", align: "end"},
        {text: this.translateKey('triggers_section.actions'), value: "actions", align: "end", sortable: false}
      ],
      triggerInstances: [],
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken
        }
      },
      confirmationDialog: false,
      confirmationDialogOperation: "",
      confirmationDialogConfirmationText: "",
      confirmationDialogTrueText: "",
      confirmationDialogFalseText: "",
      selectedVehicleHistory: null,
      selectedTriggerInstance: null,
    };
  },
  mounted(){
    this.getTriggers();
    this.getTriggerInstances();
  },
  methods: {
    handleRowClick(item){
      this.editInstance(item)
    },
    getTriggers() {
      axios.get(API_BASE_URL + "/triggers", this.formConfig)
          .then(response => {
            this.triggers = response.data.data.map(item => {
              item.name = this.translateKey('triggers_section.names.' + item.name)
              return item;
            });
          })
          .catch(error => {
            console.log(error);
          });
    },
    getTriggerInstances() {
      axios.get(API_BASE_URL + "/trigger_instances?model_key="  + this.parentModelForeignKey  + "&model_value=" + this.modelKey, this.formConfig)
          .then(response => {
            this.triggerInstances = response.data.data.map(item => {
              item.created_at = moment(item.created_at).format('YYYY-MM-DD HH:mm:ss');
              item.executed_at = item.executed_at ? moment(item.executed_at).format('YYYY-MM-DD HH:mm:ss') : null;

              // item.nonSystemConditions = item.conditions.filter((condition, index) => {
              //   return index !== "vehicle_id"
              // });

              item.nonSystemConditions = Object.fromEntries(Object.entries(item.conditions).filter(([key, value]) => {
                let conditionParam = item.trigger.params.find( param => param.field === key);

                return conditionParam &&
                    conditionParam.type &&
                    conditionParam.type.key &&
                    conditionParam.type.key.toString().includes('system') === false;
              }));

              return item;
            })
          })
          .catch(error => {
            console.log(error);
          });
    },
    editInstance(item) {
      if(this.confirmationDialog){
        return;
      }

      this.$emit('editTriggerInstance', item);
    },
    deleteInstancePopup(triggerInstance){
      this.selectedTriggerInstance = triggerInstance;
      this.confirmationDialogConfirmationText = this.translateKey("triggers_section.delete_confirmation");
      this.confirmationDialogFalseText = this.translateKey("navigation.no");
      this.confirmationDialogTrueText = this.translateKey("navigation.yes");
      this.confirmationDialogOperation = "deleteInstance";
      this.confirmationDialog = true;
    },
    deleteTriggerInstance(){
      axios.delete(`${API_BASE_URL}/trigger_instances/${this.selectedTriggerInstance?.id}`, this.formConfig)
          .finally(() => {
            
          })
          .then(() => {
            this.$noty.success(this.translateKey("triggers_section.delete_success"),{timeout: 5000, layout: 'bottomLeft'});
            this.getTriggerInstances();
          });
    },
  }
}
</script>