<template>
    <div>
        <v-row>
        <v-col cols="12" sm="2">
            <report-listing-filter
                :selected.sync="selectedReport"
            ></report-listing-filter>
        </v-col>
        <v-col cols="12" sm="10">
            <v-row v-if="selectedReport">
            <!-- Parameters View -->
            <v-col v-if="! $store.state.reportViewData" cols="12" sm="8" class="mt-4 ml-4">
                <h2>{{ selectedReport.name }} </h2>
                <v-row>
                    <v-col>
                        <template v-for="(params, index) in selectedReport.params">
                            <v-menu
                                ref="menu"
                                v-if="params.is_visible && params.parameter_type.key == 'date'"
                                :key="index"
                                v-model="dynamicParams['menu_' + params.field]"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                v-on:keydown.enter.prevent=""
                                :error-messages="
                                    $store.state.validation && !$v.dynamicParams[params.field].required
                                    ? [$t('required')]
                                    : []
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dynamicParams[params.field]"
                                    :label="params.name"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    v-on:keydown.enter.prevent=""
                                    @focus="dynamicParams[params.field] = null"
                                    :outlined="is_boxes"
                                    :dense="is_boxes"
                                    class="to-upper"
                                    :error-messages="
                                        $store.state.validation && !$v.dynamicParams[params.field].required
                                        ? [$t('required')]
                                        : []
                                    "
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                :first-day-of-week="1"
                                :weekday-format="getDay"
                                v-model="dynamicParams[params.field]"
                                no-title
                                scrollable
                                :locale="userLanguageId"
                                hide-details
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                >
                                <v-spacer></v-spacer>
                                </v-date-picker>
                            </v-menu>
                            <v-text-field
                                class="to-upper"
                                :label="params.name"
                                :key="index"
                                v-if="params.is_visible && params.parameter_type.key == 'number'"
                                v-model="dynamicParams[params.field]"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                :error-messages="
                                    $store.state.validation && !$v.dynamicParams[params.field].required
                                    ? [$t('required')]
                                    : []
                                "
                            ></v-text-field>
                            <v-text-field
                                class="to-upper"
                                :label="params.name"
                                :key="index"
                                v-if="params.is_visible && params.parameter_type.key == 'string'"
                                v-model="dynamicParams[params.field]"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                :error-messages="
                                    $store.state.validation && !$v.dynamicParams[params.field].required
                                    ? [$t('required')]
                                    : []
                                "
                            ></v-text-field>
                            <v-autocomplete
                                v-if="params.is_visible && params.parameter_type.key == 'dropdown'"
                                :error-messages="
                                    $store.state.validation && !$v.dynamicParams[params.field].required
                                    ? [$t('required')]
                                    : []
                                "
                                :key="index"
                                flat
                                :items="params.dropdown_values"
                                v-model="dynamicParams[params.field]"
                                :label="params.name"
                                class="mr-2 to-upper"
                                item-value="value"
                                item-text="key"
                                @focus="dynamicParams[params.field]=null"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                hide-no-data
                            ></v-autocomplete>
                        </template>
                    </v-col>
                </v-row>
            </v-col>
            <!-- Data Table View -->
            <v-col v-else>
                <v-data-table
                    :height="'calc(-155px + 100vh)'"
                    fixed-header
                    :footer-props="{
                    'items-per-page-options': dataTableOptions
                    }"
                    :headers="headers"
                    :items="reportDataInRowsColumns"
                    :options="options"
                    :loading-text="this.$t('loading')"
                    class="elevation-0"
                    :items-per-page="itemsPerPage"
                    item-key="identifier"
                    style="width: 100%"
                >
                </v-data-table>
            </v-col>
            <!-- <v-spacer></v-spacer>
            <v-col> </v-col> -->
            </v-row>
            <v-row> </v-row>
        </v-col>
        </v-row>
        <v-btn @click="resetParams();" class="d-none" id="reset-page-data"></v-btn>
        <v-btn @click="runReport();" class="d-none" id="run-report"></v-btn>
        <v-btn @click="cancelRunReport()" class="d-none" id="cancel-run-report"></v-btn>
        <v-btn @click="saveReport();" class="d-none" id="save-report"></v-btn>
    </div>
</template>
<script>
import ReportListingFilter from "@/components/Report/ReportListingFilter.vue";
import { REPORT_MODE } from "@/config";
import axios from '@/plugins/axios';
import { required, email, requiredIf } from "vuelidate/lib/validators";
import {API_BASE_URL} from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { validationMixin } from 'vuelidate'

export default {
    mixins: [validationMixin],
    name: "ReportListing",
    components: {
        "report-listing-filter": ReportListingFilter,
    },
    validations() {
        const dynamicParams = {};
        this.selectedReport.params.forEach(param => {
            console.log("attr",param.parameter_type.key);
            if(param.is_visible && param.is_required ){ // if is visible and required 
                dynamicParams[param.field] = {
                        required: requiredIf(function() {
                            console.log("dynam", this.dynamicParams[param.field], param.field);
                            if(param.parameter_type.key.includes('system')){ // and if is system type then not required
                                return false;
                            }
                            return this.dynamicParams[param.field] ?? true;
                        }),
                };
            }
        });
        return { dynamicParams };
    },
    
    data() {
        return {
            // downloadCSVCountValue: 1,
            selectedReport: null,
            dynamicParams: {},
            reportData: null,
            reportDataInRowsColumns: [],
            CSVkeysOrHeaders: [],
            headers: [],
            totalItems: 0,
            isLoading: false,
            options: {},
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
        };
    },
    mounted(){
        this.$store.commit('setFormMode', REPORT_MODE);
        this.$store.commit('setReportViewData', false)
        this.$store.commit("setValidation", false); // disable validation

    },
    methods: {
        cancelRunReport(){
            this.$store.commit('setReportViewData', false);
            this.resetParams();
            // this.reportDataInRowsColumns = [];
        },
        getQueryStringFromDynamicParams(){
            let queryString = "";
            Object.entries(this.dynamicParams).forEach(([key, value]) => {
                if(key.startsWith('menu') || ! value ){
                    return 
                }
                queryString += key + "=" + value + "&"
            });
            return queryString.slice(0,-1);
        },
        runReport(){
            this.$store.commit("setValidation", true); // enable validation
            if (this.$v.$invalid) {
                this.$noty.warning(this.$t("please_fill_form_correctly"), {
                    timeout: 5000,
                    layout: "bottomLeft",
                });
                return false;
            }
            else{
                
                axios.get(API_BASE_URL + '/reports/' + this.selectedReport.id + "/run?" + 
                this.getQueryStringFromDynamicParams()
                , {
                    headers: { Authorization: "Bearer " + this.$store.state.AccessToken },
                })
                .then(({ data }) => {
                    this.reportData = data;
                    this.$store.commit('setReportViewData', true)
                })
                .catch((error) => {
                    this.$store.commit('setReportViewData', false)
                    console.log('an error occured ' + error);
                    this.$noty.error(this.$t("stocks.something_went_wrong"), { timeout: 5000, layout: "bottomLeft"});
                    this.cancelRunReport();
                })
                .finally(() => {
                    
                    this.$store.commit("setValidation", false); // disable validation
                });
            }
        },
        resetParams(){
            let params = {};
            this.headers = [];
            if(this.selectedReport && this.selectedReport.params){
                this.selectedReport.params.forEach((element) => {
                    params[element.field] = null;
                    if(element.type == 'date'){
                        params['menu_' + element.field] = null
                    }
                })
                this.dynamicParams = params;

                // Updating Headers
                this.headers.push({ text: this.$t('srno'), align: 'start', sortable: false, value: this.$t('srno')},); // Add Sr No to headers
                this.selectedReport.fields.forEach((element) => {
                    this.headers.push({ text: element.translation, align: 'start', sortable: false, value: element.translation},);
                })
            }
        },
        makeFileName(){
            return this.selectedReport.name;
        },
        saveReport(){
            this.downloadCSVFromJson(this.makeFileName(), this.CSVkeysOrHeaders ,this.reportDataInRowsColumns); 
        },
    },
    watch:{
        selectedReport(){
            this.resetParams();
            this.cancelRunReport();
        },
        reportData(val){
            if(val){
                let dataRows = val.replace(/"/g, '').split("\n");
                let finalData  = [];
                this.CSVkeysOrHeaders  = dataRows[0].split(";"); // Removing double Quotes and Splitting by SemiColon(;)
                this.CSVkeysOrHeaders.unshift(this.$t('srno')); // Add Sr No to headers
                console.log("CSV Headers", this.CSVkeysOrHeaders);
                let count = -1;
                let rownumber = 1;
                dataRows.forEach((record) => {
                    count++;
                    if(count == 0){
                        // return because first row will contain headers
                        return
                    }
                    let dataInColumns = record.split(";");
                    let rowObject = {};
                    let rowColumnsNullCount = 0;
                    for (let index = 0; index < this.CSVkeysOrHeaders.length; index++) {
                        if(this.CSVkeysOrHeaders[index] == this.$t('srno')){
                            rowObject[this.CSVkeysOrHeaders[index]] = rownumber; // Add Sr No to key
                        }
                        else{
                            rowObject[this.CSVkeysOrHeaders[index]] = dataInColumns[index-1]
                        }
                        if(rowObject[this.CSVkeysOrHeaders[index]] && this.CSVkeysOrHeaders[index] != this.$t('srno') ){
                            rowColumnsNullCount++;
                        }
                    }
                    console.log("record", rowColumnsNullCount, rowObject);
                    if(rowColumnsNullCount == 0){
                        return;
                    }
                    finalData.push(rowObject);
                    rownumber++;
                })
                console.log("data rows", dataRows);
                this.reportDataInRowsColumns = finalData;
                this.totalItems = count - 1;
                // if(this.totalItems > this.downloadCSVCountValue){
                //     this.saveReport();
                // }
            }
        }
    }
};
</script>
