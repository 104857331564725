<template>
  <v-card class="elevation-0">
    <v-tabs
        v-if="checkTag('tyre_depot') || checkTag('billing')"
        v-model="tab"
        background-color="light-blue darken-2"
        dark
        align-with-title
        center-active
        height="50px"
    >
      <v-tab v-if="checkTag('vehicles')" href="#vehicle-history" :key="1">
        {{ $t("vehicles.vehicle_tab.history") }}
      </v-tab>
      <v-tab v-if="checkTag('tyre_depot')" href="#vehicle-tires-hotel">
        {{ $t("vehicles.vehicle_tab.tyre_hotel") }}
      </v-tab>
      <v-tab v-if="checkTag('vehicles')" href="#vehicle-orders">
        {{ $t("vehicles.vehicle_tab.attachments") }}
      </v-tab>

      <v-tab v-if="checkTag('vehicles')" href="#vehicle-triggers">
        {{ $t("triggers_section.trigger_instances") }}
      </v-tab>

      <v-tab v-if="checkTag('vehicles') && vehicle.tracking_device" href="#vehicle-map-location"
             @click="attemptMapReload">
        {{ $t("vehicles.vehicle_tab.map_location") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
          :key="'vehicle-tires-hotel'"
          :value="'vehicle-tires-hotel'"
      >
        <depot-tab :vehicle="vehicle"></depot-tab>
      </v-tab-item>
      <v-tab-item :key="'vehicle-orders'" :value="'vehicle-orders'">
        <attachment-listing v-if="vehicle" :resource="'vehicles'" :data="vehicle.id"></attachment-listing>
      </v-tab-item>
      <v-tab-item key="vehicle-history" value="vehicle-history">
        <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
              <span style="max-width: 300px">
                <v-combobox
                    clearable
                    :label="$t('vehicles.vehicle_tab.search')"
                    prepend-inner-icon="mdi-magnify"
                    :items="titlePredictions"
                    variant="underlined"
                    v-model="vehicleHistorySearch"
                    @keyup="vehicleHistorySearch = $refs.titleSearchInput.internalSearch"
                    ref="titleSearchInput"
                    class="to-upper"
                ></v-combobox>
              </span>
          <span>
          <vehicle-history-form-modal
              v-if="vehicle"
              :vehicle-history="selectedVehicleHistory"
              :vehicle="vehicle"
              @updateVehicleHistory="(history) => vehicleHistoryAddedHandler(history)"
              @close="selectedVehicleHistory = null"
          ></vehicle-history-form-modal>
            </span>
        </div>
        <div>
          <vehicle-history
              v-if="vehicle"
              :vehicle="vehicle"
              :key="vehicleHistoryUpdated"
              :search="vehicleHistorySearchReady"
              @editVehicleHistory="(history) => editVehicleHistoryHandler(history)"
          ></vehicle-history>

        </div>
      </v-tab-item>
      <v-tab-item key="vehicle-triggers" value="vehicle-triggers">

        <div class="my-4" style="display: flex; flex-wrap: wrap; justify-content: space-between">
          <v-row>
            <v-col align="right">
              <v-btn class="ma-2" color="primary"
                     v-if="vehicle"
                     @click="addNewTriggerHandler()"
              >
                {{ $t("nav_buttons.add") }}
                <v-icon dark right> mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <span>
            
          <AddNewTriggerModal
              v-if="vehicle"
              :parent-model="vehicle"
              :trigger-instance="selectedTriggerInstance"
              parent-model-primary-key="id"
              @triggerSaved="triggerInstanceUpdated++"
              @close="selectedTriggerInstance = null"
          >
          </AddNewTriggerModal>
            </span>
        </div>
        <div class="mt-2">
          <TriggersListing
              v-if="vehicle"
              :parent-model="vehicle"
              parent-model-primary-key="id"
              parent-model-foreign-key="vehicle_id"
              :key="triggerInstanceUpdated"
              @editTriggerInstance="(triggerInstance) => editTriggerInstanceHandler(triggerInstance)"
          ></TriggersListing>


        </div>
      </v-tab-item>
      <v-tab-item v-if="vehicle.tracking_device" key="vehicle-map-location" value="vehicle-map-location">
        <v-row class="my-4">
          <v-col md="3" sm="12" style="height: fit-content">
            <div class="d-flex flex-nowrap justify-content-between">
              <h3 style="margin-left: 15px" v-if="trackingDevice" class="mt-2 mb-2 mr-2">
                {{ $t('mappings.live_information') }}
              </h3>
              <v-btn v-if="vehicle.tracking_device" color="primary" @click="overlay = true">
                {{ $t('mappings.view_history_on_map') }}
                <v-icon class="ml-1">mdi-history</v-icon>
              </v-btn>
            </div>
            <VehicleLiveInformation :vehicle="vehicle" :parentTrackingDevice="trackingDevice"
                                    @trackingDeviceUpdated="(newVal) => handleDeviceUpdate(newVal)"/>
            <v-dialog fullscreen v-model="overlay">
              <v-card>
                <VehicleMap :tracking-device="trackingDevice" @close="overlay = false"/>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="9" sm="12" style="height: 20vh!important;">
            <div id="map" class="map" style='width: 100%; height: 40vh!important;'></div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AttachmentsListing from "@/components/AttachmentsListing";
import DepotTab from "@/components/Depot/DepotTab";
import VehicleHistory from "@/components/Vehicles/VehicleHistory.vue";
import VehicleHistoryFormModal from "@/components/Vehicles/VehicleHistoryFormModal.vue";
import _ from "lodash";
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import {DEFAULT_ITEMS_PER_PAGE_OPTIONS} from "@/config";
import TriggersListing from "@/views/Triggers/Listing.vue";
import AddNewTriggerModal from "@/views/Triggers/TriggersForm.vue";
import VehicleLiveInformation from "@/components/Vehicles/VehicleLiveInformation.vue";
import {Map, NavigationControl, Marker} from "maplibre-gl";
import {mapState} from "vuex";
import VehicleMap from "@/components/Vehicles/VehicleMap.vue";

export default {
  components: {
    VehicleMap,
    VehicleLiveInformation,
    AddNewTriggerModal,
    TriggersListing,
    "depot-tab": DepotTab,
    "attachment-listing": AttachmentsListing,
    VehicleHistory,
    VehicleHistoryFormModal,
  },
  refs: ["titleSearchInput"],
  props: {
    vehicle: [Object],
  },
  name: "Vehicle-Tabs",
  data() {
    return {
      tab: null,
      overlay: false,
      vehicleHistoryUpdated: 0,
      triggerInstanceUpdated: 0,
      selectedVehicleHistory: null,
      selectedTriggerInstance: null,
      vehicleHistorySearch: "",
      vehicleHistorySearchReady: "",
      predictionLoading: false,
      allTitlePredictions: [],
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken
        }
      },
      oldValTrackingDevice: null,
      trackingDevice: null,
    };
  },
  watch: {
    vehicleHistorySearch: _.debounce(function (val) {
      this.vehicleHistorySearchReady = val;
    }, 500),
    tab(val) {
      let tabsWhitelistedForMapUpdate = ["vehicle-map-location", "vehicle-live-info"];
      this.clearTrackingDeviceUpdateTimer();

      if (tabsWhitelistedForMapUpdate.includes(val)) {
        this.setTrackingDeviceUpdateTimer();
      }
    },
    forceReloadComponentKey() {
      console.log("Navigating Away from Vehicle Tabs");
    }
  },
  computed: {
    ...mapState(["forceReloadComponentKey"]),
    titlePredictions() {
      // this.vehicleHistorySearch == "" Return the first 10 elements of this.allTitlePredictions
      return this.allTitlePredictions.filter((item) => {
        return this.vehicleHistorySearch
            ? item.toLowerCase().includes(this.vehicleHistorySearch.toString().toLowerCase())
            : true;
      }).slice(0, 10)
    }
  },
  setup() {
    return {
      document
    }
  },
  mounted() {
    this.getTitlePredictions();
  },

  unmounted() {
    this.clearTrackingDeviceUpdateTimer();
  },
  methods: {
    handleDeviceUpdate(newVal) {
      this.trackingDevice = newVal;
      this.reloadMap();
    },
    async reloadMap() {
      if (!this.trackingDevice) {
        this.trackingDevice = await this.getTrackingDevice(this.vehicle.tracking_device.serial);
      }

      if (
          this.oldValTrackingDevice?.position?.longitude === this.trackingDevice?.position?.longitude &&
          this.oldValTrackingDevice?.position?.latitude === this.trackingDevice?.position?.latitude
      ) {
        return false;
      }

      let coordinates = [this.trackingDevice.position.longitude, this.trackingDevice.position.latitude];
      const mapOptions = {
        container: 'map',
        style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=f4IJi1mRlspltChrcUM3',
        center: coordinates,
        zoom: 14
      };

      setTimeout(() => {
        let map = new Map(mapOptions);
        new Marker({color: "#FF0000"})
            .setLngLat(coordinates)
            .addTo(map);
      }, 500);


    },
    attemptMapReload() {
      try {
        this.reloadMap();
      } catch (e) {
        // Notification Bottom Left
        this.$noty.error(this.$t('vehicles.live_location.load_error'), {timeout: 5000, layout: "bottomLeft"});
      }
    },
    getTrackingDevice(deviceId = null, setLoading = true) {
      return new Promise((resolve, reject) => {
        if (setLoading) {
        //   
        }
        deviceId = deviceId ?? this.vehicle.tracking_device.serial;

        let formConfig = JSON.parse(JSON.stringify(this.formConfig));
        formConfig.signal = this.abortController()

        return axios.get(API_BASE_URL + '/tracking-devices/' + deviceId, formConfig)
            .finally(() => {
              if (setLoading) {
                // 
              }
            }).then(({data}) => {
              this.oldValTrackingDevice = JSON.parse(JSON.stringify(this.trackingDevice));
              this.trackingDevice = data;
              this.reloadMap();
              resolve(data)
            }).catch((error) => {
              console.log(error.response)
            })

      });
    },
    setTrackingDeviceUpdateTimer() {
      this.clearTrackingDeviceUpdateTimer();

      setTimeout(async () => {
        if (!this.trackingDevice) {
          await this.getTrackingDevice();
          return true;
        }

        let timeInterval = this.trackingDevice.position.attributes.motion ? 6_000 : 60_000;
        let intervalTimer = setInterval(async () => {
          this.trackingDevice = await this.getTrackingDevice(this.vehicle.tracking_device.serial, false)
        }, timeInterval)
        sessionStorage.setItem('intervalTimer', intervalTimer);
      }, 15000)


    },
    clearTrackingDeviceUpdateTimer() {
      let timeInterval = sessionStorage.getItem('intervalTimer');
      if (timeInterval) {
        clearInterval(timeInterval)
      }
    },
    addNewTriggerHandler() {
      document.getElementById('addTriggerButton').click()
    },
    vehicleHistoryAddedHandler(history) {
      // generate random number to force re-rendering of the component
      this.vehicleHistoryUpdated = Math.random();
      this.selectedVehicleHistory = null;
    },
    editVehicleHistoryHandler(history) {
      this.selectedVehicleHistory = history;
      document.getElementById('addVehicleHistoryButton').click();
      // this.vehicleHistoryUpdated = history.id;
    },
    editTriggerInstanceHandler(triggerInstance) {
      this.selectedTriggerInstance = triggerInstance;
      document.getElementById('addTriggerButton').click();
      // this.vehicleHistoryUpdated = history.id;
    },
    getTitlePredictions(search = "") {
      let formConfig = this.formConfig
      // formConfig.signal = this.abortController();
      this.predictionLoading = true;
      return axios.get(`${API_BASE_URL}/vehicle_history/title/predictions?search=${search}`, formConfig)
          .finally(() => {
            this.predictionLoading = false;
          })
          .then(response => {
            this.allTitlePredictions = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
  },
};
</script>

<style scoped>
@import '~maplibre-gl/dist/maplibre-gl.css';

.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px); /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.watermark {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 999;
}
</style>
