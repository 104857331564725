<template>
  <v-dialog
      max-width="500"
      v-model="dialog"
  >
    <v-card>
      <v-card-title>
        {{ orderDetailDoesNotHaveCounter ? $t('order_counter.add_new') : $t('order_counter.title') }}
        <v-spacer></v-spacer>

        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text >
        <v-form>
          <v-row>
            <v-col cols="12">
              <v-select
                  :items="formattedUsers"
                  item-text="name"
                  item-value="id"
                  :disabled="counter !== null"
                  v-model="counterForm.assigned_user_id"
                  :label="$t('order_counter.select_user')"
              ></v-select>
            </v-col>

            <v-col cols="12" v-if="counterTypeDistance">
              <v-select
                  :items="clientVehicles"
                  item-text="registration_number"
                  item-value="id"
                  :disabled="counter !== null"
                  v-model="counterForm.vehicle_device_id"
                  :label="$t('order_counter.select_vehicle')"
              ></v-select>
            </v-col>


          </v-row>
        </v-form>
        <v-row>
          <h3 v-if="counter && counter.status">{{ $t('order_counter.status') }}: {{$t('order_counter.statuses.' + counter.status.key)}}</h3>
        </v-row>
        <v-row v-if="counter">
          <v-col cols="4" class="d-flex justify-center">
              <v-icon class="mx-auto" :size="iconSize" color="green" style="cursor:pointer;" :disabled="disablePlayButton || isLoading" @click="confirmStartCounter">
                mdi-play-box
              </v-icon>
          </v-col>

          <v-col cols="4" class="d-flex justify-center">
            <v-icon class="mx-auto" :size="iconSize" color="#9e911c" style="cursor: pointer" :disabled="disablePauseButton || isLoading" @click="confirmPauseCounter">
              mdi-pause-box
            </v-icon>

          </v-col>

          <v-col cols="4" class="d-flex justify-center">
            <v-icon class="mx-auto" :size="iconSize" color="red" style="cursor: pointer" :disabled="disableStopButton || isLoading" @click="confirmEndCounter">
              mdi-stop-circle
            </v-icon>

          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="orderDetailDoesNotHaveCounter">
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-2" :disabled="isLoading" @click="close">
          {{ $t("attachment.close") }}
          <v-icon dark right> mdi-close</v-icon>
        </v-btn>

        <v-btn color="success" :disabled="isLoading" :loading="$store.state.isLoading"  @click="createCounter" v-if="!counter">
          {{ $t("save") }}
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    >

    </confirmation-model>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import {API_BASE_URL} from '@/config';
import moment from "moment";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  name: 'OrderCounterForm',
  emits: ['close', 'input'],
  components: {
    ConfirmationModel,
  },
  props: {
    orderDetail: {
      required: true
    },
    order:{
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    value(val) {
      this.dialog = val;

      if(val === true && this.orderDetailHasCounter){
        this.counter = this.getCounter(this.orderDetail[this.targetColumn])
      }

    },
    // orderDetail(val){
    //   if(!val) return false;
    //
    //   if(this.isset(val[this.targetColumn])){
    //     this.counter = this.getCounter(this.orderDetail[this.targetColumn])
    //   }
    // }
  },
  computed: {
    type(){
      let code = this.orderDetail?.article?.property?.property_50 || null;
      if(!code){
        return null;
      }

      return parseInt(code)
    },
    isLoading(){
      return this.$store.state.progressBarLoading
    },
    orderDetailHasCounter() {
      if (this.counter) return true;

      return this.orderDetail && this.isset(this.orderDetail[this.targetColumn])
    },
    orderDetailDoesNotHaveCounter() {
      return !this.orderDetailHasCounter
    },
    counterHasDetails() {
      return this.counter && this.counter.details && this.counter.details.length > 0;
    },
    counterTypeTime() {
      return this.counterTypes.find(ct => ct.id === this.type)?.key === 'time_counter'
    },
    counterTypeDistance() {
      return this.counterTypes.find(ct => ct.id === this.type)?.key === 'km_counter'
    },
    orderDetailKey() {
      if (!this.orderDetail) return null;

      return {
        order_detail_key: {
          order_id: this.orderDetail?.order_id,
          article_id: this.orderDetail?.article_id,
        }
      }
    },
    formattedUsers(){
      let authUserId = localStorage.getItem('loginUserId');

      let users = JSON.parse(JSON.stringify(this.users))

      return users
          .sort((a) => {
            if(a.id === authUserId){
              return 1
            }

            return -1
          })
      //     .map( user => {
      //   if (parseInt(authUserId) === user.id){
      //     user.name = this.$t('order_counter.you')
      //   }
      //
      //   return user
      // })
    },
    disablePlayButton(){
      if(this.counter === null ) return false;
      return this.counter?.status?.key === 'counter_started'
    },
    disablePauseButton(){
      if(this.counter === null) return false
      return this.counter?.status?.key !== 'counter_started'
    },
    disableStopButton(){
      if(this.counter === null) return false
      return this.counter?.status?.key !== 'counter_started'
    }
  },
  async mounted() {
    this.counterTypes = await this.getCodes('order_counter_types')
    this.counterTypes = this.counterTypes.map(ct => ({...ct, text: this.$t('order_counter.types.' + ct.key)}))
    this.counterStatuses = await this.getCodes('order_counter_statuses')
    this.counterStatuses = this.counterStatuses.map(cs => ({...cs, text: this.$t('order_counter.statuses.' + cs.key)}))
    this.clientVehicles = await this.getCompanyClientVehicles();

    this.users = await this.getCompanyUsers()

  },
  data() {
    return {
      dialog: this.value,
      counter: null,
      users: [],
      counterTypes: [],
      counterStatuses: [],
      clientVehicles: [],
      iconSize: 75,
      counterForm: {
        assigned_user_id: null,
        vehicle_device_id: null,
      },
      counter_starts_now: false,
      axiosHeaders: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken,
        },
      },
      targetColumn: 'order_counter_id',
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: this.$t('no'),
      confirmationDialogTrueText: this.$t('yes'),
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
    }
  },
  methods: {
    clickConfirmationDialog(operation) {

    },
    close() {
      this.$emit('input', false);
    },
    getCounter(counterId) {
      return new Promise((resolve, reject) => {
        return axios.get(API_BASE_URL + '/orders/counters/' + counterId, this.axiosHeaders)
            .then((response) => {
              this.counter = response.data.data
              this.counterForm.assigned_user_id = this.counter.assigned_user_id
              this.counterForm.vehicle_device_id = this.counter.vehicle_device_id
            })
            .catch((error) => {
              reject(error);
            })
      })
    },
   async createCounter() {
      if(!this.counterForm.assigned_user_id){
        return this.$noty.error(this.$t('order_counter.select_user_error'), {
          timeout: 5000,
          layout: 'bottomLeft'
        });
      }

        this.counterForm.order_detail_key = {
          order_id: this.orderDetail.order_id ?? 52154,
          article_id: this.orderDetail.article_id ?? 129517
        };
        this.counterForm.counter_status_id = this.counterStatuses.find(cs => cs.key === 'counter_registered')?.id || null
        if(!this.isset(this.counterForm.counter_status_id)){
          await this.getCodes('order_counter_statuses')
          this.counterForm.counter_status_id = this.counterStatuses.find(cs => cs.key === 'counter_registered')?.id || null
        }
        this.counterForm.counter_type_id = this.type
        this.counterForm.counter_name = this.order.id.toString()

     
        return axios.post(API_BASE_URL + '/orders/counters', this.counterForm, this.axiosHeaders)
            .finally(() => {})
            .then((response) => {
              this.counter = response.data.data
              this.setOrderCounterInStore();
            })
            .catch((error) => {
              console.log(error)
            })
    },
    setOrderCounterInStore(action) {
      this.$store.state.orderCounter = {
        data: this.counter,
        action: action,
        ...this.orderDetailKey
      }
    },
    toggleCounter(action) {
      return new Promise((resolve, reject) => {
        
        return axios.put(API_BASE_URL + '/orders/counters/' + this.counter.id + '/' + action, this.orderDetailKey, this.axiosHeaders)
            .finally(() => {})
            .then((response) => {
              
              this.counter = response.data.data
              this.$noty.success(this.$t('order_counter.success_status.' + action), { timeout: 5000, layout: "bottomLeft" });
              if(['pause', 'stop'].includes(action)){
                this.$emit('order_counter_stopped');
                this.close()
                this.setOrderCounterInStore(action);
              }
            })
            .catch((error) => {
              reject(error);
            })
      })
    },
    confirmationButtonClicked(event) {
      if(!event){
        return
      }

      if (this.confirmationDialogButtonClickedIs === 'startCounter') {
        this.startCounter()
      }
      if (this.confirmationDialogButtonClickedIs === 'pauseCounter') {
        this.pauseCounter()
      }
      if (this.confirmationDialogButtonClickedIs === 'endCounter') {
        this.endCounter()
      }
      this.confirmationDialog = false
    },
    startCounter() {
      return this.toggleCounter('start')
    },
    confirmStartCounter() {
      this.confirmationDialogConfirmationText = this.$t('order_counter.confirm_start_counter')
      this.confirmationDialogButtonClickedIs = 'startCounter'
      this.confirmationDialogOperation = 'start'
      this.confirmationDialog = true
    },
    pauseCounter() {
      return this.toggleCounter('pause')
    },
    confirmPauseCounter() {
      this.confirmationDialogConfirmationText = this.$t('order_counter.confirm_pause_counter')
      this.confirmationDialogButtonClickedIs = 'pauseCounter'
      this.confirmationDialogOperation = 'pause'
      this.confirmationDialog = true
    },
    confirmResumeCounter() {
      this.confirmationDialogConfirmationText = this.$t('order_counter.confirm_resume_counter')
      this.confirmationDialogButtonClickedIs = 'startCounter'
      this.confirmationDialogOperation = 'resume'
      this.confirmationDialog = true
    },
    endCounter() {
      return this.toggleCounter('stop')
    },
    confirmEndCounter() {
      this.confirmationDialogConfirmationText = this.$t('order_counter.confirm_end_counter')
      this.confirmationDialogButtonClickedIs = 'endCounter'
      this.confirmationDialogOperation = 'end'
      this.confirmationDialog = true
    },
    getCompanyUsers() {
      return new Promise((resolve, reject) => {
        return axios.get(API_BASE_URL + '/users', this.axiosHeaders)
            .then((response) => {
              resolve(response.data.data)
            })
            .catch((error) => {
              reject(error);
            })
      })
    },
    getCompanyClientVehicles(){
      return new Promise((resolve, reject) => {
        return axios.get(API_BASE_URL + '/client_vehicles?with_tracking_device_only=1', this.axiosHeaders)
            .then((response) => {
              resolve(response.data.data)
            }).catch((error) => {
              reject(error);
            })
      })
    },
    formatDateTime(time) {
      if (!time) return '';

      return moment(time).format('DD.MM.YYYY HH:mm')
    }
  },
}
</script>