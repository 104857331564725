import Vue from "vue";
import axios from "axios";
import store from "@/store";
import Noty from "noty";
import i18n from "@/i18n";
let config = {};
let requestCount = 0;
let lastErrorMessage = null;
let lastErrorMessageTime = 0;

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
        incrementAxiosCounter();
        setSubmitLoading(config);
        return addTokenToAPI(config);
    },
    function(error) {
        decrementAxiosCounter();
        handleUnauthorizedError(error);
        showErrorMessage(error);
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        decrementAxiosCounter();
        resetSubmitLoading(response, null);
        return response;
    },
    function(error) {
        decrementAxiosCounter();
        resetSubmitLoading(null, error);
        showErrorMessage(error);
        handleUnauthorizedError(error);
        return Promise.reject(error);
    }
);

Plugin.install = function(Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

function handleUnauthorizedError(error){
    // Handle response error
    if (error.response.status == 401) {
        localStorage.clear();
    }
}

function decrementAxiosCounter(){
        // Decrement the request count in case of request error
        requestCount--;

        if (requestCount === 0) {
            store.dispatch("setProgressBarLoading", false);
        }
}
function incrementAxiosCounter(){

        // Increment the request count before sending the request
        requestCount++;

        if (requestCount === 1) {
            // Set loading to true when the first request is made
            store.dispatch("setProgressBarLoading", true);
        }
        
        if (requestCount === 0) {
            store.dispatch("setProgressBarLoading", false);
        }
}
function setSubmitLoading(config){
    // Set loading to true before request is sent
    if (config.method === "post" || config.method === "put" || config.method === "delete") {
        store.dispatch("setTopSubmitLoading", true); // Assuming you have a mutation SET_LOADING in your Vuex store
    }
}
function resetSubmitLoading(response, error){
        // Set loading to false after response is received
        if (response && (response.config.method === "post" || response.config.method === "put" || response.config.method === "delete")) {
            store.dispatch("setTopSubmitLoading", false);
        }

        // Set loading to false in case of error
        if (error && (error.config.method === "post" || error.config.method === "put" || error.config.method === "delete")) {
            store.dispatch("setTopSubmitLoading", false);
        }
        
}
function showErrorMessage(error) {
    // Check if custom option 'showErrorMessage' is false
    if (!(error.config.showErrorMessage === false) && error?.response?.data?.message) {
        const errorMessage = error.response.data.message;
        const currentTime = Date.now();

        // Check if the current error message is the same as the last one and if it has been less than 0.8 seconds
        if (errorMessage === lastErrorMessage && currentTime - lastErrorMessageTime < 800) {
            return; // Do not show the error message
        }

        // Display error using Noty
        if (error.response.status == 401) {
            new Noty({
                type: 'warning',
                text: i18n.t("session_expired"),
                timeout: 5000,
                layout: "bottomLeft"
            }).show();
        }
        else{
            new Noty({
                type: 'error',
                text: 'Server Error: ' + errorMessage,
                timeout: 5000,
                layout: "bottomLeft"
            }).show();    
        }
        // Update last error message and its timestamp
        lastErrorMessage = errorMessage;
        lastErrorMessageTime = currentTime;
    }
}

function addTokenToAPI(config){
    // add   bearer token
    const token = localStorage.getItem("accessToken");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}
export default _axios;
