<template>
    <v-row>
        <v-col>
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="pa-0 pt-4 ma-0" cols="12" sm="3">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        class="mr-3 to-upper"
                        :label="$t('vehicles.search')"
                        @input="debounceInput"
                        prepend-icon="mdi-magnify"
                        v-bind:clearable="true"
                        clear-icon="mdi-close"
                        v-model="search"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                </v-col>
                <v-btn
                    id="top-create"
                    class="ma-8"
                    color="primary"
                    @click="addEditVehicleModel = true"
                >
                    {{ $t("nav_buttons.add") }}
                    <v-icon dark right> mdi-plus </v-icon>
                </v-btn>
            </v-row>
            <v-row>
                <v-data-table
                    :footer-props="{
                        'items-per-page-options': dataTableOptions,
                    }"
                    fixed-header
                    :headers="headers"
                    :options="options"
                    :server-items-length="totalItems"
                    :loading="isLoading"
                    :loading-text="this.$t('loading')"
                    class="elevation-0"
                    :height="'calc(-600px + 100vh)'"
                    style="width: 100%"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :items-per-page="itemsPerPage"
                    item-key="identifier"
                    @update:sort-by="updateSort"
                    @update:sort-desc="updateSortOrder"
                    @update:page="updatePagination"
                    @update:items-per-page="updateItemsPerPage"
                    :items="vehicles"
                    mustSort
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.registration_number }}</td>
                            <td>
                                <span v-if="item.brand">
                                    {{ item.brand.name }}
                                </span>
                            </td>
                            <td>{{ item.model }}</td>
                            <td>{{ item.vin }}</td>
                            <td>
                                <v-icon
                                    v-if="item.is_leasing"
                                    class="mr-1"
                                    color="success"
                                >
                                    mdi-check
                                </v-icon>
                            </td>
                            <td>
                                <span v-if="item.driver">
                                    {{ item.driver.name }}
                                </span>
                            </td>
                            <td>
                                {{ getLocalisationFromDepot(item) }}
                            </td>
                            <td>
                                <v-icon dark color="warning" v-if="item.note">
                                    mdi-information
                                </v-icon>
                            </td>
                            <td>
                                <v-btn
                                    class="ma-2 text-white-color"
                                    color="primary"
                                    small
                                    @click="viewVehicle(item)"

                                >
                                    {{ $t("btn_view") }}
                                    <v-icon dark right> mdi-eye </v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    class="ma-2 text-white-color"
                                    color="primary"
                                    small
                                    @click="editCurrentVehicle(item)"
                                >
                                    {{ $t("edit") }}
                                </v-btn> -->
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-row>
        </v-col>

        <v-dialog
            persistent
            @click:outside="closeVehicleModel()"
            @keydown.esc="closeVehicleModel()"
            v-model="addEditVehicleModel"
            min-width="600"
            max-width="1368"
        >
            <v-card>
                <v-card-title class="text-title">
                    {{
                        selectedVehicleForEdit
                            ? $t("vehicles.edit_title")
                            : $t("vehicles.add_title")
                    }}
                </v-card-title>

                <v-card-text class="dialog-text text-content" color="black">
                    <v-row>
                        <create-edit-vehicle
                            v-if="checkTag('vehicles')"
                            ref="vehicleComp"
                            :comp_vehicle_type.sync="parent_vehicle_type"
                            :comp_registration_no.sync="parent_registration_no"
                            :comp_brand.sync="parent_brand"
                            :comp_model.sync="parent_model"
                            :comp_km.sync="parent_km"
                            :comp_reg_date.sync="parent_reg_date"
                            :comp_creditor.sync="parent_creditor"
                            :comp_creditors.sync="creditors"
                            :comp_vin.sync="parent_vin"
                            :comp_is_leasing.sync="parent_is_leasing"
                        />
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pr-2">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="px-4"
                        color="grey lighten-2"
                        @click="closeVehicleModel()"
                    >
                        {{ $t("addresses.cancel_btn_text") }}
                        <v-icon right>
                            mdi-keyboard-return
                        </v-icon>
                    </v-btn>
                    <v-btn
                        color="success"
                        dark
                        class="px-4"
                        @click="addEditVehicles()"
                    >
                        {{ $t("save") }}
                        <v-icon dark right> mdi-content-save </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import CreateEditVehicle from "@/components/Vehicles/CreateEditVehicle";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
    mixins: [validationMixin],
    props: {
        client: [Object],
    },
    validations: {
        parent_registration_no: {
            required,
        },
    },
    components: {
        "create-edit-vehicle": CreateEditVehicle,
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            vehicles: [],
            alerts: [],
            search: "",
            isLoading: true,
            totalItems: 0,
            page: 1,
            lastPage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            options: {},
            sortBy: "registration_number",
            sortDesc: false,
            sortOrd: "ASC",
            sort: {},
            showAlert: false,
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            autocompleteValueLocal: "off",
            isDebounceCancelled: false,

            // Vehicle Component Sync Values
            parent_vehicle_type: null,
            parent_registration_no: null,
            parent_brand: null,
            parent_model: null,
            parent_km: null,
            parent_reg_date: null,
            parent_creditor: null,
            creditors: [],
            parent_vin: null,
            parent_is_leasing: false,

            addEditVehicleModel: false,
            selectedVehicleForEdit: null,
        };
    },

    created() {
        (this.header = { Authorization: "Bearer " + this.token }),
            this.fetchData();
        this.autocompleteValueLocal = this.autocompleteValue();
    },
    mounted() {
        this.getCreditors();
    },
    methods: {
        viewVehicle (item) {
            this.$store.commit("seteditId",item.id);
            this.$router.push('/vehicles/edit').catch(() => {});
        },
        editCurrentVehicle(item) {
            this.selectedVehicleForEdit = item;
            this.addEditVehicleModel = true;
        },
        addEditVehicles() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.$v.$invalid) {
                this.$noty.warning(this.$t("please_fill_form_correctly"), {
                    timeout: 5000,
                    layout: "bottomLeft",
                });
                return false;
            } else {
                console.log(this.makeNewVehicleData());
                if (this.selectedVehicleForEdit) {
                    this.editVehicle(this.makeNewVehicleData());
                } else {
                    this.createVehicle(this.makeNewVehicleData());
                }
            }
        },
        closeVehicleModel() {
            this.selectedVehicleForEdit = null;
            this.addEditVehicleModel = false;

            this.parent_vehicle_type = null;
            this.parent_registration_no = null;
            this.parent_brand = null;
            this.parent_model = null;
            this.parent_km = null;
            this.parent_reg_date = null;
            this.parent_creditor = null;
            this.parent_vin = null;
            this.parent_is_leasing = null;
        },
        createVehicle(payload) {
            
            axios
                .post(API_BASE_URL + "/client_vehicles", payload, {
                    headers: this.header,
                })
                .then((response) => {
                    this.$noty.success(
                        this.$t(
                            "vehicles.text_messages.vehicle_created_successfully"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.closeVehicleModel();
                    this.fetchData();
                })
                .catch((error) => {
                    if (
                        error.response.data.errors &&
                        error.response.data.errors[0]
                    ) {
                        this.$noty.error(
                            this.$t(
                                "vehicles.text_messages." +
                                    error.response.data.errors[0]
                            ),
                            { timeout: 5000, layout: "bottomLeft" }
                        );
                    } else {
                        this.$noty.error(error, {
                            timeout: 5000,
                            layout: "bottomLeft",
                        });
                    }
                })
                .finally(() => {
                    
                });
        },
        editVehicle(payload) {
            
            axios
                .put(
                    API_BASE_URL +
                        "/client_vehicles/" +
                        this.selectedVehicleForEdit.id,
                    payload,
                    { headers: this.header }
                )
                .then((response) => {
                    this.$noty.success(
                        this.$t(
                            "vehicles.text_messages.vehicle_updated_successfully"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                    this.closeVehicleModel();
                    this.fetchData();
                })
                .catch((error) => {
                    this.$noty.error(error, {
                        timeout: 5000,
                        layout: "bottomLeft",
                    });
                })
                .finally(() => {
                    
                });
        },
        makeNewVehicleData() {
            console.log("vehicle data", this.parent_registration_no);
            return {
                vehicle_type_id:
                    this.parent_vehicle_type &&
                    typeof this.parent_vehicle_type == "object"
                        ? this.parent_vehicle_type.id
                        : null,
                registration_number:
                    this.parent_registration_no &&
                    typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.registration_number
                        : this.parent_registration_no,
                brand_id: this.parent_brand
                    ? typeof this.parent_brand == "number"
                        ? this.parent_brand
                        : typeof this.parent_brand == "object"
                        ? this.parent_brand.id
                        : null
                    : null,
                model: this.parent_model ?? null,
                registration_date: this.parent_reg_date ?? null,
                vin: this.parent_vin ?? null,
                client_id: this.client.id,
                driver_id: this.driver_name ? this.driver_name.id : null,
                is_leasing: this.parent_is_leasing ? 1 : 0,
                note: this.note,
                transmission_id: this.transmission
                    ? typeof this.transmission == "object"
                        ? this.transmission.id
                        : this.transmission
                    : null,
                fuel_id: this.fuel
                    ? typeof this.fuel == "object"
                        ? this.fuel.id
                        : this.fuel
                    : null,
                gearbox_id: this.gearbox
                    ? typeof this.gearbox == "object"
                        ? this.gearbox.id
                        : this.gearbox
                    : null,
                km: this.parent_km ?? null,
                power: this.power,
                contract_number: this.contract_number,
                contract_end_date: this.contract_end_date,
                creditor_id: this.parent_creditor
                    ? typeof this.parent_creditor == "object"
                        ? this.parent_creditor.id
                        : this.parent_creditor
                    : null,
                // cylinder_capacity: this.cylinder_capacity ?? null,
                // is_out_of_circulation: this.is_out_of_circulation ?? null,
            };
        },
        getCreditors() {
            axios
                .get(API_BASE_URL + "/creditors", { headers: this.header })
                .then(({ data }) => {
                    this.creditors = data.data.map((element) => {
                        if (element.creditor_company) {
                            element.creditor_company["is_leaser"] =
                                element.is_leaser;
                            return element.creditor_company;
                        }
                    });
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                });
        },
        debounceInput: _.debounce(function(e) {
            if (this.isDebounceCancelled) {
                this.isDebounceCancelled = false;
                return false;
            }
            this.fetchData();
        }, 500),
        getLocalisationFromDepot(item) {
            let localisation = "";
            let activeDepot = 0;
            if (item.depot) {
                item.depot.forEach((element) => {
                    if (
                        element.status_id == 10171001 &&
                        element.deleted_at == null
                    ) {
                        // depot stored
                        activeDepot++;
                        localisation += element.localisation
                            ? " " + element.localisation + ","
                            : "";
                    }
                });
                if (activeDepot > 0 && !localisation.trim()) {
                    return this.$t("yes");
                } else if (activeDepot > 0 && localisation.trim()) {
                    if(localisation && localisation.length > 20){
                        return localisation.slice(0, 20) + "..";
                    }
                    return localisation;
                } else {
                    return "";
                }
            }
        },
        fetchData() {
            this.isLoading = true;
            axios
                .get(
                    API_BASE_URL +
                        "/client_vehicles" +
                        "?page=" +
                        (this.page ?? 1) +
                        "&items_per_page=" +
                        this.itemsPerPage +
                        (this.client ? "&client_id=" + this.client.id : "") +
                        (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
                        "&order_by=" +
                        (this.sortBy ? this.sortBy : "registration_number") +
                        "&order_direction=" +
                        this.sortOrd ?? "",
                    { headers: this.header }
                )
                .then((data) => {
                    this.vehicles = data.data.data;
                    this.vehicles.forEach((vehicle) => {
                        vehicle.is_leasing =
                            vehicle.is_leasing == 1 ? true : false;
                    });
                    this.totalItems = data.total;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error.response.data.error);
                    this.$noty.success(
                        this.$t(
                            "schedulestab.something_went_wrong_while_fetching_data"
                        ),
                        { timeout: 5000, layout: "bottomLeft" }
                    );
                });
        },
        updatePagination(page) {
            this.page = page;
            this.fetchData();
        },
        updateItemsPerPage(itemsPerPage) {
            console.log(itemsPerPage);
            itemsPerPage == -1
                ? (this.itemsPerPage = this.totalItems)
                : (this.itemsPerPage = itemsPerPage);
            this.fetchData();
        },
        updateSort(sortBy) {
            if (typeof sortBy == "object") {
                this.sortBy = sortBy[0];
            } else {
                this.sortBy = sortBy;
            }
            this.fetchData();
        },
        updateSortOrder(sortDesc) {
            console.log("data => ", sortDesc);
            if (typeof sortDesc == "object") {
                !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            } else {
                !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            }
            console.log("data => ", this.sortOrd);
            this.fetchData();
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t("vehicles.registration_number"),
                    value: "registration_number",
                    sortable: true,
                },
                // { text: this.$t('vehicles.client_name'), value: 'client_name', sortable: true },
                {
                    text: this.$t("vehicles.brand_name"),
                    value: "brand_name",
                    sortable: false,
                },
                {
                    text: this.$t("vehicles.model"),
                    value: "model",
                    sortable: true,
                },
                { text: this.$t("vehicles.vin"), value: "vin", sortable: true },
                {
                    text: this.$t("vehicles.is_leasing"),
                    value: "is_leasing",
                    sortable: true,
                },
                {
                    text: this.$t("vehicles.driver_name"),
                    value: "driver_name",
                    sortable: false,
                },
                { text: this.$t("vehicles.hotel"), sortable: false },
                {
                    text: this.$t("vehicles.note"),
                    value: "note",
                    sortable: true,
                },
                {
                    text: this.$t("vehicles.actions"),
                    value: "id",
                    width: 20,
                    sortable: false,
                },
            ];
        },
    },
    watch: {
        selectedVehicleForEdit(val) {
            if (val) {
                this.$nextTick(() => {
                    this.parent_registration_no = val;
                    this.parent_registration_no.vehicle_type = val.vehicle_type;
                });
                this.parent_brand = val.brand;
                this.parent_model = val.model;
                this.parent_km = val.km;
                this.parent_reg_date = val.registration_date;
                this.parent_creditor = val.creditor;
                this.parent_vin = val.vin;
                this.parent_is_leasing = val.is_leasing;
            } else {
                this.parent_vehicle_type = null;
                this.parent_registration_no = null;
                this.parent_brand = null;
                this.parent_model = null;
                this.parent_km = null;
                this.parent_reg_date = null;
                this.parent_creditor = null;
                this.parent_vin = null;
                this.parent_is_leasing = null;
            }
        },
    },
};
</script>
<style scoped></style>
