<template>
  <v-card flat>
    <confirmation-model
      :showDialog.sync="confirmationDialog"
      :openedForOperation.sync="confirmationDialogOperation"
      :text.sync="confirmationDialogConfirmationText"
      :trueText.sync="confirmationDialogTrueText"
      :falseText.sync="confirmationDialogFalseText"
      @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-container fluid>
      <v-row>
        <!-- Registration Number -->
        <v-col cols="12" sm="3">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="registrationNo"
            class="to-upper"
            v-on:keydown="handleInput($event, 'alphanumdashdot', 15)"
            :label="$t('vehicles.registration_number')"
            required
            :error-messages="
              $store.state.validation && !$v.registrationNo.required
                ? [
                    this.$t(
                      'vehicles.text_messages.registration_number_required'
                    ),
                  ]
                : []
            "
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <!-- Brand -->
        <v-col cols="12" sm="3">
          <v-select
            :autocomplete="autocompleteValueLocal"
            v-model="selectedBrand"
            name="brand"
            :items="brands"
            item-text="name"
            class="to-upper"
            item-value="id"
            :label="$t('vehicles.brand')"
            append-icon="mdi-close"
            @click:append="selectedBrand = null"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-select>
        </v-col>
        <!-- Model -->
        <v-col cols="12" sm="2">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="model"
            name="model"
            :label="$t('vehicles.model')"
            v-on:keydown="handleInput($event, 'alphanumeric', 80)"
            class="to-upper"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <!-- Cyclinder Capacity -->
        <v-col cols="12" sm="2">
          <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="cylinder_capacity"
              class="to-upper"
              v-on:keydown="handleInput($event, 'int', 11)"
              :label="$t('vehicles.cylinder_capacity')"
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <!-- Power -->
        <v-col cols="12" sm="2">
          <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="power"
              class="to-upper"
              v-on:keydown="handleInput($event, 'alphanumeric', 12)"
              :label="$t('vehicles.power')"
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-text-field>
        </v-col>

        <!-- KM -->
        <v-col cols="12" sm="2">
          <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="km"
              class="to-upper"
              v-on:keydown="handleInput($event, 'alphanumeric', 12)"
              :label="$t('vehicles.km')"
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <!-- VIN -->
        <v-col cols="12" sm="2">
          <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="vin"
              class="to-upper"
              name="vin"
              v-on:keydown="handleInput($event, 'alphanumeric', 255)"
              :label="$t('vehicles.vin')"
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <!-- Fuel -->
        <v-col cols="12" sm="2">
          <v-select
              :autocomplete="autocompleteValueLocal"
              v-model="fuel"
              :items="fuels"
              item-text="text"
              class="to-upper"
              item-value="id"
              :label="$t('vehicles.fuel')"
              append-icon="mdi-close"
              @click:append="fuel = null"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
          ></v-select>
        </v-col>
        <!-- Vehicle Type -->
        <v-col cols="12" sm="2">
          <v-combobox
              :autocomplete="autocompleteValueLocal"
              v-model="vehicleType"
              :items="vehicleTypes"
              item-text="text"
              item-value="id"
              class="to-upper"
              :label="$t('billings.vehicle_type')"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-details
              return-object
          ></v-combobox>
        </v-col>
        <!-- Transmission -->
        <v-col cols="12" sm="2">
          <v-select
              :autocomplete="autocompleteValueLocal"
              v-model="transmission"
              :items="transmissions"
              item-text="text"
              class="to-upper"
              item-value="id"
              :label="$t('vehicles.transmission')"
              append-icon="mdi-close"
              @click:append="transmission = null"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
          ></v-select>
        </v-col>
        <!-- GearBox -->
        <v-col cols="12" sm="2">
          <v-select
              :autocomplete="autocompleteValueLocal"
              v-model="gearbox"
              :items="gearboxes"
              item-text="text"
              class="to-upper"
              item-value="id"
              :label="$t('vehicles.gearbox')"
              append-icon="mdi-close"
              @click:append="gearbox = null"
              return-object
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
          ></v-select>
        </v-col>


        <!-- Registration Date -->
        <v-col cols="12" sm="3">
          <v-menu
            v-model="menuDtp"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="Regdate"
                :label="$t('vehicles.registration_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:append="Regdate = null"
                append-icon="mdi-close"
                class="mr-2 to-upper"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              :weekday-format="getDay"
              v-model="Regdate"
              @input="menuDtp = false"
              no-title
              scrollable
              :locale="userLanguageId"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- Creditor -->
        <v-col cols="12" sm="3">
          <v-autocomplete
              :autocomplete="autocompleteValueLocal"
              v-model="creditor"
              :items="creditors"
              item-text="creditor_company.name"
              item-value="creditor_id"
              :label="$t('vehicles.creditor')"
              v-on:keydown.enter.prevent=""
              @change="updateIsLeasing()"
              clearable
              :outlined="is_boxes"
              hide-details="auto"
              :dense="is_boxes"
              class="to-upper"
              hide-no-data
          >
            <template v-slot:prepend-inner>
              <div v-if="creditor && is_leasing">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="black"> mdi-car-multiple </v-icon>
                  </template>
                  <span> {{ $t('billings.leasing') }} </span>
                </v-tooltip>
              </div>
              <div v-if="creditor && ! is_leasing">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="black"> mdi-account-cash-outline </v-icon>
                  </template>
                  <span> {{ $t('billings.not_leasing') }} </span>
                </v-tooltip>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <!-- Contract Number -->
        <v-col cols="12" sm="2">
          <v-text-field
              :autocomplete="autocompleteValueLocal"
              v-model="contract_number"
              class="to-upper"
              v-on:keydown="handleInput($event, 'alphanumeric', 80)"
              :label="$t('vehicles.contract_number')"
              :outlined="is_boxes"
              :dense="is_boxes"
          ></v-text-field>
        </v-col>
        <!-- Contract End Date -->
        <v-col cols="12" sm="2">
          <v-menu
              v-model="menu_contract_end_date"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="contract_end_date"
                  :label="$t('vehicles.contract_end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append="contract_end_date = null"
                  append-icon="mdi-close"
                  class="mr-2 to-upper"
                  :outlined="is_boxes"
                  :dense="is_boxes"
              ></v-text-field>
            </template>
            <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="contract_end_date"
                @input="menu_contract_end_date = false"
                no-title
                scrollable
                :locale="userLanguageId"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- Is Out Of Circulations -->
        <v-col cols="12" sm="2">
          <v-checkbox
              class="mt-6"
              v-model="is_out_of_circulation"
              :label="$t('vehicles.is_out_of_circulation')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <!-- Client Name -->
        <v-col cols="12" sm="12" md="12" lg="6" class="d-flex justify-content-between align-center">
          <ClientDriverInputBox
          :data.sync="company_client_name"
          :member="null"
          :type="'client'"
          :showMembers="true"
          :membersEnabled="false"
          :showPopUpToAdd="false"
          ></ClientDriverInputBox>
        </v-col>

        <!-- Driver -->
        <v-col cols="12" sm="12" md="12" lg="6" class="d-flex justify-content-between align-center">
          <ClientDriverInputBox
          :data.sync="driver"
          :member.sync="second_driver"
          :type="'driver'"
          :showMembers="true"
          :membersEnabled="true"
          :showPopUpToAdd="false"
          ></ClientDriverInputBox>
        </v-col>
      </v-row>

      <v-row class="mt-0">

        <!-- Is Leasing -->
        <!-- <v-col cols="12" sm="1">
          <v-checkbox
            class="mt-6"
            v-model="is_leasing"
            :label="$t('vehicles.is_leasing')"
          ></v-checkbox>
        </v-col> -->

      </v-row>
      <v-row class="mt-0">
        <!-- Note -->
        <v-col cols="12" sm="12">
          <h4 class="mb-3" style="color:#9B9B9B; font-weight:normal">
            {{ $t("vehicles.note") }}
          </h4>
          <wysiwyg v-model="note" />
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="onSubmit()" id="submit-vehicle" class="d-none"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-vehicle"></v-btn>
    <v-btn
      @click="showAttachmentDialog()"
      class="d-none"
      id="add-attachment"
    ></v-btn>
    <vehicles-page-tab v-if="vehicleId" :vehicle="vehicleData" />
    <attachment-dialog
      :showDialog.sync="attachmentDialog"
      :data="vehicleId"
      :countKey="'vehicles'"
      :resource="'vehicles'"
    ></attachment-dialog>
    <span id="launch-interventions" @click="openHistoryTableDialog"></span>
    <history-table v-model="historyTableDialog" :model_id="historyTableModelId" :model="historyTableModel" />
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { validationMixin } from "vuelidate";
import {
  required,
  numeric,
  email,
  requiredIf,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[a-zA-Z0-9 ]*$/);
const phoneNum = helpers.regex(
  "alpha",
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);
import { API_BASE_URL } from "@/config";
import { ADD_MODE } from "@/config";
import { DETAILS_MODE } from "@/config";
import { FAMILY_ARTICLE_CARS } from "@/config";
import VehiclesPageTab from "@/components/Vehicles/VehiclesPageTab";
import ConfirmationModel from "@/components/ConfirmationModel";
import AttachmentDialog from "@/components/AttachmentDialog";
import ClientDriverInputBox from "../../components/ClientDriverInputBox.vue";
import VehicleHistory from "@/components/Vehicles/VehicleHistory.vue";
import { mdiCheck } from "@mdi/js";
import historyTableMixin from "@/mixins/historyTableMixin";
import HistoryTable from "@/components/UserActionHistory/HistoryTable.vue";
export default {
  name: "EditVehicle",
  mixins: [validationMixin, historyTableMixin],
  components: {
    HistoryTable,
    "vehicles-page-tab": VehiclesPageTab,
    "confirmation-model": ConfirmationModel,
    "attachment-dialog": AttachmentDialog,
    ClientDriverInputBox,
  },
  validations: {
    registrationNo: {
      required,
    },
    company_client_name: {
      required,
    },
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      registrationNo: "",
      model: "",
      selectedBrand: {},
      brands: [],
      vin: "",
      is_leasing: false,
      note: "",
      isEdit: false,
      vehicleId: this.$store.state.editId,
      Regdate: new Date().toISOString().substr(0, 10),
      menuDtp: false,
      selectedClients: "",
      company_client_names: [],
      client: null,
      company_client_name: null,
      driver: [],
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      nowDate: new Date().toISOString().slice(0, 10),
      fuels: [],
      fuel: null,
      transmissions: [],
      transmission: null,
      gearboxes: [],
      gearbox: null,
      power: null,
      km: null,
      contract_end_date: new Date().toISOString().slice(0, 10),
      contract_number: null,
      menu_contract_end_date: false,
      creditor: null,
      creditors: [],
      is_out_of_circulation: false,
      cylinder_capacity: null,
      vehicleData: null,
      attachmentDialog: false,
      second_driver: null,
      vehicleTypes: [],
      vehicleType: null,
    };
  },
 async created() {
    this.header = { Authorization: "Bearer " + this.token };
    this.getFuels();
    this.getTransmission();
    this.getGearBox();
    this.getCreditors();
    await this.getVehicleTypes()
    this.$route.path == "/vehicles/edit"
      ? (this.isEdit = true)
      : (this.isEdit = false);
    if (this.isEdit) {
      this.$store.commit("setFormMode", DETAILS_MODE);
      this.fetchData();
    } else {
      this.$store.commit("setFormMode", ADD_MODE);
    }
    this.getBrands();
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  methods: {
    getVehicleTypes() {
        this.getCodes('vehicle_types', 'vehicles.types')
            .then((data) => {
              this.vehicleTypes = data
            });
    },
    updateIsLeasing(){
      this.creditors.find((element) => {
        console.log("creditors", element, this.creditor, element.creditor_id);
          if(this.creditor && element.creditor_id == this.creditor){
            this.is_leasing = !!element.is_leaser;
          }
      })
    },
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    getCreditors() {
      
      axios
        .get(API_BASE_URL + "/creditors", { headers: this.header })
        .then(({ data }) => {
          this.creditors = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
        "vehicles.DeleteConfirmation"
      );
      this.confirmationDialogFalseText = this.$t("vehicles.Cancel");
      this.confirmationDialogTrueText = this.$t("vehicles.Delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
    onDelete() {
      console.log("delete clicked");
      this.deletePopUp();
    },
    getBrands() {
      if (FAMILY_ARTICLE_CARS) {
        if (
          this.cachedData["brands"][FAMILY_ARTICLE_CARS] &&
          this.cachedData["brands"][FAMILY_ARTICLE_CARS].length > 0
        ) {
          this.brands = this.cachedData["brands"][FAMILY_ARTICLE_CARS];
        } else {
          
          axios
            .get(
              API_BASE_URL +
                "/brands" +
                "?family=" +
                FAMILY_ARTICLE_CARS +
                "&data_paginated=0",
              { headers: this.header }
            )
            .then(({ data }) => {
              this.brands = data.data;
              this.cachedData["brands"][FAMILY_ARTICLE_CARS] = this.brands;
            })
            .catch(function(error) {})
            .finally(() => {
              
            });
        }
      }
    },
    getFuels() {
      this.getCodes('vehicle_fuel', 'vehicles.fuels')
                .then((data) => {
                  this.fuels = data;
                });
    },
    getTransmission() {
      this.getCodes('vehicle_transmission', 'vehicles.transmissions')
                .then((data) => {
                  this.transmissions = data;
                });
    },
    getGearBox() {
      this.getCodes('vehicle_ger_box', 'vehicles.gearboxes')
                .then((data) => {
                  this.gearboxes = data;
                });
    },
    fetchClientsListDebounced() {
      if (!this.search_client || typeof this.search_client == "object") {
        return;
      }
      
      axios
        .get(
          API_BASE_URL +
            "/clients/search?" +
            (this.search_client ? "&search=" + encodeURIComponent(this.search_client) : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.company_client_names = data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    createVehicle(payload) {
      
      axios
        .post(API_BASE_URL + "/client_vehicles", payload, {
          headers: this.header,
        })
        .then((response) => {
          this.$noty.success(
            this.$t("vehicles.text_messages.vehicle_created_successfully"),
            { timeout: 5000, layout: "bottomLeft" }
          );
          this.$router.push("/vehicles").catch(() => {});
        })
        .catch((error) => {
          if (error.response.data.errors && error.response.data.errors[0]) {
            this.$noty.error(
              this.$t(
                "vehicles.text_messages." + error.response.data.errors[0]
              ),
              { timeout: 5000, layout: "bottomLeft" }
            );
          } else {
            this.$noty.error(error, { timeout: 5000, layout: "bottomLeft" });
          }
        })
        .finally(() => {
          
        });
    },
    editVehicle(payload) {
      
      axios
        .put(API_BASE_URL + "/client_vehicles/" + this.vehicleId, payload, {
          headers: this.header,
        })
        .then((response) => {
          this.$noty.success(
            this.$t("vehicles.text_messages.vehicle_updated_successfully"),
            { timeout: 5000, layout: "bottomLeft" }
          );
          this.$router.push("/vehicles").catch(() => {});
        })
        .catch((error) => {
          this.$noty.error(error, { timeout: 5000, layout: "bottomLeft" });
        })
        .finally(() => {
          
        });
    },
    onSubmit() {
      this.$store.commit("setValidation", true); // enable validation
      console.log("vehicle", this.makeSubmitData());
      this.company_client_name = typeof this.company_client_name == "object" ? this.company_client_name : null; // reset company client name if client is not choosen
      this.driver = typeof this.driver == "object" ? this.driver : null; // reset company client name if client is not choosen
      if (this.$v.$invalid) {
        this.$noty.warning(this.$t("please_fill_form_correctly"), {
          timeout: 5000,
          layout: "bottomLeft",
        });
      } else {
        if (this.isEdit) {
          this.editVehicle(this.makeSubmitData());
        } else {
          this.createVehicle(this.makeSubmitData());
        }
      }
    },
    makeSubmitData() {
      console.log("creditor id", typeof this.creditor == "object");
      return {
        registration_number: this.registrationNo,
        brand_id: this.selectedBrand ? this.selectedBrand.id : null,
        model: this.model,
        registration_date: this.Regdate,
        vin: this.vin,
        client_id: this.company_client_name ? (typeof this.company_client_name == 'object' ? this.company_client_name.id : null) : null,
        driver: this.driver ? (typeof this.driver == 'object' ? this.driver.id : null) : null,
        second_driver: this.second_driver ? (typeof this.second_driver == 'object' ? this.second_driver.id : null) : null,
        is_leasing: this.is_leasing ? 1 : 0,
        note: this.note,
        transmission_id: this.transmission ? (typeof this.transmission == "object" ? this.transmission.id : this.transmission) : null,
        fuel_id: this.fuel ? (typeof this.fuel == "object" ? this.fuel.id : this.fuel) : null,
        gearbox_id: this.gearbox ? (typeof this.gearbox == "object" ? this.gearbox.id : this.gearbox) : null,
        km: this.km,
        power: this.power,
        contract_number: this.contract_number,
        contract_end_date: this.contract_end_date,
        creditor_id: this.creditor ? (typeof this.creditor == "object" ? this.creditor.creditor_id : this.creditor) : null,
        cylinder_capacity: this.cylinder_capacity ?? null,
        is_out_of_circulation: this.is_out_of_circulation ?? null,
        vehicle_type_id: this.vehicleType?.id ?? null,
      };
    },
    fetchData() {
      
      axios
        .get(API_BASE_URL + "/client_vehicles/" + this.vehicleId, {
          headers: this.header,
        })
        .then((res) => {
          this.vehicleData = res.data;
          this.setHistoryModalData('ClientVehicle', res.data.id)
          this.id = res.data.id;
          this.registrationNo = res.data.registration_number;
          this.selectedBrand = res.data.brand;
          this.model = res.data.model;
          this.Regdate = res.data.registration_date;
          this.vin = res.data.vin;
          this.company_client_name = res.data.client;
          this.driver = res.data.driver;
          this.second_driver = res.data.second_driver;
          this.is_leasing = res.data.is_leasing;
          this.note = res.data.note;
          this.km = res.data.km;
          this.power = res.data.power;
          this.contract_number = res.data.contract_number;
          this.contract_end_date = res.data.contract_end_date;
          this.transmission = res.data.transmission;
          this.fuel = res.data.fuel;
          this.gearbox = res.data.gearbox;
          this.creditor = res.data.creditor_id;
          this.cylinder_capacity = res.data.cylinder_capacity;
          this.is_out_of_circulation = res.data.is_out_of_circulation;
          this.vehicleType = this.vehicleTypes.find( i => i.id === res.data.vehicle_type_id)
        })
        .catch(() => {});
    },
    deleteVehicle() {
      
      axios
        .delete(API_BASE_URL + "/client_vehicles/" + this.vehicleId, {
          headers: this.header,
        })
        .then((data) => {
          this.$noty.success(
            this.$t("vehicles.text_messages.deleted_successfully"),
            { timeout: 5000, layout: "bottomLeft" }
          );
          this.$router.push("/vehicles").catch(() => {});
        })
        .catch((error) => {
          this.$noty.error(error, { timeout: 5000, layout: "bottomLeft" });
        })
        .finally(() => {
          
        });
    },
  },
  watch: {
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
          this.confirmationDialogOperation == "delete" &&
          this.confirmationDialogButtonClickedIs == true
        ) {
          this.deleteVehicle();
        }
        this.confirmationDialogButtonClickedIs = null;
      }
    },
  },
  computed: {},
};
</script>
<style scoped></style>
