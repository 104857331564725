<template>
  <v-app id="inspire" :class="this.$route.path == '/' ? 'smooth-bg-color' : ''">
    <PDFViewer v-if="$store.state.previewUrl && $store.state.isShowFullScreen"></PDFViewer>

    <Navigation v-if="isAuth"></Navigation>

    <v-app-bar app absolute v-if="isAuth">
      <v-toolbar-title class="text-blue-color pl-0" fixed>
        {{ pageTitle }}
      </v-toolbar-title>

            <v-spacer></v-spacer>
            <div id="top-action-buttons">
              <template v-if="$route.name === 'OrderReminderSchemaListing'">
                <span class="ml-4" @click="getElementById('new-schema').click()"><AddButton /></span>
              </template>
              <template v-if="$route.name.includes('SupplierConnection')">
                <span class="ml-4" @click="getElementById('save').click()"><SaveButton/></span>
                <span v-if="$route.name === 'CompanySupplierConnectionsAdd'" class="ml-4" @click="$router.push({name: 'CompanySupplierConnections'})"><CloseButton/></span>
                <span v-if="$route.name === 'CompanySupplierConnections'" @click="$router.push({name: 'CompanySupplierConnectionsAdd'})"><AddButton/></span>
              </template>
              <template v-if="$route.name === 'EmployeeWorkingShiftListing'">

                <v-btn
                    :disabled="isTopSubmitLoading"
                    id="top-make-purchase-order"
                    class="text-white-color mr-4 mt-0"
                    color="primary"
                    @click="getElementById('duplicate').click()"
                >
                  {{ $t("user_rights_section.duplicate") }}
                  <v-icon dark right> mdi-content-duplicate </v-icon>
                </v-btn>

                <v-btn
                    :disabled="isTopSubmitLoading"
                    id="top-make-purchase-order"
                    class="text-white-color mr-4 mt-0"
                    color="success"
                    @click="getElementById('print').click()"
                >
                  {{ $t("nav_buttons.print") }}
                  <v-icon dark right> mdi-printer </v-icon>
                </v-btn>
              </template>
              <template v-if="$route.name === 'WorkingShiftListing'">
                <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary" @click="getElementById('working-shift-form').click()">
                  {{ $t("nav_buttons.add") }}
                  <v-icon dark right> mdi-plus </v-icon>
                </v-btn>
              </template>
              <template v-if="['EditExpedition'].includes($route.name)">
                <span style="order: 25"><InterventionsButton :disabled="isTopSubmitLoading" /></span>
              </template>
                <template v-if="$route.name === 'TyreHotelBulkOperationDetails'">
                    <v-btn :disabled="isTopSubmitLoading" id="bulk-save-button" class="ma-2 text-white-color" color="success" @click="getElementById('bulk-save').click()">
                        {{ $t("nav_buttons.save") }}
                        <v-icon dark right> mdi-content-save </v-icon>
                    </v-btn>

          <v-btn :disabled="isTopSubmitLoading" class="ma-2" id="top-create" color="success"
                 @click="getElementById('bulk-print').click()">
            {{ $t('print') }}
            <v-icon dark right> mdi-printer</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" class="ma-2" id="top-create" color="success"
                 @click="getElementById('bulk-print-labels').click()">
            {{ $t('tyre_hotel.print_label') }}
            <v-icon dark right> mdi-printer</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" id="top-save" class="ma-2 text-white-color" color="primary"
                 @click="getElementById('bulk-email').click()">
            {{ $t("nav_buttons.email") }}
            <v-icon dark right> mdi-email</v-icon>
          </v-btn>
        </template>
        <template v-if="$route.name === 'Triggers'">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary"
                 @click="addNewTriggerHandler()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <template v-if="$route.name === 'TyreHotel'">

          <v-btn :disabled="isTopSubmitLoading" class="ma-2" color="success" @click="openPrintLabelDialog">
            {{ $t('print_labels') }}
            <v-icon dark right> mdi-printer</v-icon>
          </v-btn>

        </template>

        <template v-if="$route.name === 'TyreHotelBulkOperation'">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary"
                 @click="getElementById('add-new').click()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <template v-if="$route.name === 'EmployeeClockingListing'">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary"
                 @click="getElementById('add-clocking').click()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" class="ma-2" id="top-create" color="success"
                 @click="getElementById('print-clocking').click()">
            {{ $t('print') }}
            <v-icon dark right> mdi-printer</v-icon>
          </v-btn>
        </template>

        <template v-if="$route.name === 'EmploymentContractsListing'">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary"
                 @click="getElementById('add-new').click()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <template v-if="$route.name === 'EmployeeListing'">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary"
                 @click="getElementById('add-new').click()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <template v-if="$route.name === 'EmployeeContractListing'">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary"
                 @click="getElementById('add-new').click()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <template v-if="$route.name === 'UserRights'">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary" @click="addNewRoleDialog">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <!-- EDIT Mode -->
        <template v-if="$store.state.inTyreDepotMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-load-localisation" class="ma-2" color="success"
                 @click="loadLocalisation()">
            {{ $t("nav_buttons.load_localisation") }}
            <v-icon dark right> mdi-arrow-up-box</v-icon>
          </v-btn>
        </template>

        <!-- Update Mode -->
        <template v-if="$store.state.inUpdateMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-save" class="ma-2 text-white-color" color="success"
                 @click="submitMainForm($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </template>

        <!-- List Mode -->
        <template v-if="$store.state.inViewMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary" @click="onAddClick()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <!-- List Mode -->
        <template v-if="$route.path.includes('rentals/history')">
          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" class="ma-2 text-black-color" color="grey lighten-2"
                 @click="$router.push('/rentals')">
            {{ $t("rentals.rental_list") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>
        </template>
        <!-- DETAIL Mode or JUST CANCEL Mode-->
        <template v-if="$store.state.inDetailsMode || $store.state.inJustCancelMode">
          <v-btn class="ma-2 px-4" color="primary" style="order: 20" @click="getElementById('launch-interventions').click()">
            {{ $t('tyre_hotel.interventions') }}
            <v-icon class="ml-2" style="color:white">mdi-clipboard-text-clock</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-print" class="ma-2 text-white-color"
                 v-if="this.$route.path.includes('/articles/edit')"
                 color="primary" @click="printReport()">
            {{ $t("nav_buttons.print_article_label") }}
            <v-icon dark right> mdi-printer</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" v-if="!$store.state.inJustCancelMode" id="top-delete"
                 class="ma-2 text-white-color" color="error"
                 @click="onDelete($event)">
            {{ $t("nav_buttons.delete") }}
            <v-icon dark right> mdi-delete</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" class="ma-2 text-black-color" color="grey lighten-2"
                 @click="$router.go(-1)">
            {{ $t("nav_buttons.cancel") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" v-if="!$store.state.inJustCancelMode" id="top-save"
                 class="ma-2 text-white-color" color="success"
                 @click="submitMainForm($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </template>

        <!-- EDIT Mode -->
        <template v-if="$store.state.inEditMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" class="ma-2 text-black-color" color="grey lighten-2"
                 @click="$router.go(-1)">
            {{ $t("nav_buttons.cancel") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-save" class="ma-2 text-white-color" color="success"
                 @click="submitMainForm($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </template>

        <!-- ADD Mode -->
        <template v-if="$store.state.inAddMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" class="ma-2 text-black-color" color="grey lighten-2"
                 @click="$router.go(-1)">
            {{ $t("nav_buttons.cancel") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2 text-white-color" color="success"
                 @click="submitMainForm($event)">
            {{ $t("nav_buttons.create") }}
            <v-icon dark right> mdi-plus-thick</v-icon>
          </v-btn>
        </template>

        <!-- RECEPTION CREATE Mode -->
        <template v-if="$store.state.inReceptionCreateMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" class="ma-2 text-black-color" color="grey lighten-2"
                 @click="$router.go(-1)">
            {{ $t("nav_buttons.cancel") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" id="top-save" class="ma-2 text-white-color" color="success"
                 @click="createReception($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </template>

        <!-- STOCK LISTING Mode -->
        <template v-if="$store.state.inStockListingMode">
          <PricingCalculator id="top-calculator" class="ma-2"
                             v-if="checkTag('tyre_calculator') && showCalculatorDialog && PricingCalculatorIsActive"
                             @closeClicked="closeCalculator"/>
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2 text-white-color" color="primary"
                 @click="addArticleToStock($event)">
            {{ $t("nav_buttons.add_article_to_stock") }}
            <v-icon dark right> mdi-plus-thick</v-icon>
          </v-btn>
          <v-btn id="top-articles-in-cart" class="ma-2 text-white-color" color="green"
                 @click="showCartPopUpForStocks()"
                 v-if="selectedArticlePendingAppointment && selectedArticlePendingAppointment.length > 0">
            <v-icon dark right class="mr-1"> mdi-invoice-list</v-icon> ({{ selectedArticlePendingAppointment.length }})
          </v-btn>
        </template>

        <!-- Scheduler Listing Mode -->
        <template v-if="$store.state.inSchedulerListingMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary" @click="onAddClick()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" id="top-print" class="ma-2" color="primary" @click="printReport()"
                 v-if="tyreDepotIsActive">
            {{ $t("nav_buttons.print_depot_report") }}
            <v-icon dark right> mdi-printer</v-icon>
          </v-btn>
        </template>

        <template v-if="
                    ($route.path.includes('/billing')||
                    $route.path.includes('/stocks') ||
                    $route.path.includes('/scheduler'))
                    && 
                    ( ! ($route.path.includes('add') || $route.path.includes('edit')))
                    
                ">
          <v-btn :disabled="isTopSubmitLoading" id="top-print" class="ma-2" color="primary"
                 @click="openCalculatorInNewWindow()" v-if="checkTag('tyre_calculator')">
            <v-icon left color="">mdi-calculator</v-icon>
            {{ $t("pricing.calculator") }}
            <PricingCalculator id="top-calculator" class="ma-2" v-if="showCalculatorDialog"
                               @closeClicked="closeCalculator"/>
          </v-btn>
        </template>

        <template v-if="$store.state.inBillingMode ||
                $store.state.inSchedulerMode ||
                $store.state.inSchedulerEditMode  || $store.state.inRecurrenceMode
                ">
          <div id="top-currency-type">
            <v-select hide-details v-if="bankAccounts.length > 1" :items="bankAccounts" v-model="topBankAccount"
                      class="d-flex to-upper mr-4 mt-0 shrink hidden" style="width:140px" item-value="id" return-object
                      outlined dense>
              <template v-slot:selection="{ item }">
                            <span class="text-no-wrap">
                            {{ item.currency.key }} ({{ parseSymbol(item.currency.symbol) }})
                            </span>
              </template>
              <template slot="item" slot-scope="{ item }">
                <v-list-item-content class="pa-0 ma-0">
                  <v-row>
                    <v-col cols="12" sm="2">
                      <span class="topSymbol mt-5">{{ parseSymbol(item.currency.symbol) }}</span>
                    </v-col>
                    <v-col cols="12" sm="10">
                                    <span>
                                    {{ item.bank_name }}
                                    </span>
                      <br/>
                      <span>
                                    {{ item.branch_name }}
                                    </span>
                      <br/>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </template>
            </v-select>
          </div>
        </template>

        <template v-if="$store.state.inRecurrenceMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" class="mt-1 ma-2 text-black-color"
                 color="grey lighten-2" @click="$router.go(-1)">
            {{ $t("nav_buttons.cancel") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-save" class="mt-1 ma-2 text-white-color" color="success"
                 @click="submitMainForm($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </template>

        <!-- Billing and Scheduler Mode -->
        <template v-if="$store.state.inBillingMode || $store.state.inSchedulerMode || $store.state.inSchedulerEditMode">
          <!-- <v-row id="top-bill-type" class="d-flex justify-end"> -->
          <!-- <v-col cols="12" sm="12" class="d-flex justify-end"> -->
          <PricingCalculator id="top-calculator" class="ma-2" v-if="showCalculatorDialog && PricingCalculatorIsActive"
                             @closeClicked="closeCalculator"/>

          <div id="top-calculator" class="mr-4 ml-4 mt-3 pr-2"
               v-if="selectedBillClientId && ($route.path.includes('add') || $route.path.includes('edit') )">
            <ClientBillingHistory :disabled="isTopSubmitLoading" :clientId="selectedBillClientId"/>
          </div>

          <v-btn :disabled="isTopSubmitLoading" id="top-delete" v-if="$store.state.inSchedulerEditMode"
                 class="mr-4 mt-0 text-white-color" color="error"
                 @click="onDelete($event)">
            {{ $t("nav_buttons.delete") }}
            <v-icon dark right> mdi-delete</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" v-if="$store.state.inSchedulerMode || $store.state.inSchedulerEditMode
                        " class="mr-4 mt-0 text-black-color" color="grey lighten-2" @click="moveToSchedulerListing">
            {{ $t("nav_buttons.cancel") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>

          <!-- Tools -->
          <v-menu
              v-if="showSaveButton && selectedBillData && checkTag('billing') && ((workSheetIsActive && vehicleIsActiveInSchedulerSelectedBill) || checkTag('tyre_calculator'))"
              open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="isTopSubmitLoading" id="top-print" dark v-bind="attrs" v-on="on"
                     class="text-white-color mr-2 mt-0" color="primary">
                {{ $t("nav_buttons.tools") }}
                <v-icon dark right> mdi-tools</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="worksheetBill($event)" v-if="workSheetIsActive && vehicleIsActiveInSelectedBill
                            ">
                <v-icon color="green darken-2" left>
                  mdi-book-open-page-variant
                </v-icon>
                {{ $t("nav_buttons.worksheet") }}
              </v-list-item>
              <v-list-item @click="workReportBill($event)" v-if="workSheetIsActive && vehicleIsActiveInSelectedBill
                            ">
                <v-icon color="green darken-2" left>
                  mdi-book-open-page-variant-outline
                </v-icon>
                {{ $t("nav_buttons.workreport") }}
              </v-list-item>
              <v-list-item @click="openCalculatorInNewWindow()" v-if="checkTag('tyre_calculator')">
                <v-icon left color="green darken-2">mdi-calculator</v-icon>
                {{ $t("pricing.calculator") }}
              </v-list-item>
              <v-list-item @click="openOrderCounterDialog" v-if="checkTag('billing') && orderCounterActivated">
                <v-icon left color="green darken-2">mdi-counter</v-icon>
                {{ $t("order_counter.title") }}
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- Convert Purchase Order, Bill, Credit Note -->
          <v-tooltip bottom
                     v-if="  makePurchaseOrderVisible && showSaveButton && selectedBillData && checkTag('billing')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :disabled="isTopSubmitLoading"
                  v-bind="attrs"
                  v-on="on"
                  id="top-make-purchase-order"

                  class="text-white-color mr-4 mt-0"
                  color="orange"
                  @click="makePurchaseOrder()"
              >
                {{ $t("nav_buttons.make_purchase_order") }}
                <v-icon dark right> mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('hint_transform_to_purchase_order') }}</span>
          </v-tooltip>


          <v-tooltip bottom v-if=" makeBillVisible && showSaveButton && selectedBillData && checkTag('billing')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :disabled="isTopSubmitLoading"
                  v-bind="attrs"
                  v-on="on"
                  id="top-make-bill"

                  class="text-white-color mr-4 mt-0"
                  color="orange"
                  @click="makeBill()"
              >
                {{ $t("nav_buttons.make_bill") }}
                <v-icon dark right> mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('hint_transform_to_bill') }}</span>
          </v-tooltip>


          <v-tooltip bottom v-if=" makeCreditNoteVisible && showSaveButton && selectedBillData && checkTag('billing')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :disabled="isTopSubmitLoading"
                  v-bind="attrs"
                  v-on="on"
                  id="top-make-credit-note"
                  class="text-white-color mr-4 mt-0"
                  color="orange"
                  @click="makeCreditNote()"
              >
                {{ $t("nav_buttons.make_credit_note") }}
                <v-icon dark right> mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('hint_transform_to_credit_note') }}</span>
          </v-tooltip>

          <!-- Document -->
          <v-menu open-on-hover bottom offset-y v-if="showSaveButton && selectedBillData">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="isTopSubmitLoading" id="top-print" dark v-bind="attrs" v-on="on"
                     class="text-white-color mr-2 mt-0" color="primary">
                <template v-if="billType && billType.key == 'estimation'">
                  {{ $t("nav_buttons.sub_menu_estimation_print") }}
                  <v-icon dark right> mdi-hand-coin-outline</v-icon>
                </template>
                <template v-if="billType && billType.key == 'purchase_order'">
                  {{ $t("nav_buttons.sub_menu_purchase_print") }}
                  <v-icon dark right> mdi-cart-arrow-down</v-icon>
                </template>
                <template v-if="billType && billType.key == 'bill'">
                  {{ $t("nav_buttons.sub_menu_bill_print") }}
                  <v-icon dark right> mdi-receipt-text-plus-outline</v-icon>
                </template>
                <template v-if="billType && billType.key == 'credit_note'">
                  {{ $t("nav_buttons.sub_menu_credit_print") }}
                  <v-icon dark right> mdi-receipt-text-minus-outline</v-icon>
                </template>
              </v-btn>
            </template>
            <v-list>
              <template v-if="showSaveButton && selectedBillData && checkTag('billing')">
                <v-list-item @click="printBill($event)">
                  <v-icon color="green darken-2" left> mdi-printer</v-icon>
                  <template v-if="billType && billType.key == 'estimation'">
                    {{ $t("nav_buttons.print") }}
                  </template>
                  <template v-if="billType && billType.key == 'purchase_order'">
                    {{ $t("nav_buttons.print") }}
                  </template>
                  <template v-if="billType && billType.key == 'bill'">
                    {{ $t("nav_buttons.print") }}
                  </template>
                  <template v-if="billType && billType.key == 'credit_note'">
                    {{ $t("nav_buttons.print") }}
                  </template>
                </v-list-item>
                <v-list-item @click="emailBill($event)">
                                <span style="cursor: pointer">
                                <v-icon color="green darken-2" left> mdi-email </v-icon>
                                {{ $t("nav_buttons.email") }}
                                </span>
                  <v-spacer></v-spacer>
                  <v-icon
                      v-if="selectedBillData && selectedBillData.email_history && selectedBillData.email_history.length > 0"
                      style="cursor: pointer" @click="openEmailHistoryDialog"> mdi-history
                  </v-icon>
                  <OrderEmailHistory :order="selectedBillData" v-model="emailHistoryDialog"/>
                </v-list-item>
                <v-list-item @click="peppolDialog = true" v-if="selectedBillData && showPeppol()">
                  <v-icon color="green darken-2" left>
                    mdi-send-outline
                  </v-icon>
                  {{ $t("send_peppol") }}
                </v-list-item>
                <v-divider></v-divider>
                <!-- Show if it is Billing -->
                <template v-if="showSaveButton && selectedBillData">
                  <v-list-item @click="addBillNote($event)">
                    <v-icon color="green darken-2" left>
                      mdi-pencil
                    </v-icon>
                    {{ $t("scheduler.add_bill_note") }}
                  </v-list-item>
                </template>
                <template
                    v-if="$store.state.inDetailsMode || $store.state.inJustCancelMode || $store.state.inBillingMode || $store.state.inSchedulerEditMode || $store.state.inSchedulerMode">
                  <v-list-item id="top-attachments"
                               v-if="this.$route.path.includes('/vehicles/edit') || ( showSaveButton && selectedBillData)"
                               class=""
                               color="success" @click="showAttachmentDialog()">
                    <v-icon color="green darken-2" left>
                      mdi-attachment
                    </v-icon>
                    {{ $t("attachments") }} (
                    <template
                        v-if="$store.state.inBillingMode || $store.state.inSchedulerEditMode || $store.state.inSchedulerMode">
                      {{ $store.state.attachmentCounts['billing'] ? $store.state.attachmentCounts['billing'] : 0 }}
                    </template>
                    <template v-if="$route.path.includes('/vehicles/edit')">
                      {{ $store.state.attachmentCounts['vehicle'] ? $store.state.attachmentCounts['vehicle'] : 0 }}
                    </template>
                    )
                    <v-icon dark right> mdi-paperclip</v-icon>
                  </v-list-item>
                </template>
                <v-list-item @click="duplicateDocument()" v-if="checkTag('duplicate_document')">
                    <v-icon left color="green darken-2">mdi-plus-circle-multiple-outline</v-icon>
                    {{ $t("nav_buttons.duplicate_document") }}
                </v-list-item>
                <!-- <v-divider></v-divider> -->
                <!-- <v-list-item v-if="makePurchaseOrderVisible &&
                    showSaveButton &&
                    selectedBillData &&
                    checkTag('billing')
                    " @click="makePurchaseOrder()">
                    <v-icon dark left color="green darken-2"> mdi-tag-text </v-icon>
                    {{ $t("nav_buttons.make_purchase_order") }}
                </v-list-item>
                <v-list-item v-if="makeBillVisible &&
                    showSaveButton &&
                    selectedBillData &&
                    checkTag('billing')
                    " @click="makeBill()">
                    <v-icon dark left color="green darken-2"> mdi-script-text </v-icon>
                    {{ $t("nav_buttons.make_bill") }}
                </v-list-item>
                <v-list-item v-if="makeCreditNoteVisible &&
                    showSaveButton &&
                    selectedBillData &&
                    checkTag('billing')
                    " @click="makeCreditNote()">
                    <v-icon dark left color="green darken-2"> mdi-note-multiple </v-icon>
                    {{ $t("nav_buttons.make_credit_note") }}
                </v-list-item> -->
              </template>
            </v-list>
          </v-menu>

          <!-- Depot -->
          <v-menu v-if="showSaveButton && checkTag('billing') && checkTag('vehicles') && ((showDepotButtons == 1 ||
                            showDepotButtons == 2 ||
                            showDepotButtons == 3 ||
                            showDepotButtons == 4) || $store.state.showPrintReportInPrintDropDown)" open-on-hover bottom
                  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="isTopSubmitLoading" id="top-print" dark v-bind="attrs" v-on="on"
                     class="text-white-color mr-2 mt-0" color="primary">
                {{ $t("nav_buttons.depot") }}
                <span disabled="showDepotButtons === 4" v-if="showDepotOutButton">({{ $t('out_uppercase') }})</span>
                <v-icon dark right> mdi-selection-ellipse-arrow-inside</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="depotIn($event)" v-if="checkTag('vehicles') &&
                            (showDepotButtons == 1 ||
                            showDepotButtons == 3 ||
                            showDepotButtons == 4)
                            ">
                <v-icon dark color="green darken-2" left> mdi-selection-ellipse-arrow-inside</v-icon>
                {{ $t("nav_buttons.depot_in") }}
              </v-list-item>
              <v-list-item :disabled="showDepotButtons == 4" @click="depotOut($event)" v-if="showDepotOutButton">
                <v-icon dark color="green darken-2" left> mdi-debug-step-out</v-icon>
                {{ $t("nav_buttons.depot_out") }}
              </v-list-item>
              <!--                          <v-list-item>-->
              <!--                            <v-icon color="red">mdi-clock-end</v-icon>-->
              <!--                            End of Depot-->
              <!--                          </v-list-item>-->
              <v-list-item v-if="$store.state.showPrintReportInPrintDropDown" @click="printReport($event)">
                <v-icon color="green darken-2" left>
                  mdi-book-open-page-variant
                </v-icon>
                {{ $t("nav_buttons.print_depot_in_report") }}
              </v-list-item>
              <v-list-item v-if="$store.state.showPrintDepotOutReportInPrintDropDown" @click="getElementById('print-depot-out').click()">
                <v-icon color="green darken-2" left>
                  mdi-clock-end
                </v-icon>
                {{ $t("nav_buttons.print_depot_out_report") }}
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" v-if="showSaveButton &&
                        !(
                            $store.state.inSchedulerMode ||
                            $store.state.inSchedulerEditMode
                        )
                        " class="text-black-color mr-4 mt-0" color="grey lighten-2" @click="resetPageData()">
            <template v-if="isBillSelected">{{ $t("nav_buttons.close") }}</template>
            <template v-else>{{ $t("nav_buttons.cancel") }}</template>
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" id="top-save"
                 v-if="showSaveButton && !($route.path.includes('/scheduler/add') || $route.path.includes('/scheduler/edit'))"
                 class="text-white-color mr-4 mt-0" color="success"
                 @click="submitMainForm($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-save"
                 v-if="showSaveButton && ($route.path.includes('/scheduler/add') || $route.path.includes('/scheduler/edit'))"
                 class="text-white-color mr-4 mt-0" color="success"
                 @click="submitMainFormWithoutClose($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-save-and-close"
                 v-if="showSaveButton && ($route.path.includes('/scheduler/add') || $route.path.includes('/scheduler/edit'))"
                 class="text-white-color mr-4 mt-0" color="success"
                 @click="submitMainFormWithClose($event)">
            {{ $t("nav_buttons.save_and_close") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </template>

        <!-- Report Page -->
        <template v-if="$store.state.inReportMode">
          <template v-if="$store.state.reportViewData">
            <v-btn :disabled="isTopSubmitLoading" id="top-report-cancel" class="text-black-color mr-4 mt-0"
                   color="grey lighten-2" @click="cancelReportRun()">
              {{ $t("nav_buttons.cancel_report_params") }}
              <v-icon dark right> mdi-keyboard-return</v-icon>
            </v-btn>
            <v-btn :disabled="isTopSubmitLoading" id="top-report-save" class="text-white-color mr-4 mt-0"
                   color="success" @click="saveReport()">
              {{ $t("nav_buttons.save_report_params") }}
              <v-icon dark right> mdi-table-arrow-right</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn :disabled="isTopSubmitLoading" id="top-report-reset" class="text-black-color mr-4 mt-0"
                   color="grey lighten-2" @click="resetPageData()">
              {{ $t("nav_buttons.reset_report_params") }}
              <v-icon dark right> mdi-refresh</v-icon>
            </v-btn>
            <v-btn :disabled="isTopSubmitLoading" id="top-report-run" class="text-white-color mr-4 mt-0" color="success"
                   @click="runReport()">
              {{ $t("nav_buttons.run_report_params") }}
              <v-icon dark right> mdi-animation-play</v-icon>
            </v-btn>
          </template>
        </template>

        <!-- Expedition Mode -->
        <template v-if="$store.state.inExpeditionMode">

          <v-btn :disabled="isTopSubmitLoading" id="top-expedition-cancel" class="text-black-color mr-4 mt-0"
                 color="grey lighten-2" @click="$router.go(-1)">
            {{ $t("nav_buttons.expedition_cancel") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>
          <!-- Block Expedition creation if client is blocked -->
          <v-btn :disabled="isTopSubmitLoading || (selectedExpeditor && typeof selectedExpeditor == 'object' && selectedExpeditor.status_id == 10041003)" id="top-expedition-save" class="text-white-color mr-4 mt-0"
                 color="success" @click="saveExpedition()">
            {{ $t("nav_buttons.expedition_save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-expedition-archive"
                 v-if="this.$route.path.includes('/expedition/edit')" class="text-white-color mr-4 mt-0" color="error"
                 @click="expeditionArchive()">
            {{ $t("nav_buttons.expedition_archive") }}
            <v-icon dark right> mdi-archive</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-expedition-add-document"
                 v-if="this.$route.path.includes('/expedition/edit')" class="text-black-color mr-4 mt-0"
                 color="grey lighten-2" @click="addDocumentToExpedition()">
            {{ $t("nav_buttons.expedition_add_document") }}
            <v-icon right> mdi-file-document-plus</v-icon>
          </v-btn>

          <v-menu open-on-hover bottom offset-y v-if="this.$route.path.includes('/expedition/edit')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="isTopSubmitLoading" id="top-expedition-add-bill" dark v-bind="attrs" v-on="on"
                     class="text-white-color mr-2 mt-0" color="primary">
                {{ $t("nav_buttons.expedition") }}
                <v-icon dark right> mdi-file-document</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template>
                <v-list-item @click="printExpeditionBill($event)">
                  <v-icon color="green darken-2" left> mdi-printer</v-icon>
                  {{ $t("nav_buttons.print") }}
                </v-list-item>
                <v-list-item @click="activateExpeditionEmailDialog">
                  <v-icon color="green darken-2" left> mdi-email</v-icon>
                  {{ $t("nav_buttons.email") }}
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="makeExpeditionBill()"
                             :disabled="! (this.$route.path.includes('/expedition/edit') && $store.state.selectedExpeditionStatus && $store.state.selectedExpeditionStatus.key !== 'new')">
                  <v-icon color="green darken-2" left
                          :disabled="! (this.$route.path.includes('/expedition/edit') && $store.state.selectedExpeditionStatus && $store.state.selectedExpeditionStatus.key !== 'new')">
                    mdi-file-document-arrow-right
                  </v-icon>
                  {{ $t("nav_buttons.expedition_make_document") }}
                </v-list-item>
                <v-list-item @click="makeExpeditionExpense()"
                             :disabled="! (this.$route.path.includes('/expedition/edit') && $store.state.selectedExpedition && $store.state.selectedTransporter && checkIfBillDocumentExists())">
                  <v-icon color="green darken-2" left
                          :disabled="! (this.$route.path.includes('/expedition/edit') && $store.state.selectedExpedition && $store.state.selectedTransporter && checkIfBillDocumentExists())">
                    mdi-cash-register
                  </v-icon>
                  {{ $t("nav_buttons.expenses") }}
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <v-btn :disabled="isTopSubmitLoading" id="top-expedition-duplicate"
                 v-if="this.$route.path.includes('/expedition/edit')" class="text-white-color mr-4 mt-0" color="primary"
                 @click="duplicateExpedition()">
            {{ $t("nav_buttons.expedition_duplicate") }}
            <v-icon dark right> mdi-file-document-arrow-right</v-icon>
          </v-btn>
        </template>

        <!-- Expedition Listing Mode -->
        <template v-if="$store.state.inExpeditionListingMode">
          <v-btn :disabled="isTopSubmitLoading || ! checkIfSameAddress()" id="top-expedition-make-bill"
                 @click="makeExpeditionBill()"
                 v-if="selectedExpeditionIdsForBilling && selectedExpeditionIdsForBilling.length > 0"
                 class="text-white-color mr-4 mt-2" color="primary">
            {{ $t("nav_buttons.expedition_make_document") }}
            <v-icon dark right> mdi-file-document-arrow-right</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading || ! checkIfSameTransporters()" id="top-expedition-make-expense"
                 @click="makeExpeditionExpense()"
                 v-if="selectedExpeditionIdsForBilling && selectedExpeditionIdsForBilling.length > 0"
                 class="text-white-color mr-4 mt-2" color="primary">
            {{ $t("nav_buttons.expenses") }}
            <v-icon dark right> mdi-cash-register</v-icon>
          </v-btn>


          <v-btn :disabled="isTopSubmitLoading" id="top-create" class="ma-2" color="primary" @click="onAddClick()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
        </template>

        <!-- Expense Mode -->
        <template v-if="$store.state.inExpenseMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-delete"
                 v-if="this.$route.path.includes('/expenses/edit') && $store.state.inExpenseMode"
                 class="mr-4 mt-0 text-white-color" color="error"
                 @click="deleteExpense">
            {{ $t("nav_buttons.delete") }}
            <v-icon dark right> mdi-delete</v-icon>
          </v-btn>

          <!-- Document -->
          <v-menu open-on-hover bottom offset-y v-if="showSaveButton && selectedExpenseData">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="isTopSubmitLoading" id="top-print" dark v-bind="attrs" v-on="on"
                     class="text-white-color mr-4 mt-0" color="primary">
                <template v-if="billType && billType.key == 'estimation'">
                  {{ $t("nav_buttons.sub_menu_estimation_print") }}
                  <v-icon dark right> mdi-hand-coin-outline</v-icon>
                </template>
                <template v-if="billType && billType.key == 'purchase_order'">
                  {{ $t("nav_buttons.sub_menu_purchase_print") }}
                  <v-icon dark right> mdi-cart-arrow-down</v-icon>
                </template>
                <template v-if="billType && billType.key == 'bill'">
                  {{ $t("nav_buttons.sub_menu_bill_print") }}
                  <v-icon dark right> mdi-receipt-text-plus-outline</v-icon>
                </template>
                <template v-if="billType && billType.key == 'credit_note'">
                  {{ $t("nav_buttons.sub_menu_credit_print") }}
                  <v-icon dark right> mdi-receipt-text-minus-outline</v-icon>
                </template>
              </v-btn>
            </template>
            <v-list>
              <template v-if="showSaveButton && selectedExpenseData && checkTag('billing')">
                <!-- <v-list-item @click="printBill($event)">
                    <v-icon color="green darken-2" left> mdi-printer </v-icon>
                    <template v-if="billType && billType.key == 'estimation'">
                    {{ $t("nav_buttons.print") }}
                    </template>
                    <template v-if="billType && billType.key == 'purchase_order'">
                    {{ $t("nav_buttons.print") }}
                    </template>
                    <template v-if="billType && billType.key == 'bill'">
                    {{ $t("nav_buttons.print") }}
                    </template>
                    <template v-if="billType && billType.key == 'credit_note'">
                    {{ $t("nav_buttons.print") }}
                    </template>
                </v-list-item>
                <v-list-item @click="emailBill($event)">
                    <v-icon color="green darken-2" left> mdi-email </v-icon>
                    {{ $t("nav_buttons.email") }}
                </v-list-item>
                <v-divider></v-divider> -->
                <!-- Show if it is Billing -->
                <template v-if="showSaveButton && selectedExpenseData">
                  <v-list-item @click="addBillNote($event)">
                    <v-icon color="green darken-2" left>
                      mdi-pencil
                    </v-icon>
                    {{ $t("scheduler.add_bill_note") }}
                  </v-list-item>
                </template>
                <template v-if="$store.state.inDetailsMode || $store.state.inExpenseMode">
                  <v-list-item id="top-attachments" v-if="( showSaveButton && selectedExpenseData)" class=""
                               color="success" @click="showAttachmentDialog()">
                    <v-icon color="green darken-2" left>
                      mdi-attachment
                    </v-icon>
                    {{ $t("attachments") }} (
                    <template v-if=" $store.state.inExpenseMode">
                      {{ $store.state.attachmentCounts['expenses'] ? $store.state.attachmentCounts['expenses'] : 0 }}
                    </template>
                    )
                    <v-icon dark right> mdi-paperclip</v-icon>
                  </v-list-item>
                </template>
              </template>
            </v-list>
          </v-menu>

          <v-btn :disabled="isTopSubmitLoading" id="top-cancel" v-if="showSaveButton &&
                        !(
                        $store.state.inSchedulerMode ||
                        $store.state.inSchedulerEditMode
                        )
                        " class="text-black-color mr-4 mt-0" color="grey lighten-2" @click="resetPageData()">
            <template v-if="isExpenseSelected">{{
                $t("nav_buttons.close")
              }}
            </template>
            <template v-else>{{ $t("nav_buttons.cancel") }}</template>
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>

          <v-btn :disabled="isTopSubmitLoading" id="top-save" v-if="showSaveButton" class="text-white-color mr-4 mt-0"
                 color="success"
                 @click="submitMainForm($event)">
            {{ $t("nav_buttons.save") }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </template>

        <!-- Expense Listing Mode -->
        <template v-if="$store.state.inExpenseListingMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-expense-create" class="ma-2" color="primary"
                 @click="onAddClick()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-expense-bulk-payment"
                 v-if="selectedItemsForPayment && selectedItemsForPayment.length > 0" class="ma-2" color="primary"
                 @click="showBulkPaymentModel()">
            {{ $t("nav_buttons.make_bulk_payment") }}
            <span v-if="totalSelectedItemsForPayment > 0">
                            ({{ totalSelectedItemsForPayment }})
                        </span>
            <v-icon dark right> mdi-cash-clock</v-icon>
          </v-btn>
          <span style="order: 3" v-if="checkUserTag('expense_export_csv')">
                    <ExportDropDownButtons/>
                </span>

        </template>

        <!-- Billing Listing Mode -->
        <template v-if="$store.state.inBillingListingMode">
          <v-btn :disabled="isTopSubmitLoading" id="top-expense-create" class="ma-2" color="primary"
                 @click="onAddClick()">
            {{ $t("nav_buttons.add") }}
            <v-icon dark right> mdi-plus</v-icon>
          </v-btn>
          <v-btn :disabled="isTopSubmitLoading" id="top-expense-bulk-payment"
                 v-if="selectedItemsForPayment && selectedItemsForPayment.length > 0" class="ma-2" color="primary"
                 @click="showBulkPaymentModel()">
            {{ $t("nav_buttons.make_bulk_payment") }}
            <span v-if="totalSelectedItemsForPayment > 0">
                            ({{ totalSelectedItemsForPayment }})
                        </span>
            <v-icon dark right> mdi-cash-clock</v-icon>
          </v-btn>

          <span style="order: 3;" v-if="checkUserTag('billing_export_csv')">
                        <ExportDropDownButtons/>
                    </span>
        </template>

      </div>
      <v-progress-linear class="data-progressbar" :active="this.$store.state.progressBarLoading" indeterminate color="primary">
      </v-progress-linear>
    </v-app-bar>

    <v-main class="ml-5 mr-5 mt-4 mb-0" :class="this.$route.path == '/' ? 'smooth-bg-color' : ''">
      <!-- Alert Dialog  -->
      <div class="v-alert-positation-wrapper" v-for="alert in this.$store.state.alerts" :key="alert"
           :hidden="!alert.show">
        <v-alert class="v-alert-positation" :type="alert.type" dismissible border="left" elevation="10" colored-border>
          <span v-html="alert.msg"></span>
        </v-alert>
      </div>
      <keep-alive :include="isAuth ? includeAlive : ''">
        <router-view v-if="$store.state.remount"></router-view>
      </keep-alive>

      <!-- Confirmation Dialog for Peppol -->
      <v-dialog persistent v-model="peppolDialog" min-width="400" max-width="500">
        <v-card>
          <v-card-title class="text-title">
            {{ $t("confirmation") }}
          </v-card-title>

          <v-card-text class="dialog-text text-content" color="black">
            {{ $t("peppol.confirmation") }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pr-2">
            <v-spacer></v-spacer>
            <v-btn :disabled="isTopSubmitLoading || isLoading" color="grey lighten-2" class="px-4"
                   @click="peppolDialog = false">
              {{ $t("no") }}
              <v-icon dark right> mdi-keyboard-return</v-icon>
            </v-btn>
            <v-btn :disabled="isTopSubmitLoading" class="px-4" color="success" :loading="isLoading"
                   @click="sendPeppol()">
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirmation Dialog For Peppol Ends Here -->
    </v-main>
    <order-counter-form
        v-if="selectedBill"
        :order="selectedBill"
        :order-detail="selectedOrderDetailForCounter"
        v-model="orderCounterDialog"
    />
    <PopupNotificationHandler/>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import {API_BASE_URL, PEPPOL_BILL_TYPES} from "@/config";
import axios from "@/plugins/axios";
import {mapState} from "vuex";
import peppolMixin from "@/mixins/peppolMixin";
import ClientBillingHistory from "@/components/Billing/ClientBillingHistory.vue";
import PDFViewer from "@/components/PDFViewer";
import OrderCounterForm from "@/components/Billing/OrderCounterForm.vue";
import OrderEmailHistory from "@/components/Emails/OrderEmailHistory.vue";
import ExportDropDownButtons from "@/components/ExportDropDownButtons.vue";
import InterventionsButton from "@/components/Buttons/interventionsButton.vue";
import AddButton from "@/components/Buttons/AddButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import CloseButton from "@/components/Buttons/CloseButton.vue";
import PopupNotificationHandler from "@/components/PopupNotificationHandler.vue";

export default {
  name: "App",
  components: {
    CloseButton,
    AddButton,
    SaveButton,
    InterventionsButton,
    ExportDropDownButtons,
    OrderEmailHistory,
    ClientBillingHistory,
    Navigation,
    PDFViewer,
    OrderCounterForm,
    PopupNotificationHandler,
    PricingCalculator: () =>
        import("@/components/ArticlePricing/PricingCalculator"),
  },
  mixins: [peppolMixin],
  data: () => ({
    emailHistoryDialog: false,
    showCalculatorDialog: false,
    peppolDialog: false,
    orderCounterDialog: false,
    currentAppointmentStatus: null,
    includeAlive: ["ArticlesListing", "SchedulerListing", "Scheduler"],
    tyreDepotIsActive: false,
    workSheetIsActive: false,
    right: null,
    isAuth: false,
    calculatorWindow: null,
    textbackgroundcolor: "grey lighten-4",
    expensetextbackgroundcolor: "grey lighten-4",
    languages: [
      {
        title: "English",
        code: "en",
      },
      {
        title: "Freanch",
        code: "fr",
      },
    ],
  }),
  methods: {
    getPageTitleForBillingPage(title) {
      if (this.$route.meta.title == 'billing' && this.navigationData) {
        console.log("bill type page title###", this.billType, title);
        title = this.$t(this.navigationData.name);
      }
      if (this.$route.meta.submit == 'submit-billing' && this.billType) {
        console.log("bill type page title----", this.billType, title);
        if (this.billType.text) {
          title = this.billType.text;
        } else {
          title = this.$t("billings.types." + this.billType.key);
        }

      }
      return title;
    },
    getPageTitleForExpensePage(title) {
      if (this.$route.meta.title == 'expenses' && this.navigationData) {
        console.log("bill type page title###", this.billType, title);
        title = this.$t('router_titles.expenses') + " - " + this.$t(this.navigationData.name);
      }
      if (this.$route.meta.submit == 'submit-expenses' && this.billType) {
        console.log("bill type page title----", this.billType, title);
        if (this.billType.text) {
          title = this.$t('router_titles.expenses') + " - " + this.billType.text;
        } else {
          title = this.$t('router_titles.expenses') + " - " + this.$t("billings.types." + this.billType.key);
        }

      }
      return title;
    },
    showCartPopUpForStocks(){
        document.getElementById("show-cart-pop-up-for-stocks").click();
    },
    moveToSchedulerListing() {
      this.$router.push("/scheduler").catch(() => {
      });
    },
    moveToScheduler() {
      this.$router.push("/scheduler/add").catch(() => {
      });
    },
    getCompanyBanksRIB(bank_id) {
      let module_id = this.getModuleId('company_banks'); // Company_banks as in Application Resource
      if (module_id && bank_id) {
        axios
            .get(
                API_BASE_URL +
                `/attachments?module_id=${module_id}&resource_key_id=${bank_id}`,
                {
                  headers: {
                    Authorization:
                        "Bearer " + this.$store.state.AccessToken,
                  },
                }
            )
            .then(({data}) => {
              data.data.result.forEach((bankAttachment) => {
                bankAttachment.bank_id = bank_id;
              });
              let attachments = data.data.result;
              this.company_bank_attachments = this.company_bank_attachments.concat(attachments);
              localStorage.setItem("bankAttachments", JSON.stringify(this.company_bank_attachments));
              console.log("bank_attachments", this.company_bank_attachments);
            })
            .catch((error) => {
              console.log("an error occured " + error);
            })
            .finally(() => {
            });
      }
    },
    getElementById(id) {
      return document.getElementById(id);
    },
    checkIfSameAddress() {
      let isSameAddress = true;
      if (this.selectedExpeditionIdsForBilling && this.selectedExpeditionIdsForBilling.length > 0) {
        let lastAddressId = null;
        this.selectedExpeditionIdsForBilling.forEach((element) => {
          console.log("same_expeditor_checked", element.expeditor_billing_working_point.address_id, lastAddressId);
          if (lastAddressId == null) {
            lastAddressId = element.expeditor_billing_working_point.address_id;
          }
          if (lastAddressId !== element.expeditor_billing_working_point.address_id) {
            isSameAddress = false;
          }
        })
      }
      return isSameAddress;
    },
    checkIfSameTransporters() {
      let isSameExpeditor = true;
      if (this.selectedExpeditionIdsForBilling && this.selectedExpeditionIdsForBilling.length > 0) {
        let lastTransporterId = null;
        this.selectedExpeditionIdsForBilling.forEach((element) => {
          console.log("same_transporter_checked", element.transporter_id, lastTransporterId);
          if (lastTransporterId == null) {
            lastTransporterId = element.transporter_id;
          }
          if (lastTransporterId !== element.transporter_id || element.transporter_id == null) {
            isSameExpeditor = false;
          }
        })
      }
      return isSameExpeditor;
    },
    checkIfBillDocumentExists() {
      let documents = this.$store.state.selectedExpeditionDocuments;
      let billFound = false
      documents.forEach(element => {
        console.log("bill_documents", element.description, (element.description ?? "").toLowerCase().includes('bill'));
        if ((element.description ?? "").toLowerCase().includes('bill')) {
          billFound = true;
        }
      });
      return billFound;
    },
    activateExpeditionEmailDialog() {
      document.getElementById("activate-expedition-email").click();
    },
    duplicateExpedition() {
      document.getElementById("duplicate-expedition").click();
    },
    openEmailHistoryDialog() {
      this.emailHistoryDialog = false;
      setTimeout(() => {
        this.emailHistoryDialog = true
        clearTimeout(this.timerId);
      }, 20)
    },
    openPrintLabelDialog() {
      document.getElementById('print-labels').click()
    },
    addNewRoleDialog() {
      document.getElementById('add-new-role').click()
    },
    openOrderCounterDialog() {
      this.orderCounterDialog = false;
      setTimeout(() => this.orderCounterDialog = true, 10);
    },
    addNewTriggerHandler() {
      document.getElementById('addTriggerButton').click()
    },
    showBulkPaymentModel() {
      document.getElementById('show-payment-model').click()
    },
    showCalculator() {
      this.showCalculatorDialog = true;
    },
    openCalculatorInNewWindow() {
      this.calculatorWindow = window.open("/pricingcalculator", '_blank');
    },
    duplicateDocument(){
        document.getElementById('duplicate-document').click()
    },
    closeCalculator() {
      this.showCalculatorDialog = false;
    },
    async setTyreDepotActiveStatus() {
      return new Promise((resolve, reject) => {
        axios
            .get(`${API_BASE_URL}/company_application_resources`, {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              },
            })
            .then(({data}) => {

              let response = data.data;
              let tyreDepot = response.find(
                  (item) =>
                      item.application_resource.key == "tyre_depot" && item.is_active
              );
              if (tyreDepot) {
                this.tyreDepotIsActive = true;
                this.$store.state.tyreDepotIsActive = true;
              }

              let workSheetReport = response.find(
                  (item) =>
                      item.application_resource.key == "worksheet_report" &&
                      item.is_active
              );

              if (workSheetReport) {
                this.workSheetIsActive = true;
                this.$store.state.workSheetIsActive = true;
              }

              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    invertDrawer: function () {
      this.$store.commit("invertNavDrawerVisibility");

      return this.$store.state.showNavDrawer;
    },
    /**
     * Submit main form when click save button
     */
    submitMainForm: function () {
      document.getElementById(this.$route.meta.submit).click();
    },
    submitMainFormWithoutClose: function () {
      document.getElementById("submit-scheduler-without-close").click();
    },
    submitMainFormWithClose: function () {
      document.getElementById(this.$route.meta.submit).click();
    },
    showAttachmentDialog: function () {
      document.getElementById("add-attachment").click();
    },
    createReception: function () {
      document.getElementById("create-reception").click();
    },
    addArticleToStock: function () {
      document.getElementById("add-article-to-stock").click();
    },
    loadLocalisation: function () {
      document.getElementById("add-localisation").click();
    },
    printBill: function () {
      document.getElementById("print-bill").click();
    },
    worksheetBill: function () {
      document.getElementById("worksheet-bill").click();
    },
    workReportBill: function () {
      document.getElementById("workreport-bill").click();
    },
    printReport: function () {
      document.getElementById("print-report").click();
    },
    addBillNote: function () {
      document.getElementById("add-bill-note").click();
    },
    emailBill: function () {
      this.timerId = setTimeout(() => {
        document.getElementById("email-bill").click();
      }, 30);

    },
    depotIn: function () {
      document.getElementById("depot-in").click();
    },
    depotOut: function () {
      document.getElementById("depot-out").click();
    },
    resetPageData: function () {
      document.getElementById("reset-page-data").click();
    },
    runReport: function () {
      document.getElementById("run-report").click();
    },
    saveReport: function () {
      document.getElementById("save-report").click();
    },
    cancelReportRun: function () {
      document.getElementById("cancel-run-report").click();
    },
    makeBill: function () {
      document.getElementById("make-bill").click();
    },
    makePurchaseOrder: function () {
      document.getElementById("make-purchase-order").click();
    },
    makeCreditNote: function () {
      document.getElementById("make-credit-note").click();
    },
    saveExpedition: function () {
      document.getElementById("save-expedition").click();
    },
    expeditionArchive: function () {
      document.getElementById("make-expedition-archive").click();
    },
    addDocumentToExpedition: function () {
      document.getElementById("add-document-to-expedition").click();
    },
    makeExpeditionBill: function () {
      document.getElementById("make-expedition-bill").click();
    },
    makeExpeditionExpense: function () {
      document.getElementById("make-expedition-expense").click();
    },
    printExpeditionBill: function () {
      document.getElementById("print-expedition-bill").click();
    },
    onDelete: function () {
      document.getElementById(this.$route.meta.delete).click();
    },
    exportData: function () {
      document.getElementById("export-data").click();
    },
    deleteExpense() {
      document.getElementById("delete-expenses").click();
    },
    onAddClick() {
      this.$router.push(this.$route.path + "/add").catch(() => {
      });
    },
    logout() {
      const token = this.$store.state.AccessToken;
      if (this.calculatorWindow) {
        this.calculatorWindow.close();
      }
      axios({
        method: "get",
        url: API_BASE_URL + "/logout",
        headers: {Authorization: "Bearer " + token},
      }).then((resLogout) => {
        localStorage.clear();
        this.$store.state.AccessToken = "";
        // this.$router.push("/login").catch(() => { });
      });
    },
    onListClick(title) {
      if (title == "Logout") {
        this.logout();
      }
    },
    getCompanyBanks() {
      
      const token = this.$store.state.AccessToken;
      axios({
        method: "get",
        url: API_BASE_URL + "/banks",
        headers: {Authorization: "Bearer " + token},
      })
          .then(({data}) => {
            if (data.data && data.data.length > 0) {
              this.$store.commit("setBankAccounts", data.data);
              let isDefaultBankAccountSet = false;
              data.data.forEach((element) => {
                this.getCompanyBanksRIB(element.id);
                if (element.is_default) {
                  this.$store.commit("setBankAccount", element);
                  isDefaultBankAccountSet = true
                }
              })
              if (!isDefaultBankAccountSet) {
                this.$store.commit("setBankAccount", data.data[0]);
              }
            }
          })
          .catch(function (error) {
            console.log('an error occured ' + error);
          })
          .finally(() => {
            
          });
    },
    getCountries() {
      return new Promise((resolve, reject) => {
        const token = this.$store.state.AccessToken;
        return axios({
          method: "get",
          url: API_BASE_URL + "/countries?items_per_page=-1",
          headers: {Authorization: "Bearer " + token},
        })
            .then(({data}) => {
              this.$store.commit("setCountries", data.data);
              resolve();
            })
            .catch((error) => {
              reject();
            });
      })
    },
    async getBillTypes() {
      await this.getCodes('order_types', 'billings.types')
          .then((data) => {
            console.log("Getting Bill Types ....", data);
            data.forEach((element, index) => {
              if (element.key == "bill") {
                data[index].icon = "mdi-script-text";
              } else if (element.key == "purchase_order") {
                data[index].icon = "mdi-tag-text";
              } else if (element.key == "estimation") {
                data[index].icon = "mdi-book-open";
              } else if (element.key == "credit_note") {
                data[index].icon = "mdi-note-multiple";
              }
            });
            console.log("selected bill from bill types");
            this.selectDefaultBillTypeFromNavigationData();
            this.$store.commit("setBillTypes", data);
            this.setExpensesTypes();
          });
    },
    setExpensesTypes() {
      let expensesTypes = this.billTypes.filter((element) => {
        if (element.key == "bill" || element.key == "credit_note") {
          return element;
        }
      });
      this.$store.commit("setExpensesTypes", expensesTypes);
      this.expensesType = this.selectDefaultExpenseTypeFromNavigationData();
    },
    async getAdressTypes() {
      await this.getCodes('address_types', 'address_types')
          .then((data) => {
            this.$store.commit("setCachedAdressTypes", data);
          })
    },
    async getMemberTypes(){
        await this.getCodes('member_types', 'member_types')
        .then((data) => {
            console.log("member_types", this.memberType);
        })
    },
    getExpeditionArticle(article_id) {
      if (article_id) {
        axios.get(API_BASE_URL + "/articles/" + article_id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.AccessToken,
          },
        })
            .then(({data}) => {
              data.data.id = data.data.article_id;
              localStorage.setItem("expedition_article", JSON.stringify(data.data));
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    async getTaxationReason() {
      await this.getCodes('taxation_reasons', 'taxation_reasons')
          .then((data) => {
            this.$store.commit("setTaxationReasons", data);
          })
    },
    getFamilyArticles() {
      axios
          .get(
              API_BASE_URL + "/family_articles?items_per_page=-1&sortby=order,name",
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.AccessToken,
                },
              }
          )
          .then(({data}) => {
            data.data.forEach((family_article) => {
              // If family article has properties then its properties will be of family article
              if (
                  family_article &&
                  family_article.parent_family_properties.length > 0
              ) {
                family_article.properties =
                    family_article.parent_family_properties;
              }
            });
            this.$store.commit("setFamilyArticles", data.data);
          });
    },
    async getTyreTypes() {
      await this.getCodes('tyre_types', false)
          .then((data) => {
            this.$store.commit("setTyreTypes", data);
          })
    },
    async getProjectStatuses() {
      await this.getCodes('project_status', 'project_statuses')
          .then((data) => {
            this.$store.commit("setClientProjectsStatus", data);
          })
    },
    async getWheelTypes() {
      await this.getCodes('wheel_types', 'types')
          .then((data) => {
            this.$store.commit("setWheelTypes", data);
          })
    },
    async getWheelMaterial() {
      await this.getCodes('wheel_material', 'types')
          .then((data) => {
            this.$store.commit("setWheelMaterials", data);
          })
    },
    async getTyreSeason() {
      await this.getCodes('tyre_season', 'tyre_season')
          .then((data) => {
            this.$store.commit("setTyreSeasons", data);
          })
    },
    async getStatuses() {
      await this.getCodes('article_statuses', 'article_statuses')
          .then((data) => {
            this.$store.commit("setStatuses", data);
          })
    },
    async getApprovals() {
      await this.getCodes('article_approval_statuses', 'article_approval_statuses')
          .then((data) => {
            this.$store.commit("setApprovals", data);
          })
    },
    async getOrderReminderTypes() {
      await this.getCodes('order_reminders_types', 'order_reminders_types')
          .then((data) => {
            this.$store.commit("setOrderRemindersTypes", data);
          })
    },
    getFamilyClients() {
      axios
          .get(API_BASE_URL + "/family_clients", {
            headers: {Authorization: "Bearer " + this.$store.state.AccessToken},
          })
          .then(({data}) => {
            this.$store.commit("setFamilyClients", data.data);
          });
    },
  },
  async created() {
    setInterval(() => {
      if (
          this.$route.path == "/" ||
          this.$route.path == "/rentals" ||
          this.$route.path == "/rentals/history"
      ) {
        this.$store.state.inViewMode = false;
      }

      this.isAuth = this.$route.meta.auth;
      // console.log("Auth Route", this.isAuth);
      if (
          (!localStorage.getItem("accessToken") ||
              !localStorage.getItem("applicationResources")) &&
          this.isAuth
      ) {
        localStorage.clear();
        this.$router.push("/login").catch(() => {
        });
        if (this.calculatorWindow) {
          this.calculatorWindow.close();
        }
      }
    }, -1);
  },
  watch: {
    navigationData: {
      handler: function (oldNav, newNav) {
        console.log("old nav new nav selected bill expense*******", oldNav, newNav);
        this.selectDefaultBillTypeFromNavigationData();
        this.selectDefaultExpenseTypeFromNavigationData();
      },
    },
    selectedExpeditionIdsForBilling(val) {
      console.log("selected_expedition", this.selectedExpeditionIdsForBilling);
    },
    $route(to, from) {
      if (to.name == "EditScheduler") {
        this.currentAppointmentStatus = this.$store.state?.appointment?.status?.id;
      }
    },
    billType: {
      handler: function () {
        if (this.billType && this.billType.key == "purchase_order") {
          this.textbackgroundcolor = "green lighten-4";
        } else if (this.billType && this.billType.key == "bill") {
          this.textbackgroundcolor = "deep-orange lighten-4";
        } else {
          this.textbackgroundcolor = "grey lighten-4";
        }
      },
    },
    expensesType: {
      handler: function () {
        if (this.expensesType && this.expensesType.key == "purchase_order") {
          this.expensetextbackgroundcolor = "green lighten-4";
        } else if (this.expensesType && this.expensesType.key == "bill") {
          this.expensetextbackgroundcolor = "deep-orange lighten-4";
        } else {
          this.expensetextbackgroundcolor = "grey lighten-4";
        }
      },
    },
    isAuth: {
      handler: function () {
        if (this.$route.meta.auth) {
          // Set Map Api Key
          let mapApiKey = this.getCompanyPropertyFromLocalStorage('ADDRESS_LOCALISATION_KEY') ? this.getCompanyPropertyFromLocalStorage('ADDRESS_LOCALISATION_KEY').value : null;
          this.$store.commit("setMapApiKey", mapApiKey);

          // Call Necessary API's To update the variables
          this.getCountries().then(() => {
            this.getFamilyArticles();
            this.getTaxationReason();
            this.getApprovals();
            this.getWheelTypes();
            this.getWheelMaterial();
            this.getTyreSeason();
            this.getStatuses();
            this.getTyreTypes();
            this.getProjectStatuses();
            this.getCompanyBanks();
            this.getFamilyClients();
            this.getOrderReminderTypes();
            this.getAdressTypes();
            this.getMemberTypes();
            this.$nextTick(() => {
              console.log("expedition_article_id______________", this.getCompanyPropertyFromLocalStorage('EXPEDITION_CHARGE_DISCHARGE_ARTICLE_ID'));
              let expeditionArticle = this.getCompanyPropertyFromLocalStorage('EXPEDITION_CHARGE_DISCHARGE_ARTICLE_ID') ? this.getCompanyPropertyFromLocalStorage('EXPEDITION_CHARGE_DISCHARGE_ARTICLE_ID').value : null;
              console.log("expedition_article_id", expeditionArticle?.value);
              this.getExpeditionArticle(expeditionArticle);
            });
          });
          this.setTyreDepotActiveStatus();
          this.$store.dispatch("fetchAppointmentStatuses");
          //   this.$store.commit("setPdfMailConfig", this.defaultPdfEmailConfig);
        }
      },
    },
  },
  computed: {
    ...mapState([
      "selectedArticlePendingAppointment",
      "showApplySelectedArticleButton",
      "appointmentStatuses",
      "isLoading",
      "currentlySelectedClient"
    ]),
    ...mapState({
      selectedBill: (state) => state.selectedBillData,
    }),
    showDepotOutButton() {
      return (this.checkTag('vehicles') &&
          this.billType &&
          this.billType.key !== 'estimation' &&
          (this.showDepotButtons === 2 ||
              this.showDepotButtons === 3 ||
              this.showDepotButtons === 4)
      )
    },
    pageTitle() {
      // let title = (localStorage.getItem("company_name") ? localStorage.getItem("company_name") + " - " : "") + this.$t("router_titles." + this.$route.meta.title);
      let title = this.$t("router_titles." + this.$route.meta.title);

      // If Navigation Includes Children of Billing/Expense then set the title to navigationData bill/expense type
      title = this.getPageTitleForBillingPage(title);
      title = this.getPageTitleForExpensePage(title);
      console.log("bill type page title", this.billType, title);
      return title;
    },
    navigationData() {
      return this.$store.state.navigationData;
    },
    isTopSubmitLoading() {
      return this.$store.state.topSubmitLoading;
    },
    selectedExpeditor(){
        return this.$store.state.selectedExpeditor;
    },
    disableExpeditionExpenseButton() {
      return !(
          this.$route.path.includes('/expedition/edit')
          && this.$store.state.selectedExpedition
          && this.$store.state.selectedExpedition.expense_id
          && this.$store.state.selectedTransporter
      )
    },
    vehicleDataForAppBar() {
      return this.$store.state.vehicleDataForAppBar;
    },
    selectedArticlePendingAppointment() {
      return this.$store.state.selectedArticlePendingAppointment;
    },
    selectedItemsForPayment() {
      return this.$store.state.selectedItemsForBulkPayment;
    },
    totalSelectedItemsForPayment() {
      let total = 0;
      let items = this.$store.state.selectedItemsForBulkPayment;
      if (items && items.length > 0) {
        return items.length;
      }
      return 0;
    },
    selectedExpeditionIdsForBilling() {
      return this.$store.state.selectedExpeditionIdsForBilling;
    },
    orderCounterActivated() {
      if (!this.selectedBill || !this.selectedBill.details || !this.isset(this.selectedBill.client?.vehicles)) {
        // || !this.isset(this.selectedBill.vehicle)
        return false
      }

      return this.selectedBill.details.some(item => {
        if (typeof (item.article?.property) !== 'object') {
          return false;
        }

        return this.isset(item.article?.property?.property_50);
      });
    },
    selectedOrderDetailForCounter() {
      if (!this.orderCounterActivated) return null;

      return this.selectedBill.details.find(item => {
        if (!item.article?.property || typeof (item.article?.property) !== 'object') {
          return false;
        }

        return this.isset(item.article?.property?.property_50)
      })
    },
    selectedBillClientId() {
      let selectedBillId = this?.selectedBill?.client_id ?? null;
      let selectedClientId = this?.currentlySelectedClient?.client_id ?? null;

      return selectedBillId ?? selectedClientId;
    },
    vehicleIsActiveInSchedulerSelectedBill() {
      // Check if current route contains scheduler
      if (!this.$route.path.includes("scheduler")) {
        return true;
      }

      // Check if a bill is selected
      if (!this.selectedBill) {
        return true;
      }

      // Check if vehicle is active in selected bill
      let vehicleId = this.selectedBill.vehicle_id;

      return this.isset(vehicleId);
    },
    vehicleIsActiveInSelectedBill() {
      return this.isset(this.selectedBill?.vehicle_id);
    },
    PricingCalculatorIsActive() {
      return (
          this.tyreDepotIsActive && this.vehicleIsActiveInSchedulerSelectedBill
      );
    },
    appointmentStatusesItem() {
      return this.appointmentStatuses.map((item) => {
        return {
          label: this.$t("schedulestab.appointment_status." + item.key),
          value: item.id,
        };
      });
    },
    billType: {
      get: function () {
        if (
            !this.$store.state.billType ||
            this.$store.state.billTypes.length == 0
        ) {
          if (
              this.$route.path.includes("billing") ||
              this.$route.path.includes("scheduler") ||
              this.$route.path.includes("recurrence") ||
              this.$route.path.includes("expenses")
          ) {
            this.getBillTypes();
          }
        }
        return this.$store.state.billType;
      },
      set: function (newValue) {
        this.$store.commit("setBillType", newValue);
      },
    },
    expensesType: {
      get: function () {
        if (
            !this.$store.state.expensesType ||
            this.$store.state.expensesTypes.length == 0
        ) {
          if (this.$route.path.includes("expenses")) {
            this.getBillTypes();
          }
        }
        return this.$store.state.expensesType;
      },
      set: function (newValue) {
        this.$store.commit("setExpensesType", newValue);
      },
    },
    topBankAccount: {
      get: function () {
        if (
            !this.$store.state.bankAccounts ||
            this.$store.state.bankAccounts.length == 0
        ) {
          if (
              this.$route.path.includes("billing") ||
              this.$route.path.includes("scheduler") ||
              this.$route.path.includes("recurrence")
          ) {
            this.getCompanyBanks();
          }
        }
        return this.$store.state.topBankAccount;
      },
      set: function (newValue) {
        this.$store.commit("setBankAccount", newValue);
      },
    },
    billCancelVisible: function () {
      return this.$store.state.billCancelVisible;
    },
    makePurchaseOrderVisible: function () {
      return this.$store.state.makePurchaseOrder;
    },
    makeBillVisible: function () {
      return this.$store.state.makeBillVisible;
    },
    makeCreditNoteVisible: function () {
      return this.$store.state.makeCreditNote;
    },
    showNewButton: function () {
      return this.$store.state.showNewButton;
    },
    showSaveButton: function () {
      return this.$store.state.showSaveButton;
    },
    showDepotButtons: function () {
      return this.$store.state.showDepotButtons;
    },
    isBillSelected: function () {
      return this.$store.state.isBillSelected;
    },
    selectedBillData: function () {
      return this.$store.state.selectedBillData;
    },
    isExpenseSelected: function () {
      return this.$store.state.isExpenseSelected;
    },
    selectedExpenseData: function () {
      return this.$store.state.selectedExpenseData;
    },
  },
};
</script>
<style scoped>
#top-action-buttons {
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
}

#top-new {
  order: 0;
}

#top-pending-article {
  order: 10;
}
#top-articles-in-cart{
    order: 22 !important;
}

#top-create {
  order: 1;
}

#top-save {
  order: 0;
}

#top-save-and-close {
  order: 1;
}

#top-cancel {
  order: 2;
}

#top-delete {
  order: 3;
}

#top-depot-in {
  order: 4;
}

#top-depot-out {
  order: 5;
}

#top-make-bill {
  order: 19;
}

#top-make-purchase-order {
  order: 19;
}

#top-make-credit-note {
  order: 19;
}

#top-print {
  order: 7;
}

#top-bill-type {
  order: 20;
}

.zeroOpacity {
  opacity: 0;
}

#top-attachments {
  order: 9;
}

#top-calculator {
  order: 10;
}

#top-history {
  order: 11;
}


#top-currency-type {
  order: 19;
}

#top-report-run {
  order: 1;
}

#top-report-reset {
  order: 2;
}

#top-report-save {
  order: 1;
}

#top-report-cancel {
  order: 2;
}

#top-expedition-cancel {
  order: 1;
}

#top-expedition-save {
  order: 0;
}

#top-expedition-make-bill {
  order: 2
}

#top-expedition-make-expense {
  order: 3;
}

#top-expedition-archive {
  order: 2;
}

#top-expedition-add-document {
  order: 3;
}

#top-expedition-add-bill {
  order: 4;
}

#top-expedition-duplicate {
  order: 6
}

#top-expense-create {
  order: 1;
}

#top-expense-bulk-payment {
  order: 2;
}
</style>
